import { useQuery } from '@tanstack/react-query';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import http from 'app/config/http';
import React, { useState } from 'react';
import { IBookingWithListedBySuyAgency } from '../AllBooking/AllBooking';
import { useSearchParams } from 'react-router-dom';

import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';
import BookingTable from './BookingTable/BookingTable';

const PassengerCalender = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageCount, setPageCount] = useState<number>(0);

    let page = searchParams.get('page') || 1;
    let fromDate = searchParams.get('fromDate') || '';
    let toDate = searchParams.get('toDate') || '';

    // let query = searchParams.get('query') || '';
    // let bookingStatus = searchParams.get('bookingStatus') || '';

    const agencyBookingQuery = useQuery<IBookingWithListedBySuyAgency[]>(
        ['agencyBookingListPaginated', page, fromDate, toDate, 'All bookings'],
        async () => {
            let params = 'get=all&';
            page ? (params += `page=${page}`) : (params += '');
            fromDate ? (params += `&fromDate=${fromDate}`) : (params += '');
            toDate ? (params += `&toDate=${toDate}`) : (params += '');
            const { data } = await http.get(
                `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/completed-and-awaiting-pnr?${params}`
            );
            setPageCount(data.pageCount);
            return data.data;
        },
        {
            enabled: fromDate.trim() !== '' && toDate.trim() !== '',
        }
    );

    const handlePageChange = (val: any) => {
        let newPage = val.selected + 1;
        searchParams.set('page', newPage);
        setSearchParams(searchParams, { replace: true });
    };

    const handleDateFilter = (date1: string, date2: string) => {
        date1 ? searchParams.set('fromDate', date1) : searchParams.delete('fromDate');
        date2 ? searchParams.set('toDate', date2) : searchParams.delete('toDate');
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    };

    console.log({ isLoading: agencyBookingQuery.isLoading, data: agencyBookingQuery.data });

    return (
        <div>
            <h5 className="font-bold">Passenger Calendar</h5>

            <div className="card mt-8 ">
                <div className="flex items-center gap-8 py-4 px-4">
                    <TableDateFilter onChange={handleDateFilter} fromDate={fromDate} toDate={toDate} />
                </div>

                <div className="mt-6">
                    <BookingTable
                        bookings={agencyBookingQuery.data}
                        type={'AG'}
                        isLoading={agencyBookingQuery.isLoading && agencyBookingQuery.fetchStatus !== 'idle'}
                    />

                    <div className="px-4 pb-3 ">
                        <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PassengerCalender;
