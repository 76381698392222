/* eslint-disable jsx-a11y/anchor-is-valid */
import { ISubAgency } from 'app/types';
import { FC } from 'react';
import { formatCurrency } from 'app/utils/currency-utils';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import { MenuDotIcon } from 'app/icons';
import useDialog from 'app/hooks/use-dialog';
import { useMutation } from '@tanstack/react-query';
import http from 'app/config/http';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import InfoModal from 'app/components/InfoModal/InfoModal';
import { useNavigate } from 'react-router-dom';
interface SubAgencyTableProps {
    subAgency: ISubAgency;
}

const SubAgencyTable: FC<SubAgencyTableProps> = (props) => {
    const { subAgency } = props;
    const deleteDialog = useDialog<string>();
    const infoDialog = useDialog<string>();
    const navigate = useNavigate();

    const deleteMutation = useMutation(async (values: any) => {
        // delete api here
        await http.delete(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${values._id}`);
    });

    const handleUserDelete = async () => {
        try {
            await deleteMutation.mutateAsync({ _id: subAgency._id });
            navigate('/sub-agencies', { replace: true });
            toast.success('Sub Agency deleted successfully.');
        } catch (ex: any) {
            console.log(ex);
            infoDialog.show(ex.data.message);
        } finally {
            deleteDialog.hide();
        }
    };

    const getAvailableBalance = () => {
        let availableBalance = subAgency.availableCredit + subAgency.availableCashBalance;

        if (availableBalance < 0) {
            availableBalance = 0;
        }

        return availableBalance;
    };

    const getAmountDue = () => {
        let amountDue = subAgency.creditLimit - subAgency.availableCredit - subAgency.availableCashBalance;

        if (amountDue < 0) {
            amountDue = 0;
        }

        return amountDue;
    };

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50">
                        <th className="th-1">ID</th>
                        <th className="th-1">Name & Address</th>
                        <th className="th-1">Contact</th>
                        <th className="th-1">GST Number</th>
                        <th className="th-1">Users</th>
                        <th className="th-1">Bookings this month</th>
                        <th className="th-1">Total Bookings</th>
                        <th className="th-1">Balances</th>
                        <th className="th-1"></th>
                        {/* <th className="th-1"></th> */}
                    </tr>
                </thead>
                <tbody>
                    <tr key={subAgency._id} className="border-b border-gray-200 last:border-none">
                        <td className="td-1">
                            <div className="font-semibold">{subAgency._id}</div>
                        </td>
                        <td className="td-1">
                            <div className="font-semibold">{subAgency.name}</div>
                            <p className="mt-1">
                                {subAgency.address.city}, {subAgency.address.state}
                            </p>
                        </td>
                        <td className="td-1">
                            <p className="font-semibold">{subAgency.phoneNumber}</p>
                            <p className="font-semibold">{subAgency.email}</p>
                        </td>
                        <td className="td-1">
                            <div className="font-semibold">{subAgency.gst.number}</div>
                        </td>
                        <td className="td-1">
                            <div className="font-semibold">{subAgency.subAgencyUserCount}</div>
                        </td>
                        <td className="td-1">
                            <div className="font-semibold">{subAgency.currentMonthBookingCount}</div>
                        </td>
                        <td className="td-1">
                            <div className="font-semibold">{subAgency.totalBookingCount}</div>
                        </td>
                        <td className="td-1">
                            <div className="flex items-center gap-3 min-w-max">
                                <p className="w-16">Cr Limit:</p>
                                <div className="font-semibold">{formatCurrency(subAgency.creditLimit)}</div>
                            </div>

                            <div className="flex items-center gap-3 min-w-max">
                                <p className="w-16">Avl Bal:</p>
                                <div className="font-semibold text-green-500">
                                    {formatCurrency(getAvailableBalance())}
                                </div>
                            </div>

                            <div className="flex items-center gap-3 min-w-max">
                                <p className="w-16">Amt Due:</p>
                                <div className="font-semibold text-red-500">{formatCurrency(getAmountDue())}</div>
                            </div>
                        </td>
                        <td className="td-1">
                            <Dropdown
                                target={
                                    <a className="dropdown-action">
                                        <MenuDotIcon />
                                    </a>
                                }
                                menu={
                                    <DropdownList>
                                        <DropdownItem to={`/sub-agencies/${subAgency._id}/users`}>Users</DropdownItem>
                                        <DropdownItem to={`/account/top-up?sub-agency=${subAgency._id}`}>
                                            Top Up
                                        </DropdownItem>
                                        <DropdownItem onClick={() => deleteDialog.show(subAgency._id)}>
                                            <span className="text-red-500">Delete</span>
                                        </DropdownItem>
                                    </DropdownList>
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this subagency?'}
                onClose={deleteDialog.hide}
                onConfirm={handleUserDelete}
                isLoading={deleteMutation.isLoading}
            />
            <InfoModal message={infoDialog.data} show={infoDialog.isOpen} onClose={infoDialog.hide} />
        </div>
    );
};

export default SubAgencyTable;
