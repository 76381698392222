import BackLink from 'app/components/BackLink/BackLink';

import MarkupControlForm from 'app/components/MarkupControlForm/MarkupControlForm';
import TravelBoutiqueCredentialsForm from '../shared/components/TravelBoutiqueCredentialsForm/TravelBoutiqueCredentialsForm';
import PageLoader from 'app/components/PageLoader/PageLoader';
import MarkupHistoryTable from 'app/components/MarkupHistoryTable/MarkupHistoryTable';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { mapFareSupplierStatusToBoolean } from '../shared/utils';
import { Status } from 'app/enums/status.enum';
import { ITravelBoutiqueDetail } from '../shared/types/travel-boutique';
import { IMarkUpHistory } from '../shared/types/mark-up-history';

import { FareSupplierIdEnum } from 'app/enums/FareSupplierIdEnum.enum';
import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';
import FareSupplierDefaultCommissionPlan from '../shared/components/FareSupplierDefaultCommissionPlan/FareSupplierDefaultCommissionPlan';
import FareRuleForm from 'app/components/FareRuleForm/FareRuleForm';

const FareSupplierTravelBoutique = () => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
    } = useFareSupplierStatus<ITravelBoutiqueDetail>({
        fareIdentifier: FareSupplierEnum.TRAVEL_BOUTIQUE,
    });

    if (isLoading) return <PageLoader />;

    const data: ITravelBoutiqueDetail = isError
        ? {
              clientId: '',
              password: '',
              username: '',
              markup: 0,
              fareRules: [] as string[],
              markupHistory: [] as IMarkUpHistory[],
              status: Status.DISABLED,
              authUrl: '',
              bookingUrl: '',
              searchUrl: '',
          }
        : queryData;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">Travel Boutique Details</div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-switch success"
                            checked={mapFareSupplierStatusToBoolean(data.status)}
                            onChange={(e) => handleToggleStatus(e.target.checked)}
                        />
                    </div>
                </div>

                <TravelBoutiqueCredentialsForm
                    authUrl={data.authUrl}
                    bookingUrl={data.bookingUrl}
                    searchUrl={data.searchUrl}
                    clientId={data.clientId}
                    password={data.password}
                    username={data.username}
                />
            </div>

            <FareSupplierDefaultCommissionPlan
                fareSupplierId={FareSupplierIdEnum.TRAVEL_BOUTIQUE}
                fareSupplier={FareSupplierEnum.TRAVEL_BOUTIQUE}
            />

            <FareSupplierAirlineFilter fareSupplier={FareSupplierIdEnum.TRAVEL_BOUTIQUE} />

            {!isError && (
                <FareRuleForm
                    fareRules={data.fareRules}
                    fareSupplier={'tbo'}
                    refetchQueryIdentifier={queryIdentifier}
                />
            )}
            {!isError && (
                <MarkupControlForm
                    currentMarkup={data.markup}
                    fareSupplier={'tbo'}
                    refetchQueryIdentifier={queryIdentifier}
                    markupHistory={data.markupHistory}
                />
            )}
        </>
    );
};

export default FareSupplierTravelBoutique;
