import { useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { ITourQuery } from 'app/types';
import { formatDate } from 'app/utils/date-utils';
import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

interface TourQueryViewProps {}

const TourQueryView: FC<TourQueryViewProps> = (props) => {
    const { subAgency, id } = useParams();

    const tourQuery = useQuery<ITourQuery>(['tour-query', subAgency, id], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgency}/tour-queries/${id}`
        );
        return data;
    });

    if (tourQuery.isLoading) return <PageLoader />;

    const tourData = tourQuery.data;

    const getHotelLabel = (val: string) => {
        let label = '';
        switch (val) {
            case 'BUDGET':
                label = 'Budget';
                break;
            case 'STANDARD':
                label = 'Standard';
                break;
            case 'LUXURY':
                label = 'Luxury';
                break;
        }
        return label;
    };

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Tour Request</h5>
            </div>

            <div className="card px-6 mt-8 pt-5 pb-10">
                <div>
                    <div className="font-bold">Tour Details</div>
                    <div className="grid grid-cols-12 gap-6 mt-8">
                        <div className="col-span-4">
                            <label className="text-xs mb-2">Arrival Date</label>
                            <div className="font-semibold py-2">
                                {formatDate(tourData.arrivalDate, 'ddd, DD MMM YYYY')}
                            </div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2">Departure Date</label>
                            <div className="font-semibold py-2 flex gap-2">
                                {formatDate(tourData.departureDate, 'ddd, DD MMM YYYY')}
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2">Hotel Category</label>
                            <div className="font-semibold py-2 flex gap-2">{getHotelLabel(tourData.hotelCategory)}</div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2">Adults</label>
                            <div className="font-semibold py-2 flex gap-2">{tourData.adults}</div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2">Children</label>
                            <div className="font-semibold py-2 flex gap-2">{tourData.children}</div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2">Rooms</label>
                            <div className="font-semibold py-2 flex gap-2">{tourData.totalRooms}</div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2">Extra Beds</label>
                            <div className="font-semibold py-2 flex gap-2">{tourData.extraBed}</div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2">Sub Agency</label>
                            <div className="font-semibold py-2 flex gap-2 text-blue-500">
                                <Link to={`/sub-agencies/${tourData.subAgency._id}/details`}>
                                    {tourData.subAgency.name}
                                </Link>
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2">Sub Agency Number</label>
                            <div className="font-semibold py-2 flex gap-2">{tourData.subAgency.phoneNumber}</div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2">Transport Needed</label>
                            <div className="mb-2">
                                <input
                                    type="checkbox"
                                    className="form-switch success"
                                    checked={tourData.transportNeeded}
                                />
                            </div>
                        </div>

                        <div className="col-span-12">
                            <label className="text-xs mb-2">More Details</label>

                            <textarea
                                className="form-control"
                                value={tourData.extraInformation}
                                rows={5}
                                disabled
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TourQueryView;
