import { cloneDeep } from 'lodash';
import useDialog from 'app/hooks/use-dialog';
import { FC, useEffect, useState } from 'react';
import FlightDetailsCard from 'app/pages/Booking/BookingDetail/components/FlightDetailsCard/FlightDetailsCard';
import PageLoader from 'app/components/PageLoader/PageLoader';

import EditFlightDetails from './components/EditFlightDetails/EditFlightDetails';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editBooking } from 'app/api/services/booking.service';
import { IBooking, IFareDescription } from 'app/types';
import { toast } from 'react-toastify';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import BookingChangeRemarkDialog from './components/BookingChangeRemarkDialog/BookingChangeRemarkDialog';
import TravelerDetailsCard from 'app/pages/Booking/BookingDetail/components/TravelerDetailsCard/TravelerDetailsCard';
import ContactDetailsCard from 'app/pages/Booking/BookingDetail/components/ContactDetailsCard/ContactDetailsCard';
import EditPassengerDetailsDialog from './components/EditPassengerDetailsDialog/EditPassengerDetailsDialog';
import EditContactDetailsDialog from './components/EditContactDetailsDialog/EditContactDetailsDialog';

interface IEditBooking {
    booking: any;
    show: boolean;
    onClose: () => void;
    renderDistanceFromTop: number;
}

const EditBooking: FC<IEditBooking> = (props) => {
    const remarkDialog = useDialog();
    const editFlightDialog = useDialog();
    const editPassengerDialog = useDialog();
    const editContactDialog = useDialog();

    const [booking, setBooking] = useState(null);
    const { booking: originalBooking, onClose, renderDistanceFromTop } = props;
    const queryClient = useQueryClient();

    useEffect(() => {
        setBooking(() => cloneDeep(originalBooking));
    }, []);

    const updateBookingMutation = useMutation(
        (payload: { updateBookingData: IBooking; remark: string; bookingId: string }) => editBooking(payload)
    );

    const handleSaveChanges = async (remark: string) => {
        try {
            const updatedBooking = await updateBookingMutation.mutateAsync({
                updateBookingData: booking,
                remark,
                bookingId: booking._id,
            });
            queryClient.fetchQuery(['bookingDetail', booking._id]);
            queryClient.fetchQuery(['BOOKING', 'HISTORY', booking._id]);
            remarkDialog.hide();
            console.log({ updatedBooking });
            props.onClose();
            toast.success('Booking Updated Successfully');
        } catch (e) {
            console.error(e);
            toast.error('Something went wrong');
        }
    };

    if (!booking) return <PageLoader />;

    const fixedFareDescription: IFareDescription = {
        adult: booking.priceDetail.fareDescription.adult,
        child: booking.passengers.children.length > 0 ? booking.priceDetail.fareDescription.child : null,
        infant: booking.passengers.infants.length > 0 ? booking.priceDetail.fareDescription.infant : null,
    };

    return (
        <>
            <div className="fixed top-0 left-0 h-full w-screen  bg-slate-800/90 z-20 overflow-auto pb-8">
                <div
                    className={'grid grid-cols-3  gap-6 ml-[var(--sidebar-width)] px-8'}
                    style={{ marginTop: `${renderDistanceFromTop}px` }}
                >
                    <div className="col-span-3 lg:col-span-2">
                        <FlightDetailsCard
                            booking={booking}
                            isEditable={true}
                            openModal={() => editFlightDialog.show()}
                        />

                        <TravelerDetailsCard
                            booking={booking}
                            isEditable={true}
                            openModal={() => editPassengerDialog.show()}
                            viewingOldVersion={true}
                        />

                        <ContactDetailsCard
                            booking={booking}
                            isEditable={true}
                            viewingOldVersion={booking.version}
                            openModal={() => editContactDialog.show()}
                        />
                    </div>
                    <div className="col-span-3 lg:col-span-1 mt-16">{/* <div className="card py-4"></div> */}</div>
                </div>

                <div className="flex gap-4 fixed bottom-0 right-0 mx-10 mb-4">
                    <LoaderButton onClick={remarkDialog.show} className="bg-primary py-2 px-4 text-white">
                        Save Changes
                    </LoaderButton>
                    <button className="bg-red-500 py-2 px-4 text-white" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
            <EditFlightDetails
                onClose={editFlightDialog.hide}
                show={editFlightDialog.isOpen}
                handleUpdate={() => {}}
                setBooking={setBooking}
                segments={booking.segments}
                fareDescription={fixedFareDescription}
                status={booking.status}
            />
            <EditPassengerDetailsDialog
                passengers={booking.passengers}
                onClose={editPassengerDialog.hide}
                show={editPassengerDialog.isOpen}
                handleUpdate={() => {}}
                isInternational={booking.isInternational}
                setBooking={setBooking}
                segments={booking.segments}
            />
            <EditContactDetailsDialog
                contactDetails={booking.contactDetails}
                onClose={editContactDialog.hide}
                show={editContactDialog.isOpen}
                setBooking={setBooking}
                handleUpdate={() => {}}
            />

            <BookingChangeRemarkDialog
                onClose={() => {
                    remarkDialog.hide();
                    onClose();
                }}
                show={remarkDialog.isOpen}
                handleRequest={handleSaveChanges}
                isLoading={updateBookingMutation.isLoading}
            />
        </>
    );
};

export default EditBooking;
