import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ICommissionPlan } from 'app/enums/commission-plan';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import useCommissionPlan from 'app/modules/shared/hooks/use-commission-plan';
import { CommissionPlanTypeEnum } from 'app/enums/commission-plan-type.enum';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import useCommissionPlanLayoutContext from '../../hooks/use-commission-plan-layout-context';

const initialValues: ICommissionPlan = {
    name: '',
    commissionFareSupplier: FareSupplierEnum.TRIP_JACK,
    commissionPlanType: CommissionPlanTypeEnum.SUB_AGENCY,
    managementChargesPerPassenger: 0,
    zeroCommissionConditionalManagementChargesPerPassenger: 0,
    commissionBreakdown: {
        netCommission: {
            flat: 0,
            percentage: 0,
        },
    },
};

const AddCommissionPlan = () => {
    const navigate = useNavigate();
    const { createCommissionPlanMutation } = useCommissionPlan();
    const { commissionPlanEnabledFareSupplier } = useCommissionPlanLayoutContext();

    const validationSchema = Yup.object({
        name: Yup.string().trim().required('This field is required'),
        commissionFareSupplier: Yup.mixed().oneOf(commissionPlanEnabledFareSupplier),
        commissionPlanType: Yup.mixed().oneOf([Object.keys(CommissionPlanTypeEnum)]),
        managementChargesPerPassenger: Yup.number().required('This field is required'),
        zeroCommissionConditionalManagementChargesPerPassenger: Yup.number().required('This field is required'),
        commissionBreakdown: Yup.object({
            netCommission: Yup.object({
                flat: Yup.number().required('This field is required').min(0, 'The minimum value you can enter is 0.'),
                percentage: Yup.number()
                    .required('This field is required')
                    .min(0, 'The minimum value you can enter is 0.')
                    .max(100, 'The maximum value you can enter is 100.'),
            }),
        }),
    });

    const handleSubmit = async (values: typeof initialValues, helpers: FormikHelpers<typeof initialValues>) => {
        try {
            await createCommissionPlanMutation.mutateAsync({ ...values });
            navigate('/fare-management/commission-plan');
        } catch (e) {
            toast.error('Some thing went wrong');
        }
    };

    return (
        <div>
            <h4 className="font-bold">Add Commission Plan </h4>

            <div className="card mt-8 px-6 py-5">
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({ errors, setFieldValue }: FormikProps<typeof initialValues>) => (
                        <Form>
                            <div className="grid grid-cols-12 gap-6 mt-6">
                                <div className="col-span-8">
                                    <label className="text-xs mb-2" htmlFor="name">
                                        Plan Name
                                    </label>
                                    <Field type="text" id="name" name="name" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="name" />
                                    </div>
                                </div>

                                <div className="col-span-8">
                                    <label className="text-xs mb-2" htmlFor="commissionFareSupplier">
                                        Fare Supplier
                                    </label>
                                    <Field
                                        as="select"
                                        name="commissionFareSupplier"
                                        type="text"
                                        id="commissionFareSupplier"
                                        className="form-control"
                                    >
                                        {commissionPlanEnabledFareSupplier.map((comFareSup) => (
                                            <option key={comFareSup} value={comFareSup}>
                                                {createReadableFareSupplierLabel(comFareSup)}
                                            </option>
                                        ))}
                                    </Field>
                                    <div className="form-error">
                                        <ErrorMessage name="commissionFareSupplier" />
                                    </div>
                                </div>
                                <div className="col-span-8">
                                    <label className="text-xs mb-2" htmlFor="managementChargesPerPassenger">
                                        Management Charges (Per Seat)
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field
                                            type="number"
                                            id={`managementChargesPerPassenger`}
                                            name={`managementChargesPerPassenger`}
                                            className="form-control pl-8"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="managementChargesPerPassenger" />
                                    </div>
                                </div>
                                <div className="col-span-8">
                                    <label
                                        className="text-xs mb-2"
                                        htmlFor="zeroCommissionConditionalManagementChargesPerPassenger"
                                    >
                                        Management Charges (Per Seat) When Net Commission Is Zero
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field
                                            type="number"
                                            id={`zeroCommissionConditionalManagementChargesPerPassenger`}
                                            name={`zeroCommissionConditionalManagementChargesPerPassenger`}
                                            className="form-control pl-8"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="zeroCommissionConditionalManagementChargesPerPassenger" />
                                    </div>
                                </div>

                                <div className="col-span-8">
                                    <div className="grid grid-cols-5 gap-4">
                                        <div className="mt-auto">
                                            <div className="mb-5 text-base font-medium">Commission</div>
                                            <div className="form-error" />
                                        </div>
                                        <div className="col-span-2 mt-auto">
                                            <label className="text-xs" htmlFor="">
                                                Flat (Per Seat)
                                            </label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">₹</div>
                                                <Field
                                                    type="number"
                                                    id={`commissionBreakdown.netCommission.flat`}
                                                    name={`commissionBreakdown.netCommission.flat`}
                                                    className="form-control pl-8"
                                                />
                                            </div>
                                            <div className="form-error">
                                                <ErrorMessage name={`commissionBreakdown.netCommission.flat`} />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <label className="text-xs" htmlFor="">
                                                Share Net Commission With Sub Agent (%)
                                            </label>
                                            <div className="input-group">
                                                <div className="input-group-postpend">%</div>
                                                <Field
                                                    type="number"
                                                    id={`commissionBreakdown.netCommission.percentage`}
                                                    name={`commissionBreakdown.netCommission.percentage`}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-error">
                                                <ErrorMessage name={`commissionBreakdown.netCommission.percentage`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-12">
                                <LoaderButton
                                    type="submit"
                                    className="bg-primary text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4"
                                    isLoading={createCommissionPlanMutation.isLoading}
                                >
                                    Create
                                </LoaderButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AddCommissionPlan;
