import React, { FC } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Modal from 'app/components/Modal/Modal';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ISegment } from 'app/types/manual-booking';
import SegmentFormItem from './components/SegmentFormItem/SegmentFormItem';
import {
    convertDateInputFormatToSystemDateFormat,
    convertSystemDateFormatToDateInputFormat,
    convertSystemTimeFormatToTimeInputFormat,
    convertTimeInputFormatToSystemTimeFormat,
} from 'app/utils/date-utils';
import { calculateTimeDifferenceInMinutesWithTimeZone } from 'app/utils/time-zone.utils';
import { IAirline, IFareDescription } from 'app/types';
import { bookingStatusEnum } from 'app/enums/booking-status.enum';
import { BOOKING_STATUS_LIST } from 'app/utils/constants';

interface IEditFlightDetails {
    show: boolean;
    onClose: () => void;
    handleUpdate: (val: any) => void;
    setBooking: React.Dispatch<any>;
    segments: Array<ISegment>;
    fareDescription: IFareDescription;
    status: bookingStatusEnum;
}

const EditFlightDetails: FC<IEditFlightDetails> = (props) => {
    type TFlightForm = {
        segments: Array<ISegment>;
        fareDescription: IFareDescription;
        status: bookingStatusEnum;
    };

    const initialValues: TFlightForm = {
        status: props.status,
        segments: props.segments.map((segment) => {
            return {
                ...segment,
                arrival: {
                    ...segment.arrival,
                    date: convertSystemDateFormatToDateInputFormat(segment.arrival.date),
                    time: convertSystemTimeFormatToTimeInputFormat(segment.arrival.time),
                },
                departure: {
                    ...segment.departure,
                    date: convertSystemDateFormatToDateInputFormat(segment.departure.date),
                    time: convertSystemTimeFormatToTimeInputFormat(segment.departure.time),
                },
            };
        }),
        fareDescription: props.fareDescription,
    };

    const calculateSegmentDuration = (segment: ISegment) => {
        const from = {
            airport: segment.departure.airport,
            date: convertDateInputFormatToSystemDateFormat(segment.departure.date),
            time: convertTimeInputFormatToSystemTimeFormat(segment.departure.time),
        };

        const to = {
            airport: segment.arrival.airport,
            date: convertDateInputFormatToSystemDateFormat(segment.arrival.date),
            time: convertTimeInputFormatToSystemTimeFormat(segment.arrival.time),
        };

        return calculateTimeDifferenceInMinutesWithTimeZone(from, to);

        // let x = moment(segment.arrival.date + segment.arrival.time, 'YYYY-MM-DDHH:mm').diff(
        //     moment(segment.departure.date + segment.departure.time, 'YYYY-MM-DDHH:mm'),
        //     'minutes'
        // );
    };

    /*
     * This function calculated layover time by taking respective segments.
     * This Function take current and next segment and convert the layover
     * into a segment and uses calculateSegmentDuration to calculate layover
     */
    const calculateLayoverDuration = ({
        inboundFlight,
        outBoundFlight,
    }: {
        inboundFlight: ISegment;
        outBoundFlight: ISegment;
    }) => {
        const fakeSegment: ISegment = {
            departure: inboundFlight.arrival,
            arrival: outBoundFlight.departure,
            flight: {} as IAirline & { number: string },
            stopsOverAirport: [],
        };

        return calculateSegmentDuration(fakeSegment);
    };

    const onSubmit = (values: TFlightForm) => {
        console.log({ values });

        const deSerializedValue = {
            segments: values.segments.map((segment, index, segmentList) => ({
                ...segment,

                duration: calculateSegmentDuration(segment),
                layoverDuration:
                    index + 1 < segmentList.length
                        ? calculateLayoverDuration({ inboundFlight: segment, outBoundFlight: segmentList[index + 1] })
                        : 0,
                arrival: {
                    ...segment.arrival,
                    date: convertDateInputFormatToSystemDateFormat(segment.arrival.date),
                    time: convertTimeInputFormatToSystemTimeFormat(segment.arrival.time),
                },
                departure: {
                    ...segment.departure,
                    date: convertDateInputFormatToSystemDateFormat(segment.departure.date),
                    time: convertTimeInputFormatToSystemTimeFormat(segment.departure.time),
                },
            })),
        };

        const fixedFareDescription: IFareDescription = {
            adult: values.fareDescription.adult,
            child: values.fareDescription.adult,
            infant: values.fareDescription.infant,
        };

        props.setBooking((prev: any) => {
            return {
                ...prev,
                ...deSerializedValue,
                priceDetail: {
                    ...prev.priceDetail,
                    fareDescription: fixedFareDescription,
                },
                status: values.status,
            };
        });
        props.onClose();
    };

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-8 pt-8 pb-10 w-[1280px]">
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ values }) => (
                    <Form>
                        <div className="">
                            <label className="text-xs mb-2" htmlFor="status">
                                Status
                            </label>
                            <Field as="select" name="status" type="text" id="status" className="form-control">
                                {BOOKING_STATUS_LIST.map((status) => (
                                    <option key={status.value} value={status.value}>
                                        {status.label}
                                    </option>
                                ))}
                            </Field>
                            <div className="form-error">
                                <ErrorMessage name="status" />
                            </div>
                        </div>

                        {values.segments.map((segment, index) => (
                            <SegmentFormItem segmentIndex={index} />
                        ))}
                        <div className="mt-8">
                            <div className="text-gray-900 font-bold">Baggage Information</div>
                            <div className="grid grid-cols-12 gap-6 mt-4">
                                {values.fareDescription.adult && (
                                    <>
                                        <div className="col-span-4">
                                            <label
                                                className="text-xs mb-2  "
                                                htmlFor="fareDescription.adult.baggage.cabin"
                                            >
                                                CABIN
                                            </label>
                                            <div className="input-group">
                                                <Field
                                                    type="text"
                                                    id="fareDescription.adult.baggage.cabin"
                                                    name="fareDescription.adult.baggage.cabin"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-error">
                                                <ErrorMessage name="fareDescription.adult.baggage.cabin" />
                                            </div>
                                        </div>
                                        <div className="col-span-4">
                                            <label
                                                className="text-xs mb-2 "
                                                htmlFor="fareDescription.adult.baggage.checkIn"
                                            >
                                                CHECK IN
                                            </label>
                                            <div className="input-group">
                                                <Field
                                                    type="text"
                                                    id="fareDescription.adult.baggage.checkIn"
                                                    name="fareDescription.adult.baggage.checkIn"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-error">
                                                <ErrorMessage name="fareDescription.adult.baggage.checkIn" />
                                            </div>
                                        </div>
                                        <div className="col-span-4" />
                                    </>
                                )}

                                {values.fareDescription.infant && (
                                    <>
                                        <div className="col-span-4">
                                            <label
                                                className="text-xs mb-2  "
                                                htmlFor="fareDescription.infant.baggage.cabin"
                                            >
                                                CABIN (INFANT)
                                            </label>
                                            <div className="input-group">
                                                <Field
                                                    type="text"
                                                    id="fareDescription.infant.baggage.cabin"
                                                    name="fareDescription.infant.baggage.cabin"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-error">
                                                <ErrorMessage name="fareDescription.infant.baggage.cabin" />
                                            </div>
                                        </div>
                                        <div className="col-span-4">
                                            <label
                                                className="text-xs mb-2 "
                                                htmlFor="fareDescription.infant.baggage.checkIn"
                                            >
                                                CHECK IN (INFANT)
                                            </label>
                                            <div className="input-group">
                                                <Field
                                                    type="text"
                                                    id="fareDescription.infant.baggage.checkIn"
                                                    name="fareDescription.infant.baggage.checkIn"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-error">
                                                <ErrorMessage name="fareDescription.infant.baggage.checkIn" />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <LoaderButton className="bg-primary text-white px-4 py-6 rounded-lg mt-6">
                            Continue
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default EditFlightDetails;
