import { FC } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import { IBank, ISubAgency } from 'app/types';
import BackLink from 'app/components/BackLink/BackLink';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createTopUp } from 'app/api/services/accounting.service';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { getAgencyBankAccounts } from 'app/api/services/bank.service';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import SearchComponent from 'app/components/SearchComponent/SearchComponent';
import { getSubAgencies, getSubAgencyById } from 'app/api/services/sub-agecny.service';

const topUpFormSchema = Yup.object().shape({
    transactionDate: Yup.string()
        .required('This field is required')
        .test('isValid', 'Enter valid date', (transDate) => {
            return !moment(transDate, 'YYYY-MM-DD').isAfter(moment());
        }),
    transactionRemark: Yup.string().trim().required('This field is required'),
    balanceUploaded: Yup.number()
        .typeError('Enter valid amount')
        .required('This field is required')
        .moreThan(0, 'Enter valid amount'),
    transactionId: Yup.string().trim().required('This field is required'),
    bankAccount: Yup.object().required('Bank account is required').nullable(),
    subAgency: Yup.object().required('Sub Agency is required').nullable(),
});

type ITopUpForm = {
    transactionDate: string;
    transactionRemark: string;
    balanceUploaded: number;
    transactionId: string;
    bankAccount: null | IBank;
    subAgency: ISubAgency | null;
};

interface ISubAgencyTopUp {}

const SubAgencyTopUp: FC<ISubAgencyTopUp> = (props) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { data: bankAccounts, isLoading } = useQuery(['Agency Bank Account List'], () => getAgencyBankAccounts());

    const { data: subAgency, isLoading: subAgencyLoading } = useQuery(
        ['SUB_AGENCY', 'ID'],
        () => getSubAgencyById(searchParams.get('sub-agency')),
        {
            enabled: searchParams.has('sub-agency'),
            retry: false,
        }
    );

    const createTopUpPlanMutation = useMutation((payload: Omit<ITopUpForm, 'subAgency'> & { subAgencyId: string }) =>
        createTopUp(payload)
    );

    if (isLoading || (searchParams.has('sub-agency') && subAgencyLoading)) return <PageLoader />;

    const initialValues: ITopUpForm = {
        transactionDate: moment().format('YYYY-MM-DD'),
        transactionRemark: '',
        balanceUploaded: 0,
        transactionId: '',
        bankAccount: null,
        subAgency: subAgency ? subAgency : null,
    };

    const handleSubmit = async (values: ITopUpForm) => {
        const tmp = {
            ...values,
            subAgencyId: values.subAgency._id,
            transactionDate: moment(values.transactionDate, 'YYYY-MM-DD').format('YYYYMMDD'),
        };
        delete tmp['subAgency'];
        try {
            await createTopUpPlanMutation.mutateAsync(tmp);
            toast.success('Top Up Created Successfully');
            navigate('/');
        } catch (err: any) {
            toast.error('Something went wrong.');
            console.log({ err });
        }
    };

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Sub Agency Top Up</div>

                <div>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={topUpFormSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue, errors }: FormikProps<ITopUpForm>) => (
                            <Form>
                                <div className="grid grid-cols-12 gap-6 mt-6">
                                    {/* transaction date */}
                                    <div className="col-span-12">
                                        <label className="text-xs mb-2 label-required" htmlFor="subAgency">
                                            SUB AGENCY
                                        </label>
                                        <SearchComponent
                                            fetchSuggestions={getSubAgencies}
                                            setField={(subAgency: ISubAgency) => setFieldValue('subAgency', subAgency)}
                                            selectedItem={values?.subAgency}
                                            placeholder="Search Sub Agency"
                                            initialSuggestions={[]}
                                            getSuggestionValue={(subAgency: ISubAgency) =>
                                                `${subAgency._id} - ${subAgency.name}`
                                            }
                                            renderSuggestion={(subAgency: ISubAgency) => <div> {subAgency.name} </div>}
                                            clearInputOnSelection={true}
                                        />

                                        <div className="form-error">
                                            <ErrorMessage name="subAgency" />
                                        </div>
                                    </div>
                                    <div className="col-span-4">
                                        <label className="text-xs mb-2 " htmlFor="transactionDate">
                                            TRANSACTION DATE
                                        </label>
                                        <Field
                                            type="date"
                                            id="transactionDate"
                                            name="transactionDate"
                                            className="form-control"
                                        />
                                        <div className="form-error">
                                            <ErrorMessage name="transactionDate" />
                                        </div>
                                    </div>

                                    {/* Amount */}
                                    <div className="col-span-4">
                                        <label className="text-xs mb-2" htmlFor="balanceUploaded">
                                            AMOUNT
                                        </label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">₹</div>
                                            <Field
                                                type="number"
                                                id="balanceUploaded"
                                                name="balanceUploaded"
                                                className="form-control pl-8"
                                            />
                                        </div>
                                        <div className="form-error">
                                            <ErrorMessage name="balanceUploaded" />
                                        </div>
                                    </div>
                                    {/* Amount */}
                                    <div className="col-span-4">
                                        <label className="text-xs mb-2" htmlFor="transactionId">
                                            TRANSACTION ID
                                        </label>

                                        <Field
                                            type="text"
                                            id="transactionId"
                                            name="transactionId"
                                            className="form-control"
                                        />

                                        <div className="form-error">
                                            <ErrorMessage name="transactionId" />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 gap-6 mt-6">
                                    <div className="col-span-6">
                                        <label className="text-xs mb-2" htmlFor="bankAccount">
                                            SELECT ACCOUNT
                                        </label>
                                        <Field
                                            as="select"
                                            name="bankAccount"
                                            type="text"
                                            id="bankAccount"
                                            className="form-control"
                                            value={values.bankAccount?._id || ''}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                const selectedBankAccount = bankAccounts.find(
                                                    (bankAccount) => bankAccount._id === e.target.value
                                                );
                                                setFieldValue('bankAccount', selectedBankAccount);
                                            }}
                                        >
                                            <option value="">Select Bank Account</option>
                                            {bankAccounts.map((bankAccount) => (
                                                <option key={bankAccount._id} value={bankAccount._id}>
                                                    {`${bankAccount.name} - ${bankAccount.account.number} `}
                                                </option>
                                            ))}
                                        </Field>
                                        <div className="form-error">
                                            <ErrorMessage name="bankAccount" />
                                        </div>
                                    </div>
                                    <div className="col-span-6" />

                                    {/* ///////////////////////////////////// view only ////////////////////// */}

                                    <div className="col-span-4">
                                        <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                            Name
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            className="form-control"
                                            value={values?.bankAccount?.name}
                                        />
                                    </div>

                                    <div className="col-span-4">
                                        <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                            IFSC
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            className="form-control"
                                            value={values?.bankAccount?.ifsc}
                                        />
                                    </div>
                                    <div className="col-span-4">
                                        <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                            ACCOUNT HOLDER
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            className="form-control"
                                            value={values.bankAccount?.account?.holderName}
                                        />
                                    </div>
                                    <div className="col-span-4">
                                        <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                            ACCOUNT NUMBER
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            className="form-control"
                                            value={values?.bankAccount?.account?.number}
                                        />
                                    </div>
                                    <div className="col-span-4">
                                        <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                            ACCOUNT TYPE
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            className="form-control"
                                            value={values?.bankAccount?.account?.type}
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 gap-6 mt-3">
                                    <div className="col-span-8">
                                        <div className="mt-4">
                                            <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                                REMARKS
                                            </label>
                                            <Field
                                                as="textarea"
                                                id="name"
                                                name="transactionRemark"
                                                className="form-control"
                                                rows="5"
                                            />
                                            <div className="form-error">
                                                <ErrorMessage name="transactionRemark" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <LoaderButton
                                    type="submit"
                                    className="bg-primary text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4"
                                    isLoading={createTopUpPlanMutation.isLoading}
                                >
                                    Submit
                                </LoaderButton>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default SubAgencyTopUp;
