import http from 'app/config/http';
import { IAgencyUser, ISubAgency } from 'app/types';

export const createTopUp = async (payload: any) => {
    const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/accounts/top-up`, payload);
    return data;
};

// -----------------------------------------------------  Receivables - Sub Agency Balances -----------------------------------------------------

export const createReceivableNote = async (payload: { subAgencyId: string; note: string }) => {
    const { data } = await http.post(
        `${process.env.REACT_APP_API_URL}/api/v1/agent/reports/receivable/${payload.subAgencyId}`,
        {
            message: payload.note,
        }
    );
    return data;
};

type TPaymentReminderNote = {
    _id: string;
    message: string;
    subAgency: Pick<ISubAgency, '_id' | 'name'>;
    createdBy: Pick<IAgencyUser, '_id' | 'name'>;
    createdAt: string;
};

export const getReceivableNotes = async (payload: { subAgencyId: string }) => {
    const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/agent/reports/receivable/${payload.subAgencyId}`
    );
    return data as Array<TPaymentReminderNote>;
};






