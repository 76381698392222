import http from 'app/config/http';
import { IBooking } from 'app/types';
import { TBookingHistory } from 'app/types/booking-history';
import { IManualBooking } from 'app/types/manual-booking';

//------------------------------------------------------------------ Manual Booking ------------------------------------------------------------------
export const createManualBooking = async (payload: IManualBooking) => {
    const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/manual-booking`, payload);

    console.log({ resMB: data });

    return data.commissionPlans;
};

//------------------------------------------------------------------ Edit Booking   -------------------------------------------------------------------
export const editBooking = async (payload: { updateBookingData: IBooking; remark: string; bookingId: string }) => {
    const { data } = await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${payload.bookingId}`, {
        remark: payload.remark,
        updateBookingData: payload.updateBookingData,
    });
    return data.commissionPlans;
};

//------------------------------------------------------------------ Booking history   ----------------------------------------------------------------

export const getBookingHistory = async (bookingId: string) => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/booking-logs/${bookingId}/history`);
    return data.history as Array<TBookingHistory>;
};

//------------------------------------------------------------------ Booking remarks  -----------------------------------------------------------------
export const addRemarkToBooking = async (payload: { bookingId: string; remark: string }) => {
    const { data } = await http.post(
        `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${payload.bookingId}/remarks`,
        { remark: payload.remark }
    );

    return data.commissionPlans;
};