import { FareSupplierUrlEnum } from 'app/enums/fare-supplier.enum';


import * as Yup from 'yup';
import http from 'app/config/http';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { IMaskContactDetails } from 'app/types/mask-contact-details.type';
import { CrossIcon, EditPencilIcon } from 'app/icons';

interface IContactDetailsMasking {
    fareSupplier: FareSupplierUrlEnum;
    maskContactDetails: IMaskContactDetails;
    refetchQueryIdentifier: Array<any>;
}

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Invalid email address'),

    mobileNumber: Yup.string()
        .trim()
        .required('Mobile number is required')
        .length(10, 'Mobile number must be 10 digits')
        .matches(/^\d+$/, 'Mobile number must contain only numbers'),
});

const ContactDetailsMasking = (props: IContactDetailsMasking) => {
    const { fareSupplier, maskContactDetails, refetchQueryIdentifier } = props;
    const initialValues: IMaskContactDetails = maskContactDetails;
    const [isEditMode, setIsEditMode] = useState(false);
    const queryClient = useQueryClient();

    const maskContactMutation = useMutation(async (payload: { maskContactDetails: IMaskContactDetails }) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/${fareSupplier}/mask-contact-details`,
            payload
        );
        return data;
    });

    const handleToggleContactDetailsMasking = async (payload: IMaskContactDetails, isEnabled: boolean) => {
        try {
            await maskContactMutation.mutateAsync({
                maskContactDetails: {
                    isEnabled,
                    email: payload.email,
                    mobileNumber: payload.mobileNumber,
                },
            });
            toast.success(`Masking ${isEnabled ? 'enabled' : 'disabled'}  successfully`);
            setIsEditMode(false);
        } catch (e: any) {
            toast.error(e.data.message);
        } finally {
            queryClient.fetchQuery(refetchQueryIdentifier);
        }
    };

    const handleUpdateContactDetailsMasking = async (
        values: IMaskContactDetails,
        formikHelpers: FormikHelpers<IMaskContactDetails>
    ) => {
        try {
            await maskContactMutation.mutateAsync({ maskContactDetails: values });
            toast.success('Masking details updated successfully');
            setIsEditMode(false);
        } catch (e: any) {
            toast.error(e.data.message);
        } finally {
            queryClient.fetchQuery(refetchQueryIdentifier);
        }
    };

    return (
        <div className="card mt-8 px-6 py-5">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleUpdateContactDetailsMasking}
            >
                {({ setFieldValue, initialValues, setFormikState, values }: FormikProps<IMaskContactDetails>) => (
                    <Form>
                        <div className="flex justify-between items-center">
                            <div className="font-bold">Contact Detail Masking</div>
                            <div>
                                <input
                                    type="checkbox"
                                    className="form-switch success"
                                    checked={values.isEnabled}
                                    name="isEnabled"
                                    disabled={isEditMode}
                                    onChange={(e) => {
                                        setFieldValue('isEnabled', e.target.checked);
                                        handleToggleContactDetailsMasking(values, e.target.checked);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-6 mt-8">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="email">
                                    Email
                                </label>
                                <Field
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    disabled={!isEditMode}
                                />
                                <div className="form-error">
                                    <ErrorMessage name="email" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="mobileNumber">
                                    Mobile Number
                                </label>
                                <Field
                                    type="text"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    className="form-control"
                                    placeholder="Enter your Mobile Number"
                                    disabled={!isEditMode}
                                />
                                <div className="form-error">
                                    <ErrorMessage name="mobileNumber" />
                                </div>
                            </div>
                            <div className="col-span-4 ">
                                <div className="flex flex-col justify-center  h-full pb-4 pl-6">
                                    {isEditMode ? (
                                        <div
                                            className="cursor-pointer text-red-500 mt-auto pb-2"
                                            onClick={() => {
                                                setIsEditMode((prev) => !prev);
                                                setFormikState((prev) => ({
                                                    ...prev,
                                                    values: initialValues,
                                                }));
                                            }}
                                        >
                                            <CrossIcon />
                                        </div>
                                    ) : (
                                        <div
                                            className="cursor-pointer text-blue-500 mt-auto "
                                            onClick={() => setIsEditMode((prev) => !prev)}
                                        >
                                            <EditPencilIcon />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <LoaderButton
                            type="submit"
                            isDisabled={!isEditMode}
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-11"
                            isLoading={maskContactMutation.isLoading}
                        >
                            Save Changes
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ContactDetailsMasking;
