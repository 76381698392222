import { FC } from 'react';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import TripJackCredentialForm from '../shared/components/TripJackCredentialForm/TripJackCredentialForm';
import { Status } from 'app/enums/status.enum';
import { FareSupplierEnum, FareSupplierUrlEnum } from 'app/enums/fare-supplier.enum';
import { ITripJackDetails } from '../shared/types/trip-jack';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import { IMarkUpHistory } from '../shared/types/mark-up-history';
import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';
import FareSupplierDefaultCommissionPlan from '../shared/components/FareSupplierDefaultCommissionPlan/FareSupplierDefaultCommissionPlan';
import FareRuleForm from 'app/components/FareRuleForm/FareRuleForm';
import FareIdentifierMappingForm from '../shared/components/FareIdentifierMappingForm/FareIdentifierMappingForm';
import FareSupplierActivationToggle from '../shared/components/FareSupplierActivationToggle/FareSupplierActivationToggle';
import ContactDetailsMasking from '../shared/components/ContactDetailsMasking/ContactDetailsMasking';

interface FareSupplierTripJackProps {}

const FareSupplierTripJack: FC<FareSupplierTripJackProps> = (props) => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
        fareSupplier,
        fareSupplierLabel,
        fareSupplierUrlKey,
        isUpdatingFareSupplierStatus,
    } = useFareSupplierStatus<ITripJackDetails>({
        fareIdentifier: FareSupplierEnum.TRIP_JACK,
    });

    if (isLoading) return <PageLoader />;

    const data: ITripJackDetails = isError
        ? {
              apiKey: '',
              markup: 0,
              markupHistory: [] as IMarkUpHistory[],
              baseUrl: '',
              status: Status.DISABLED,
              fareRules: [],
              maskContactDetails: {
                  email: '',
                  isEnabled: false,
                  mobileNumber: '',
              },
          }
        : queryData;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>
            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">TripJack Details</div>
                    <FareSupplierActivationToggle
                        checked={data.status}
                        handleToggleStatus={handleToggleStatus}
                        fareSupplierLabel={fareSupplierLabel}
                        isLoading={isUpdatingFareSupplierStatus}
                    />
                </div>

                <TripJackCredentialForm apiKey={data.apiKey} baseUrl={data.baseUrl} />
            </div>
            <FareSupplierDefaultCommissionPlan fareSupplier={fareSupplier} fareSupplierId={fareSupplierUrlKey} />
            <FareIdentifierMappingForm fareSupplier={fareSupplierUrlKey} />
            <ContactDetailsMasking
                fareSupplier={fareSupplierUrlKey}
                maskContactDetails={data.maskContactDetails}
                refetchQueryIdentifier={queryIdentifier}
            />
            <FareSupplierAirlineFilter fareSupplier={fareSupplierUrlKey} />
            {!isError && (
                <FareRuleForm
                    fareRules={data.fareRules}
                    fareSupplier={'trip-jack'}
                    refetchQueryIdentifier={queryIdentifier}
                />
            )}
        </>
    );
};

export default FareSupplierTripJack;
