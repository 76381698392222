import React, { FC } from 'react';
import Modal from 'app/components/Modal/Modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { IContactDetails } from 'app/types/contact-details';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

interface IEditContactDetailsDialog {
    show: boolean;
    onClose: () => void;
    contactDetails: IContactDetails;
    handleUpdate: (val: any) => void;
    setBooking: React.Dispatch<any>;
}

const EditContactDetailsDialog: FC<IEditContactDetailsDialog> = (props) => {
    const { contactDetails, setBooking } = props;

    const initialValues: IContactDetails = {
        ...contactDetails,
    };

    const onSubmit = (values: IContactDetails) => {
        setBooking((prev: any) => {
            return {
                ...prev,
                contactDetails: {
                    ...values,
                },
            };
        });
        props.onClose();
    };

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-8 pt-8 pb-10 w-[1280px]">
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ values }) => (
                    <Form>
                        <div className="mt-12">
                            <div className="font-semibold col-span-full">Contact Details</div>
                            <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                <div className="sm:col-span-6">
                                    <label className="text-xs mb-2" htmlFor={`mobileNumber`}>
                                        MOBILE NUMBER
                                    </label>
                                    <Field
                                        type="text"
                                        id={`mobileNumber`}
                                        name={`mobileNumber`}
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name={`mobileNumber`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label className="text-xs mb-2" htmlFor={`email`}>
                                        E MAIL
                                    </label>
                                    <Field type="text" id={`email`} name={`email`} className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name={`email`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoaderButton className="bg-primary text-white px-4 py-6 rounded-lg mt-6">
                            Continue
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default EditContactDetailsDialog;
