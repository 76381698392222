import moment from 'moment';
import { FC } from 'react';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import { useQuery } from '@tanstack/react-query';
import { formatDate } from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import PaymentAddDialog from '../PaymentAddDialog/PaymentAddDialog';
import BookingInvoiceDialog from '../BookingInvoiceDialog/BookingInvoiceDialog';
import PaymentReceiptDialog from '../PaymentReceiptDialog/PaymentReceiptDialog';
import { TLedgerItem } from '../../types/sub-agency-series-ledger';
import Tooltip from 'app/components/Tooltip/Tooltip';

interface SeriesSupplierLedgerProps {
    transactions: Array<TLedgerItem>;
    subAgencyId: string;
}

const transactionTypeMessage: any = {
    ADD_MONEY_TO_WALLET: 'Money added to wallet',
    FLIGHT_BOOKING: 'Flight Booking',
    OPENING_BALANCE: 'Opening Balance',
};

const SeriesSupplierLedger: FC<SeriesSupplierLedgerProps> = (props) => {
    const { transactions, subAgencyId } = props;
    const AddPaymentDialog = useDialog();
    const paymentDialog = useDialog();
    // const [selectedTransactionId, setSelectedTransactionId] = useState<number>(null);

    const paymentNoteDialog = useDialog<{ _id: string; type: string }>({ _id: '', type: '' });
    const invoiceDialog = useDialog<{ _id: string; type: string }>({ _id: '', type: '' });

    const bankAccounts = useQuery(['subAgency', subAgencyId, 'BankAccounts'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/banks`
        );
        return data;
    });

    const handleDialogOpenner = (tranType: string, _id: string, ref: any) => {
        if (tranType === 'PAYMENT') {
            paymentDialog.show(_id);
        }

        if (tranType === 'CREDIT' || tranType === 'DEBIT') {
            if (tranType === 'CREDIT') {
                paymentNoteDialog.show({ _id, type: 'Credit' });
            }
            if (tranType === 'DEBIT') {
                paymentNoteDialog.show({ _id, type: 'Debit' });
            }
        }

        if (tranType === 'INVOICE') {
            // window.open(`/bookings/${ref}/invoice/SAG`, '_blank');
            invoiceDialog.show({ _id: ref, type: 'SAG' });
        }

        // if (tranType === 'COMMISSION_PAYMENT') {
        //     navigate(`/bookings/${ref}/AG`);
        // }
    };

    return (
        <div>
            <div className="">
                {/* <div className="flex justify-between items-center px-6 pt-5">
                    <TableSearch placeholder="Search for a booking by name, PNR, reference" onSearch={handleSearch} />
                    <TableDateFilter onChange={handleDateFilter} />
                </div> */}

                <div className="mt-6">
                    <div className="flex justify-end px-6">
                        <button
                            onClick={AddPaymentDialog.show}
                            className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                            disabled={bankAccounts.isLoading}
                        >
                            Add Payment
                        </button>
                    </div>

                    <div className="overflow-auto my-6">
                        <table className="card-table text-sm">
                            <thead>
                                <tr>
                                    <th className="th-1">Date</th>
                                    <th className="th-1">Transaction Type</th>
                                    <th className="th-1">Refrence ID</th>
                                    <th className="th-1">Credit</th>
                                    <th className="th-1">Debit</th>
                                    <td className="th-1 text-right">Balance</td>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map((row, i) => {
                                    return (
                                        <tr key={i} className="border-b border-gray-200 last:border-none">
                                            <td className="td-1">
                                                {`${formatDate(row.createdAt)} ${moment(
                                                    row.createdAt,
                                                    'YYYYMMDDHHmmss'
                                                ).format('hh:mm a')}`}{' '}
                                            </td>

                                            {/* transation id */}
                                            <td className="td-1 ">
                                                <div className="font-bold">{transactionTypeMessage[row.type]}</div>
                                                {row.category === 'INVOICE' && (
                                                    <Tooltip
                                                        content={
                                                            <div className="w-56 h-32 p-4">
                                                                <div className="font-semibold">Passenger(s)</div>
                                                                <ul className="text-sm">
                                                                    {row.metadata?.passengerNames.map((pax, index) => (
                                                                        <li>
                                                                            {' '}
                                                                            {index + 1}) {pax}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        }
                                                    >
                                                        <div className="text-sm">
                                                            {`${row.metadata?.segment} / ${
                                                                row.metadata?.departureDate
                                                                    ? moment(
                                                                          row.metadata.departureDate,
                                                                          'YYYYMMDD'
                                                                      ).format('DD MMM, YYYY')
                                                                    : ' - '
                                                            } / ${row.metadata?.pnr} / ${
                                                                row.metadata?.passengerNames.length
                                                            } Pax `}
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </td>

                                            <td className="td-1  w-max">
                                                {row.referenceId || '-'}
                                                {/* {row.referenceId && (
                                                    <div
                                                        onClick={() =>
                                                            handleDialogOpenner(row.category, row._id, row.referenceId)
                                                        }
                                                        className="text-blue-500  cursor-pointer"
                                                    >
                                                        {row.category === 'PAYMENT' ? 'View Receipt' : ''}
                                                        {row.category === 'CREDIT' && row.type === 'PAYMENT_NOTE'
                                                            ? 'View  Credit Note'
                                                            : row.category === 'DEBIT' && row.type === 'PAYMENT_NOTE'
                                                            ? 'View Debit Note'
                                                            : ''}

                                                        {row.category === 'INVOICE' ? 'View Invoice' : ''}
                                                        {row.category === 'COMMISSION_PAYMENT' ? 'View Booking' : ''}
                                                    </div>
                                                )} */}

                                                {row.category === 'INVOICE' && (
                                                    <div>
                                                        <div>
                                                            <div
                                                                className="cursor-pointer text-blue-500"
                                                                // onClick={() => paymentDialog.show(row._id)}
                                                                onClick={() =>
                                                                    invoiceDialog.show({
                                                                        _id: row.referenceId,
                                                                        type: 'SAG',
                                                                    })
                                                                }
                                                            >
                                                                View Invoice
                                                            </div>
                                                        </div>

                                                        {/* invoiceDialog.show({ _id: ref, type: 'SAG' }); */}

                                                        {/* <Link
                                                            className="text-blue-500"
                                                            to={`/my-series/bookings/${row.referenceId}/invoice`}
                                                        >
                                                            View Invoice
                                                        </Link> */}
                                                    </div>
                                                )}

                                                {row.category === 'PAYMENT' && (
                                                    <div>
                                                        <div
                                                            className="cursor-pointer text-blue-500"
                                                            onClick={() => paymentDialog.show(row._id)}
                                                        >
                                                            View Receipt
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="flex flex-col ">
                                                    {/* <div>{row.id || '-'} </div>
                                                    {row.category === 'PAYMENT' && (
                                                        <span
                                                            className="text-blue-500 cursor-pointer"
                                                            onClick={() => {
                                                                setSelectedTransactionId(i);
                                                                paymentDialog.show();
                                                            }}
                                                        >
                                                            View Details
                                                        </span>
                                                    )} */}
                                                </div>
                                            </td>
                                            <td className="td-1">
                                                {row.category === 'INVOICE' ? (
                                                    <span className="text-green-500">{formatCurrency(row.amount)}</span>
                                                ) : (
                                                    '-'
                                                )}

                                                {/* {row.category === 'INVOICE' || row.category === 'OPENING_BALANCE' ? (
                                                    row.category === 'OPENING_BALANCE' && row.amount > 0 ? (
                                                        <span className="text-green-500">
                                                            {formatCurrency(row.amount)}{' '}
                                                        </span>
                                                    ) : row.category === 'INVOICE' ? (
                                                        <span className="text-green-500">
                                                            {formatCurrency(row.amount)}{' '}
                                                        </span>
                                                    ) : (
                                                        '-'
                                                    )
                                                ) : (
                                                    '-'
                                                )} */}
                                            </td>
                                            <td className="td-1">
                                                {row.category === 'PAYMENT' ? (
                                                    <span className="text-red-500">{formatCurrency(row.amount)}</span>
                                                ) : (
                                                    '-'
                                                )}
                                                {/* {row.category === 'PAYMENT' || row.category === 'OPENING_BALANCE' ? (
                                                    row.category === 'OPENING_BALANCE' && row.amount <= 0 ? (
                                                        <span className="text-red-500">
                                                            {formatCurrency(row.amount)}{' '}
                                                        </span>
                                                    ) : row.category === 'PAYMENT' ? (
                                                        <span className="text-red-500">
                                                            {formatCurrency(row.amount)}{' '}
                                                        </span>
                                                    ) : (
                                                        '-'
                                                    )
                                                ) : (
                                                    '-'
                                                )} */}
                                            </td>

                                            <td className="td-1 text-right ">{formatCurrency(row.balance)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <PaymentAddDialog
                        show={AddPaymentDialog.isOpen}
                        onClose={AddPaymentDialog.hide}
                        bankList={bankAccounts.data}
                        subAgencyId={subAgencyId}
                    />

                    {paymentDialog.isOpen && (
                        <PaymentReceiptDialog
                            show={paymentDialog.isOpen}
                            onClose={paymentDialog.hide}
                            transactionId={paymentDialog.data}
                        />
                    )}

                    {invoiceDialog.isOpen && (
                        <BookingInvoiceDialog
                            show={invoiceDialog.isOpen}
                            onClose={invoiceDialog.hide}
                            bookingId={invoiceDialog.data._id}
                            type={invoiceDialog.data.type}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SeriesSupplierLedger;
