import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { assertUnreachable } from './common-utils';
import { bookingStatusEnum } from 'app/enums/booking-status.enum';

export const getFormattedStops = (stops: number) => {
    if (stops <= 1) return 'Non Stop';
    else if (stops === 2) return '1 Stop';
    else return stops + ' Stops';
};

export const calculateStops = (segments: any[]) => {
    let stops = segments.length - 1;
    segments.forEach((s) => {
        stops += s.stops;
    });
    return stops;
};

export const getFormattedRefundableType = (key: string) => {
    const RefundableType: any = {
        NON_REFUNDABLE: 'Non-refundable',
        REFUNDABLE: 'Refundable',
        PARTIAL_REFUNDABLE: 'Partial Refundable',
    };

    return RefundableType[key];
};

export const getStatusClass = (status: string): string => {
    switch (status) {
        case bookingStatusEnum.PENDING:
        case bookingStatusEnum.SUBMISSION_PENDING:
        case bookingStatusEnum.AWAITING_PNR:
        case bookingStatusEnum.IN_PROCESS:
            return 'yellow';
        case bookingStatusEnum.COMPLETED:
        case bookingStatusEnum.SUBMITTED:
            return 'green';

        case bookingStatusEnum.CANCELLED:
        case bookingStatusEnum.SUB_AGENT_ABORT:
        case bookingStatusEnum.AGENT_ABORT:
        case bookingStatusEnum.FAILED:
        case bookingStatusEnum.ROLLED_BACK:
            return 'red';
    }
};

export const getStatusText = (status: string): string => {
    switch (status) {
        case bookingStatusEnum.PENDING:
            return 'Pending';
        case bookingStatusEnum.AWAITING_PNR:
            return 'Awaiting PNR';
        case bookingStatusEnum.SUBMISSION_PENDING:
            return 'Submission Pending';
        case bookingStatusEnum.IN_PROCESS:
            return 'Processing';
        case bookingStatusEnum.COMPLETED:
            return 'Completed';
        case bookingStatusEnum.SUBMITTED:
            return 'Submitted';
        case bookingStatusEnum.CANCELLED:
            return 'Cancelled';
        case bookingStatusEnum.SUB_AGENT_ABORT:
            return 'Sub Agent Aborted';
        case bookingStatusEnum.AGENT_ABORT:
            return 'You Aborted';
        case bookingStatusEnum.FAILED:
            return 'Failed';
        case bookingStatusEnum.ROLLED_BACK:
            return 'Rolled Back';
    }
};

export const getRefundTypeBackgroundColor = (status: string) => {
    switch (status) {
        case 'REFUNDABLE':
            return 'bg-success';
        case 'NON_REFUNDABLE':
            return 'bg-danger';
        case 'PARTIAL_REFUNDABLE':
            return 'bg-yellow-400';
        default:
            return 'bg-slate-500';
    }
};

export const getFareSupplier = (fareSupplier: string) => {
    switch (fareSupplier) {
        case '0':
            return 'Series Fare';
        case '1':
            return 'TripJack API';
        case '2':
            return 'Series Fare';
        case '3':
            return 'Air IQ';
        case '4':
            return 'Travel Boutique';
        case '5':
            return 'Kafila';
        default:
            return '-';
    }
};

export const getFareSupplierLabelFromEnum = (fareSuppler: FareSupplierEnum): string => {
    switch (fareSuppler) {
        case FareSupplierEnum.AGENCY_SERIES:
            return 'Series Inventory';
        case FareSupplierEnum.AIR_IQ:
            return 'Air IQ';
        case FareSupplierEnum.FARE_BOUTIQUE:
            return 'Fare Boutique';
        case FareSupplierEnum.KAFILA:
            return 'Kafila';
        case FareSupplierEnum.MY_SERIES:
            return 'Sub Agency Series';
        case FareSupplierEnum.SPICE_JET:
            return 'Spice Jet';
        case FareSupplierEnum.TRAVEL_BOUTIQUE:
            return 'Travel Boutique';
        case FareSupplierEnum.TRIP_JACK:
            return 'TripJack';
        case FareSupplierEnum.UNIVERSAL_API:
            return 'Universal Api';
        default:
            assertUnreachable(fareSuppler);
    }
};
