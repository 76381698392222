import { SeperatorDotIcon } from 'app/icons';
import { getCabinClassColor } from 'app/utils/color-utils';
import { formatDate, formatMinutesToTime, formatTime } from 'app/utils/date-utils';
import {
    calculateStops,
    getFormattedRefundableType,
    getFormattedStops,
    getStatusClass,
    getStatusText,
} from 'app/utils/flight-utils';
import capitalize from 'lodash.capitalize';
import { FC } from 'react';
import { EditPencilIcon } from 'app/icons';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import useDialog from 'app/hooks/use-dialog';
import Modal from 'app/components/Modal/Modal';
import { bookingStatusEnum } from 'app/enums/booking-status.enum';
import { StatusUpdateModal } from 'app/pages/Booking/BookingList/components/BookingTable/BookingTable';
import { IBooking } from 'app/types';


interface FlightDetailsCardProps {
    booking: any;
    isEditable?: boolean;
    openModal?: () => void;
}

const FlightDetailsCard: FC<FlightDetailsCardProps> = (props) => {
    const { booking } = props;
    const failureLogDialog = useDialog('');
    const controllerStatusUpdateModal = useDialog<IBooking>();

    const getFareType = () => (booking.fareSupplier === '0' ? 'Series' : 'Normal');
    const getCabinClass = () => booking.priceDetail.fareDescription.adult.cabinClass;
    const getRefundableType = () => booking.priceDetail.fareDescription.adult.refundableType;
    const cabinColor = getCabinClass() ? getCabinClassColor(getCabinClass()) : 'bg-primary';

    return (
        <>
            <div className="card px-6 pt-5 pb-6">
                <div className="flex items-center justify-between">
                    <div className="flex  items-centers gap-4">
                        <div className="font-bold">Flight Details - {booking.id} </div>
                        <StatusBadge className="" color={getStatusClass(booking.status)}>
                            {getStatusText(booking.status)}
                        </StatusBadge>
                    </div>
                    <div className="flex gap-2 items-center">
                        {!props.isEditable && booking.failureLog && (
                            <button
                                onClick={() => failureLogDialog.show()}
                                className="text-blue-500 text-base font-bold hover:bg-blue-50 px-4 py-1 rounded-xl "
                            >
                                View Log
                            </button>
                        )}
                        {props.isEditable && (
                            <div onClick={props.openModal} className="hover:cursor-pointer">
                                <EditPencilIcon />
                            </div>
                        )}
                        {!props.isEditable && booking.status === bookingStatusEnum.IN_PROCESS && (
                            <button
                                onClick={() => controllerStatusUpdateModal.show(booking)}
                                className="text-blue-500 text-base font-bold hover:bg-blue-50 px-4 py-1 rounded-xl "
                            >
                                Update Status
                            </button>
                        )}
                    </div>
                </div>

                <div className="mt-8">
                    <div className="flex justify-between gap-2">
                        <div>
                            <div className="font-semibold">
                                {booking.departure.airport.city} <span className="font-light"> to </span>{' '}
                                {booking.arrival.airport.city}
                            </div>
                            <div className="flex items-center gap-2 text-sm mt-2">
                                <div>{formatDate(booking.departure.date, 'dddd, MMM D')}</div>
                                <SeperatorDotIcon />
                                <div>
                                    <span className="mr-2">{getFormattedStops(calculateStops(booking.segments))}</span>
                                    <span>{formatMinutesToTime(booking.duration)}</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-end">
                            {getRefundableType() && (
                                <div
                                    className={`rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium ${
                                        getRefundableType() === 'NON_REFUNDABLE' ? 'bg-danger' : 'bg-success'
                                    }`}
                                >
                                    {getFormattedRefundableType(getRefundableType())}
                                </div>
                            )}
                            <div className="flex items-center gap-2 text-sm mt-2">
                                {/* <div>{capitalize(getCabinClass().toLowerCase())}</div> */}
                                <div
                                    className={`rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium 
                                        ${cabinColor}`}
                                >
                                    {capitalize(getCabinClass().toLowerCase())}
                                </div>
                                <SeperatorDotIcon />
                                <div>{getFareType()} Fare</div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-7 space-y-6 overflow-auto">
                        {booking.segments.map((segment: any, i: number) => (
                            <div key={i}>
                                <div className="flex justify-between items-center gap-6">
                                    <div className="flex items-center gap-2 min-w-max">
                                        <img
                                            crossOrigin="anonymous"
                                            className="w-9 h-9"
                                            src={segment.flight.logo}
                                            alt=""
                                        />
                                        <div>
                                            <div className="text-base font-semibold">{segment.flight.name}</div>
                                            <div className="text-xs text-black/70 mt-[2px]">{`${segment.flight.code} ${segment.flight.number}`}</div>
                                        </div>
                                    </div>
                                    <div className="flex justify-center gap-6 min-w-max">
                                        <div>
                                            <div className="flex gap-2">
                                                <div className="font-medium">{formatDate(segment.departure.date)}</div>
                                                <div className="font-semibold">
                                                    {formatTime(segment.departure.time)}
                                                </div>
                                            </div>
                                            <div className="text-xs text-black/70 mt-[2px] w-28">
                                                {segment.departure.airport.name}
                                                {segment.departure.airport.terminal &&
                                                    ', ' + segment.departure.airport.terminal}
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-center mt-1">
                                            <div className="text-xs text-black/70">
                                                {formatMinutesToTime(segment.duration)}
                                            </div>
                                            {/* Line */}
                                            <div className="bg-[#ECAE0E] h-1 w-24 rounded-lg mt-1" />
                                            {/*  */}
                                        </div>
                                        <div>
                                            <div className="flex gap-2">
                                                <div className="font-medium">{formatDate(segment.arrival.date)}</div>
                                                <div className="font-semibold">{formatTime(segment.arrival.time)}</div>
                                            </div>
                                            <div className="text-xs text-black/70 mt-[2px] w-28">
                                                {segment.arrival.airport.name}{' '}
                                                {segment.arrival.airport.terminal &&
                                                    ', ' + segment.arrival.airport.terminal}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="min-w-max">
                                        <div className="font-semibold">
                                            {booking.priceDetail.fareDescription.adult?.baggage?.checkIn}
                                        </div>
                                        <div className="text-xs text-black/70 mt-[2px]">Check-in</div>
                                    </div>
                                    <div className="min-w-max">
                                        <div className="font-semibold">
                                            {booking.priceDetail.fareDescription.adult?.baggage?.cabin}
                                        </div>
                                        <div className="text-xs text-black/70 mt-[2px]">Cabin</div>
                                    </div>
                                </div>
                                {segment.layoverDuration !== 0 && (
                                    <div className="mx-auto w-60 mt-6 bg-gray-100 rounded-full px-8 py-2 text-sm text-center">
                                        Layover Time -{' '}
                                        <span className="font-semibold">
                                            {formatMinutesToTime(segment.layoverDuration)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <FailureLogModal
                failureLog={booking.failureLog}
                id={booking.id}
                show={failureLogDialog.isOpen}
                onClose={failureLogDialog.hide}
            />

            {controllerStatusUpdateModal.isOpen && (
                <StatusUpdateModal
                    booking={controllerStatusUpdateModal.data}
                    show={controllerStatusUpdateModal.isOpen}
                    onClose={controllerStatusUpdateModal.hide}
                    identifier={[['bookingDetail', controllerStatusUpdateModal.data._id]]}
                />
            )}
        </>
    );
};

FlightDetailsCard.defaultProps = {
    isEditable: false,
    openModal: () => {},
};

interface FailureLogModalProps {
    show: boolean;
    onClose: () => void;
    failureLog: string;
    id: string;
}

const FailureLogModal: FC<FailureLogModalProps> = (props) => {
    return (
        <>
            <Modal show={props.show} onClose={props.onClose} className='py-3 min-h-[15rem]'>
                <div className='px-6 mt-4'>
                    <div className='font-bold'>Failure Log - {props.id}</div>
                </div>
                <div className='overflow-auto mt-6'>
                    <div className='px-6 w-[480px] max-w-full'>{props.failureLog}</div>
                </div>
            </Modal>
        </>
    );
};



export default FlightDetailsCard;
