import { FC } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Spinner from 'app/components/Spinner/Spinner';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAgencySettings, updateAgencySettings } from 'app/api/services/agency.service';
import { IAgencySetting } from 'app/types/agency-setting';
import { CrossIcon, DeleteIcon, InfoCircleIcon } from 'app/icons';
import SearchComponent from 'app/components/SearchComponent/SearchComponent';
import { getAirlines } from 'app/api/services/airline.service';
import { IAirline } from 'app/types';
import Tooltip from 'app/components/Tooltip/Tooltip';

const validationSchema = Yup.object({
    pricingStepIncrement: Yup.number()
        .required('This field is required')
        .min(0, 'The minimum value you can enter is 0.')
        .max(100, 'The maximum value you can enter is 100.'),
    isLowBalanceAlertEnabled: Yup.boolean(),
    lowBalanceAlert: Yup.number().required('This field is required').min(0, 'The minimum value you can enter is 0.'),

    gstRate: Yup.number()
        .required('This field is required')
        .min(0, 'The minimum value you can enter is 0.')
        .max(100, 'The maximum value you can enter is 100.'),
    commissionTdsRate: Yup.number()
        .required('This field is required')
        .min(0, 'The minimum value you can enter is 0.')
        .max(100, 'The maximum value you can enter is 100.'),
    fareDisplayCount: Yup.number()
        .required('This field is required')
        .typeError('You must specify a number')
        .min(1, 'Value must be greater than zero')
        .max(10, 'Maximum value can be 10'),
});

type IAgencySettingForm = Omit<IAgencySetting, 'agency' | '_id'>;

interface IAgencySettingFormProps {}

const AgencySettingForm: FC<IAgencySettingFormProps> = (props) => {
    const { data: agencySettings, isLoading } = useQuery(['agency config'], getAgencySettings);
    const agencySettingFormMutation = useMutation((values: IAgencySettingForm) => updateAgencySettings(values));

    const getAirlineMutation = useMutation((query: string) => getAirlines(query));

    const getAirlineSuggestion = async (query: string) => {
        try {
            const airlines = await getAirlineMutation.mutateAsync(query);
            return airlines;
        } catch (e) {}
    };

    if (isLoading)
        return (
            <div className="mt-12 flex justify-center items-center">
                <Spinner />
            </div>
        );

    const initialValues: IAgencySettingForm = {
        pricingStepIncrement: agencySettings.pricingStepIncrement,
        gstRate: agencySettings.gstRate,
        commissionTdsRate: agencySettings.commissionTdsRate,
        lowBalanceAlert: agencySettings.lowBalanceAlert,
        isLowBalanceAlertEnabled: agencySettings.isLowBalanceAlertEnabled,
        amendmentTypes: agencySettings.amendmentTypes,
        compulsoryDobDomesticAirlineCodes: agencySettings.compulsoryDobDomesticAirlineCodes,
        fareDisplayCount: agencySettings.fareDisplayCount,
    };

    const handleSubmit = async (values: IAgencySettingForm) => {
        try {
            await agencySettingFormMutation.mutateAsync({ ...values });
            toast.success('Agency config updated successfully');
        } catch (e) {
            toast.error('Something went wrong.');
        }
    };

    return (
        <div className="mt-8">
            {/* <div className="font-bold">Agency Config Settings</div> */}
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ values }) => (
                    <Form>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="fareDisplayCount">
                                    FARE DISPLAY COUNT
                                </label>
                                <Field id="fareDisplayCount" name="fareDisplayCount" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="fareDisplayCount" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2 label-required " htmlFor="pricingStepIncrement">
                                    Sub Agency Series Increment Step
                                </label>
                                <Field
                                    name="pricingStepIncrement"
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                />
                                <div className="form-error">
                                    <ErrorMessage name="pricingStepIncrement" />
                                </div>
                            </div>
                            <div className="col-span-4" />
                            <div className="col-span-2 self-center">
                                <div className="flex items-center gap-2 mt-4">
                                    <Field
                                        type="checkbox"
                                        id="isLowBalanceAlertEnabled"
                                        name="isLowBalanceAlertEnabled"
                                        className="form-switch success"
                                    />

                                    <label htmlFor="isLowBalanceAlertEnabled">Low Balance Alert</label>
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2 label-required " htmlFor="lowBalanceAlert">
                                    Low Balance Alert
                                </label>
                                <Field
                                    name="lowBalanceAlert"
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                    disabled={!values.isLowBalanceAlertEnabled}
                                />
                                <div className="form-error">
                                    <ErrorMessage name="lowBalanceAlert" />
                                </div>
                            </div>
                            <div className="col-span-6" />
                            <div className="col-span-4">
                                <label className="text-xs mb-2 label-required " htmlFor="gstRate">
                                    GST Rate
                                </label>
                                <div className="input-group">
                                    <div className="input-group-postpend">%</div>
                                    <Field name="gstRate" type="number" className="form-control" placeholder="" />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="gstRate" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2 label-required " htmlFor="commissionTdsRate">
                                    Commission TDS Rate
                                </label>
                                <div className="input-group">
                                    <div className="input-group-postpend">%</div>
                                    <Field
                                        name="commissionTdsRate"
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                    />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="commissionTdsRate" />
                                </div>
                            </div>
                            <div className="col-span-2" />
                            <div className="col-span-4">
                                <label className="text-xs mb-2 label-required " htmlFor="amendmentTypes">
                                    Amendment Types
                                </label>

                                <FieldArray name="amendmentTypes">
                                    {({ push, remove }) => (
                                        <>
                                            <div className="form-control min-h-[144px] p-4 ">
                                                {values.amendmentTypes.map((amendmentType, index) => (
                                                    <div
                                                        className="cursor-default  flex items-center justify-between px-2 py-1 hover:bg-gray-50 rounded-xl "
                                                        key={amendmentType}
                                                    >
                                                        <span>{amendmentType}</span>
                                                        <div
                                                            onClick={() => remove(index)}
                                                            className="cursor-pointer text-red-500  hover:text-red-700"
                                                        >
                                                            <DeleteIcon height={20} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>{' '}
                                            <input
                                                className="form-control mt-6 w-full"
                                                placeholder="Add Amendment"
                                                onKeyDownCapture={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    if (event.key === 'Enter') {
                                                        event.preventDefault();
                                                        const tmpValue = event.currentTarget.value.trim();
                                                        if (tmpValue.length === 0) return;
                                                        push(tmpValue);
                                                        event.currentTarget.value = '';
                                                    }
                                                }}
                                            />
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                            {/* <div className="col-span-12">
                                <FieldArray name={'compulsoryDobDomesticAirlineCodes'}>
                                    {({ push, remove, handlePush }) => (
                                        <div className="grid grid-cols-12">
                                            <div className="mt-6 col-span-12">
                                                <label className="text-xs mb-2 label-required " htmlFor="flight">
                                                    Selected Airlines
                                                </label>
                                                <div className="form-control min-h-[144px] p-4 flex gap-4 items-start">
                                                    {values.compulsoryDobDomesticAirlineCodes.map((airline, index) => (
                                                        <div
                                                            className="cursor-default bg-primary/80  inline-flex  items-center gap-2  px-4 py-2 text-white rounded-3xl"
                                                            key={airline}
                                                        >
                                                            <span>{airline}</span>
                                                            <div
                                                                onClick={() => remove(index)}
                                                                className="cursor-pointer hover:text-black/20"
                                                            >
                                                                <DeleteIcon height={20} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-span-4">
                                                <label className="text-xs mb-2 label-required " htmlFor="flight">
                                                    Select Airline
                                                </label>
                                                <SearchComponent
                                                    fetchSuggestions={getAirlineSuggestion}
                                                    setField={(airline: IAirline) => push(airline.code)}
                                                    selectedItem={null}
                                                    placeholder="Search airline"
                                                    initialSuggestions={[]}
                                                    getSuggestionValue={(airline: IAirline) =>
                                                        `${airline.code} - ${airline.name}`
                                                    }
                                                    renderSuggestion={(airline: IAirline) => (
                                                        <div> {airline.name} </div>
                                                    )}
                                                    clearInputOnSelection={true}
                                                />
                                                <div className="form-error">
                                                    <ErrorMessage name="flight" />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </FieldArray>
                            </div> */}
                            <div className="col-span-8" />
                            <div className="col-span-4">
                                <div className="flex gap-2 items-center">
                                    <label
                                        className="flex items-center gap-1 text-xs mb-2 label-required "
                                        htmlFor="compulsoryDobDomesticAirlineCodes"
                                    >
                                        Mandatory D.O.B
                                        <Tooltip
                                            placement="right-end"
                                            content={
                                                <div className="px-4 py-3 text-sm  text-black/50">
                                                    Date of birth will be mandatory for the selected airlines.
                                                </div>
                                            }
                                        >
                                            <div className="py-2">
                                                <InfoCircleIcon height={10} />
                                            </div>
                                        </Tooltip>
                                    </label>
                                </div>

                                <FieldArray name="compulsoryDobDomesticAirlineCodes">
                                    {({ push, remove }) => (
                                        <>
                                            <div className="form-control min-h-[144px] p-4 ">
                                                {values.compulsoryDobDomesticAirlineCodes.map((airline, index) => (
                                                    <div
                                                        className="cursor-default  flex items-center justify-between px-2 py-1 hover:bg-gray-50 rounded-xl "
                                                        key={airline}
                                                    >
                                                        <span>{airline}</span>
                                                        <div
                                                            onClick={() => remove(index)}
                                                            className="cursor-pointer text-red-500  hover:text-red-700"
                                                        >
                                                            <DeleteIcon height={20} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>{' '}
                                            <div className="col-span-4">
                                                <label className="text-xs mb-2 label-required " htmlFor="flight">
                                                    Select Airline
                                                </label>
                                                <SearchComponent
                                                    fetchSuggestions={getAirlineSuggestion}
                                                    setField={(airline: IAirline) => push(airline.code)}
                                                    selectedItem={null}
                                                    placeholder="Search airline"
                                                    initialSuggestions={[]}
                                                    getSuggestionValue={(airline: IAirline) =>
                                                        `${airline.code} - ${airline.name}`
                                                    }
                                                    renderSuggestion={(airline: IAirline) => (
                                                        <div> {airline.name} </div>
                                                    )}
                                                    clearInputOnSelection={true}
                                                />
                                                <div className="form-error">
                                                    <ErrorMessage name="flight" />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </div>
                        <button
                            disabled={agencySettingFormMutation.isLoading}
                            type="submit"
                            className="flex justify-center items-center h-12 px-4 bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-14 disabled:bg-primary/50"
                        >
                            Save
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AgencySettingForm;
