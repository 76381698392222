import http from 'app/config/http';
import { useQuery } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import TableSearch from 'app/components/TableSearch/TableSearch';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';

import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import AmendmentTable from '../AmendmentTable/AmendmentTable';
import { getAmendmentStatusText } from 'app/utils/common-utils';
import { AmendmentStatusEnum } from 'app/enums/amendment.status.enum';

interface AmendmentListProps {}

const AmendmentList: FC<AmendmentListProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageCount, setPageCount] = useState<number>(0);

    let fromDate = searchParams.get('fromDate') || '';
    let toDate = searchParams.get('toDate') || '';
    let page = searchParams.get('page') || 1;
    let query = searchParams.get('query') || '';
    let status = searchParams.get('status') || '';

    const allAmendmentsQuery = useQuery(['all amendments', fromDate, toDate, page, query, status], async () => {
        let params = '?';
        page ? (params += `page=${page}`) : (params += '');
        fromDate ? (params += `&fromDate=${fromDate}`) : (params += '');
        toDate ? (params += `&toDate=${toDate}`) : (params += '');
        query ? (params += `&searchParam=${query}`) : (params += '');
        status ? (params += `&status=${status}`) : (params += '');

        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/amendments${params}`);
        setPageCount(data.pageCount);
        return data.data;
    });

    const handlePageChange = (val: any) => {
        let newPage = val.selected + 1;
        searchParams.set('page', newPage);
        setSearchParams(searchParams, { replace: true });
    };

    const debouncedSearch = debounce((q) => {
        if (q !== '') {
            searchParams.set('query', q);
        } else {
            searchParams.delete('query');
        }
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    }, 300);

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };
    const handleDateFilter = (date1: string, date2: string) => {
        date1 ? searchParams.set('fromDate', date1) : searchParams.delete('fromDate');
        date2 ? searchParams.set('toDate', date2) : searchParams.delete('toDate');
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    };

    const handleAmendmentStatusChangeFilter = (status: string) => {
        if (status === '') {
            searchParams.delete('status');
        } else {
            searchParams.set('status', status);
        }
        setSearchParams(searchParams, {
            replace: true,
        });
    };

    React.useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <div className="relative">
            <div className="flex items-center justify-between px-6 py-5">
                <TableSearch placeholder="Search for a payment by id, method" onSearch={handleSearch} value={query} />

                <select
                    className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium"
                    onChange={(e) => handleAmendmentStatusChangeFilter(e.target.value)}
                    value={status}
                >
                    <option value="">Filter by Status</option>
                    {Object.values(AmendmentStatusEnum).map((status) => (
                        <option key={status} value={status}>
                            {getAmendmentStatusText(status)}
                        </option>
                    ))}
                </select>

                <TableDateFilter onChange={handleDateFilter} />
            </div>

            <AmendmentTable
                amendmentList={allAmendmentsQuery.data}
                isLoading={allAmendmentsQuery.isLoading}
                refetchQueryIdentifier={['all amendments', fromDate, toDate, page, query, status]}
            />
            <div className="px-4 pb-3 ">
                <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
            </div>
        </div>
    );
};

export default AmendmentList;
