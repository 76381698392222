import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import http from 'app/config/http';
import useNavTab from 'app/hooks/use-nav-tab';
import { ISubAgency } from 'app/types';
import moment from 'moment';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import BookingList from './components/BookingList/BookingList';
import SubAgencyDetailNav from './components/SubAgencyDetailNav/SubAgencyDetailNav';
import SubAgencyTable from './components/SubAgencyTable/SubAgencyTable';
import TransactionList from './components/TransactionList/TransactionList';
import update from 'immutability-helper';
import { toast } from 'react-toastify';
import DocumentList from './components/DocumentList/DocumentList';
import SeriesSupplierLedger from './components/SeriesSupplierLedger/SeriesSupplierLedger';
import SubAgencyPluginList from './components/SubAgencyPluginList/SubAgencyPluginList';
import Bank from './components/Bank/Bank';
import TourQueryList from './components/TourQueryList/TourQueryList';
import PaymentNoteList from './components/PaymentNoteList/PaymentNoteList';
import SubAgencySeriesList from './components/SubAgencySeriesList/SubAgencySeriesList';
import SubAgencyCommissionPlanList from './components/SubAgencyCommissionPlanList/SubAgencyCommissionPlanList';
import { TLedgerItem } from './types/sub-agency-series-ledger';

interface SubAgencyDetailsProps {}

const SubAgencyDetails: FC<SubAgencyDetailsProps> = (props) => {
    const [activeTab, setActiveTab] = useNavTab('1');
    const { subAgencyId } = useParams();
    const queryClient = useQueryClient();

    const subAgencyQuery = useQuery<ISubAgency>(['subAgency', subAgencyId], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}`
        );
        return data;
    });

    const changeEnabledMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/is-enabled`,
            { isEnabled: values.isEnabled }
        );

        return data;
    });

    const subAgencyLedgerQuery = useQuery(['subAgency', subAgencyId, 'ledger'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/transactions`
        );
        return data;
    });

    // const subAgencyBookingQuery = useQuery(['subAgency', subAgencyId, 'bookings'], async () => {
    //     const { data } = await http.get(
    //         `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/bookings`
    //     );
    //     return data;
    // });

    const subAgencyDocumentQuery = useQuery(['subAgency', subAgencyId, 'documents'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/documents`
        );
        return data;
    });

    // const subAgencypluginsQuery = useQuery(['subAgency', subAgencyId, 'plugins'], async () => {
    //     const { data } = await http.get(
    //         `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/plugins`
    //     );
    //     return data;
    // });

    const ledgerQuery = useQuery<Array<TLedgerItem>>(['subAgency', subAgencyId, 'SeriesSupplierLedger'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/ledger`
        );
        return data;
    });

    const handleEnabledChange = async (row: ISubAgency, isChecked: boolean) => {
        queryClient.setQueryData(['subAgency', subAgencyId], (prevValues: any) => {
            return update(prevValues, {
                isEnabled: { $set: isChecked },
            });
        });

        try {
            await changeEnabledMutation.mutateAsync({ _id: row._id, isEnabled: isChecked });

            toast.success(`Sub Agency ${isChecked ? 'enabled' : 'disabled'} successfully`);
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');

            queryClient.setQueryData(['subAgencyDetail'], (prevValues: any) => {
                return update(prevValues, { isEnabled: { $set: !isChecked } });
            });
        }
    };

    if (
        subAgencyLedgerQuery.isLoading ||
        // subAgencyBookingQuery.isLoading ||
        subAgencyQuery.isLoading ||
        subAgencyDocumentQuery.isLoading ||
        ledgerQuery.isLoading
        // || subAgencypluginsQuery.isLoading
    )
        return <PageLoader />;

    const subAgency = subAgencyQuery.data;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>
            <div>
                <div className="flex justify-between items-center">
                    <div>
                        <h5 className="font-bold">{subAgency.name}</h5>

                        <div className="flex mt-2 gap-2 items-center">
                            <StatusBadge color={subAgency.isEnabled ? 'green' : 'red'}>
                                {subAgency.isEnabled ? 'Active' : 'Inactive'}
                            </StatusBadge>

                            <div>
                                <p>{moment(subAgency.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY hh:mm A')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center gap-5">
                        <div className="mb-2">
                            <label className="text-xs " htmlFor="isEnabled">
                                ENABLED
                            </label>

                            <div className="mt-[2px]">
                                <input
                                    type="checkbox"
                                    className="form-switch success"
                                    checked={subAgency.isEnabled}
                                    onChange={(e) => handleEnabledChange(subAgency, e.target.checked)}
                                />
                            </div>
                        </div>
                        <Link
                            to={`/sub-agencies/${subAgencyId}/edit`}
                            className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                        >
                            Edit Sub Agency
                        </Link>
                    </div>
                </div>
                <div className="card mt-8 pt-8">
                    <SubAgencyTable subAgency={subAgencyQuery.data} />
                </div>

                <div className="card mt-8">
                    <SubAgencyDetailNav activeTab={activeTab} setActiveTab={setActiveTab} />
                    {activeTab === '1' && <BookingList subAgencyId={subAgencyId} />}
                    {activeTab === '2' && (
                        <TransactionList subAgencyId={subAgencyId} transaction={subAgencyLedgerQuery.data} />
                    )}
                    {activeTab === '3' && <Bank banks={subAgencyQuery.data.banks} subAgencyId={subAgencyId} />}
                    {activeTab === '4' && (
                        <DocumentList documents={subAgencyDocumentQuery.data} subAgencyId={subAgencyId} />
                    )}
                    {activeTab === '5' && (
                        <SeriesSupplierLedger transactions={ledgerQuery.data} subAgencyId={subAgencyId} />
                    )}
                    {activeTab === '6' && (
                        // <SubAgencyPluginList plugins={subAgencypluginsQuery.data} subAgencyId={subAgencyId} />
                        <SubAgencyPluginList subAgencyId={subAgencyId} />
                    )}
                    {activeTab === '7' && <TourQueryList />}
                    {activeTab === '8' && <PaymentNoteList />}
                    {activeTab === '9' && <SubAgencySeriesList />}
                    {activeTab === '10' && <SubAgencyCommissionPlanList subAgency={subAgency.name} />}
                </div>
            </div>
        </>
    );
};

export default SubAgencyDetails;
