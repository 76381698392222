import React, { FC } from 'react';
import Modal from 'app/components/Modal/Modal';
import { FieldArray, Form, Formik } from 'formik';
import { PassengerTypeEnum } from 'app/enums/passenger-type.enum';
import PassengerFormItem from './components/PassengerFormItem/PassengerFormItem';
import { IBookingPassenger, IBookingPassengers, IPnrAndTicketNumber } from 'app/types';
import {
    convertDateInputFormatToSystemDateFormat,
    convertSystemDateFormatToDateInputFormat,
} from 'app/utils/date-utils';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ISegment } from 'app/types/manual-booking';

export type TPassengerDetails = {
    passengers: IBookingPassengers;
};

interface IEditPassengerDetailsDialog {
    show: boolean;
    onClose: () => void;
    passengers: IBookingPassengers;
    segments: Array<ISegment>;
    handleUpdate: (val: any) => void;
    isInternational: boolean;
    setBooking: React.Dispatch<any>;
}

const EditPassengerDetailsDialog: FC<IEditPassengerDetailsDialog> = (props) => {
    const { passengers, isInternational, setBooking, segments } = props;

    const generateSectorAndNumber = (): Array<IPnrAndTicketNumber> => {
        const tmp: Array<IPnrAndTicketNumber> = [];

        segments.forEach((segment) => {
            tmp.push({ sector: `${segment.departure.airport.code}-${segment.arrival.airport.code}`, number: '' });
        });

        return tmp;
    };

    const formatPassengerETicketPnrSeatNumber = (passenger: IBookingPassenger): IBookingPassenger => {
        return {
            ...passenger,
            pnrs: passenger.pnrs.length > 0 ? passenger.pnrs : generateSectorAndNumber(),
            ticketNumbers: passenger.ticketNumbers.length > 0 ? passenger.ticketNumbers : generateSectorAndNumber(),
            seatNumbers: passenger.seatNumbers.length > 0 ? passenger.seatNumbers : generateSectorAndNumber(),
        };
    };

    const formatPassengerForDateInput = (passenger: IBookingPassenger): IBookingPassenger => {
        return {
            ...passenger,
            dob: convertSystemDateFormatToDateInputFormat(passenger.dob),
            passport: {
                ...passenger.passport,
                issueDate: convertSystemDateFormatToDateInputFormat(passenger.passport.issueDate),
                expiry: convertSystemDateFormatToDateInputFormat(passenger.passport.expiry),
            },
        };
    };

    const formatPassengerForSystemDate = (passenger: IBookingPassenger): IBookingPassenger => {
        return {
            ...passenger,
            dob: convertDateInputFormatToSystemDateFormat(passenger.dob),
            passport: {
                ...passenger.passport,
                issueDate: convertDateInputFormatToSystemDateFormat(passenger.passport.issueDate),
                expiry: convertDateInputFormatToSystemDateFormat(passenger.passport.expiry),
            },
        };
    };

    const initialValues: TPassengerDetails = {
        passengers: {
            adults: passengers.adults.map((adult) =>
                formatPassengerETicketPnrSeatNumber(formatPassengerForDateInput(adult))
            ),
            children: passengers.children.map((child) =>
                formatPassengerETicketPnrSeatNumber(formatPassengerForDateInput(child))
            ),
            infants: passengers.infants.map((infant) =>
                formatPassengerETicketPnrSeatNumber(formatPassengerForDateInput(infant))
            ),
        },
    };

    console.log({ initialValues });

    const onSubmit = (values: TPassengerDetails) => {
        const deSerializedValue = {
            ...values,
            passengers: {
                adults: values.passengers.adults.map((adult) => formatPassengerForSystemDate(adult)),
                children: values.passengers.children.map((child) => formatPassengerForSystemDate(child)),
                infants: values.passengers.infants.map((infant) => formatPassengerForSystemDate(infant)),
            },
        };
        setBooking((prev: any) => {
            return { ...prev, ...deSerializedValue };
        });
        props.onClose();
    };

    const segmentCodes: Array<string> = [];

    segments.forEach((segment) => {
        segmentCodes.push(`${segment.departure.airport.code}-${segment.arrival.airport.code}`);
    });

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-8 pt-8 pb-10 w-[1280px]">
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ values }) => (
                    <Form>
                        {/* <button onClick={() => console.log({ values })}>hllo </button> */}

                        <FieldArray name="passengers.adults">
                            {({ remove }) => (
                                <>
                                    {values.passengers.adults.map((adult, index) => (
                                        <PassengerFormItem
                                            passengerIndex={index}
                                            passengerType={PassengerTypeEnum.ADULT}
                                            isInternational={isInternational}
                                            segmentCodes={segmentCodes}
                                            remove={remove}
                                        />
                                    ))}
                                </>
                            )}
                        </FieldArray>

                        <FieldArray name="passengers.children">
                            {({ remove }) => (
                                <>
                                    {values.passengers.children.map((child, index) => (
                                        <PassengerFormItem
                                            passengerIndex={index}
                                            passengerType={PassengerTypeEnum.CHILD}
                                            isInternational={isInternational}
                                            segmentCodes={segmentCodes}
                                            remove={remove}
                                        />
                                    ))}
                                </>
                            )}
                        </FieldArray>

                        <FieldArray name="passengers.infants">
                            {({ remove }) => (
                                <>
                                    {values.passengers.infants.map((infant, index) => (
                                        <PassengerFormItem
                                            passengerIndex={index}
                                            passengerType={PassengerTypeEnum.INFANT}
                                            isInternational={isInternational}
                                            segmentCodes={segmentCodes}
                                            remove={remove}
                                        />
                                    ))}
                                </>
                            )}
                        </FieldArray>

                        <LoaderButton className="bg-primary text-white px-4 py-6 rounded-lg mt-6">
                            Continue
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default EditPassengerDetailsDialog;
