import http from 'app/config/http';
import { IBookingFlow } from 'app/types/booking-flow';

export const createBookingFlowLogsByBookingId = async (bookingId: string) => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/booking-flows/booking/${bookingId}`);
    return data as IBookingFlow;
};

export const createBookingFlowLogsByBookingFlowId = async (bookingFlowId: string) => {
    const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}{{agent-api-url}}/api/v1/agent/booking-flows/${bookingFlowId}`
    );
    return data.bookingFlow._id as string;
};
