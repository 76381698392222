import moment from 'moment';
import ReactDOM from 'react-dom';
import { MenuDotIcon } from 'app/icons';
import useDialog from 'app/hooks/use-dialog';
import { formatDate } from 'app/utils/date-utils';
import { FC, useLayoutEffect, useRef } from 'react';
import Spinner from 'app/components/Spinner/Spinner';
import Tooltip from 'app/components/Tooltip/Tooltip';
import { IBooking, IBookingPassenger } from 'app/types';
import { formatCurrency } from 'app/utils/currency-utils';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getStatusClass, getStatusText } from 'app/utils/flight-utils';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';

interface IManualBookingTable {
    bookings: IBooking[];
    isLoading?: boolean;
}

export const getFirstPassengerName = (booking: IBooking) => {
    const firstPassenger = (booking?.passengers as unknown as IBookingPassenger[])[0] || booking.passengers.adults[0];
    return `${firstPassenger.title} ${firstPassenger.firstName} ${firstPassenger.lastName}`;
};

export const getPassengerName = (passenger: IBookingPassenger) => {
    return `${passenger.title} ${passenger.firstName} ${passenger.lastName}`;
};

const ManualBookingTable: FC<IManualBookingTable> = (props) => {
    const { bookings, isLoading } = props;
    const navigate = useNavigate();
    const tableTop = useRef();
    const controllerStatusUpdateModal = useDialog<IBooking>();
    const [searchParams] = useSearchParams();

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const generateQueryIdentifier = () => {
        let fromDate = searchParams.get('fromDate') || '';
        let toDate = searchParams.get('toDate') || '';
        let query = searchParams.get('query') || '';
        let page = searchParams.get('page') || 1;
        let bookingStatus = searchParams.get('bookingStatus') || '';

        return [
            ['agencyBookingListPaginated', query, page, fromDate, toDate, bookingStatus, 'All bookings'],
            ['agencyBookingListPaginated', query, page, fromDate, toDate, bookingStatus],
        ];
    };

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const handleNavigate = (booking: IBooking) => {
        return navigate(`/bookings/${booking._id}/AG`);
    };

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50" ref={tableTop}>
                        <th className="th-1">Trip ID</th>
                        <th className="th-1">Travel & Date</th>
                        <th className="th-1">Traveller(s)</th>
                        <th className="th-1">Flight Details</th>
                        <th className="th-1">Fare Details</th>
                        <th className="th-1">Booked By</th>
                        <th className="th-1">Created At</th>
                        <th className="th-1"></th>
                    </tr>
                </thead>
                <tbody className="">
                    {!isLoading && bookings.length === 0 && (
                        <tr>
                            <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                - No data available -
                            </td>
                        </tr>
                    )}

                    {!isLoading &&
                        bookings.map((row) => (
                            <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                <td className="td-1">
                                    <div
                                        className="font-semibold text-blue-500 !cursor-pointer"
                                        onClick={() => handleNavigate(row)}
                                    >
                                        {row._id}
                                    </div>
                                    <StatusBadge className="mt-1" color={getStatusClass(row.status)}>
                                        {getStatusText(row.status)}
                                    </StatusBadge>
                                </td>

                                <td className="td-1">
                                    <div className="font-semibold  text-nowrap">
                                        {row.trip.departure.airport.city} to {row.trip.arrival.airport.city}{' '}
                                    </div>
                                    <p className="mt-1">{formatDate(row.trip.departure.date, 'DD MMM, YYYY')}</p>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold text-nowrap">{getFirstPassengerName(row)}</div>
                                    {(row.passengers as unknown as IBookingPassenger[]).length > 1 && (
                                        <Tooltip
                                            content={
                                                <div className="px-6 py-4 w-48">
                                                    <ul className="space-y-4">
                                                        {(row.passengers as unknown as IBookingPassenger[]).map(
                                                            (row, i) => (
                                                                <li className="text-sm text-gray-500" key={i}>
                                                                    {getPassengerName(row)}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            }
                                            placement="bottom-start"
                                            triggers={['click']}
                                        >
                                            <p className="mt-1">
                                                + {(row.passengers as unknown as IBookingPassenger[]).length - 1}{' '}
                                                Passengers
                                            </p>
                                        </Tooltip>
                                    )}
                                </td>
                                <td className="td-1">
                                    <div className="flex items-center gap-3 min-w-max">
                                        <img className="w-9 h-9" crossOrigin="anonymous" src={row.flight.logo} alt="" />
                                        <div>
                                            <div className="font-semibold">{row.flight.name}</div>
                                            <p className="mt-1">
                                                {row.flight.code} {row.flight.number}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold text-nowrap">
                                        {formatCurrency(row.payment.amount)}
                                    </div>
                                    <p className="mt-1">Total</p>
                                </td>

                                <td className="td-1">
                                    <div className="font-semibold text-nowrap">
                                        <Link
                                            to={`/sub-agencies/${row?.bookedBy?._id}/details`}
                                            className="text text-primary cursor-pointer"
                                        >
                                            {row.bookedBy.name}
                                        </Link>
                                        <div className="min-w-max">
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                        </div>
                                    </div>
                                </td>

                                <td className="td-1">
                                    <Dropdown
                                        target={
                                            <a className="dropdown-action">
                                                <MenuDotIcon />{' '}
                                            </a>
                                        }
                                        menu={
                                            <DropdownList>
                                                <DropdownItem to={`/bookings/${row._id}/AG`}>View Ticket</DropdownItem>
                                                <DropdownItem
                                                    onClick={() => window.open(`/bookings/${row._id}/invoice/AG`)}
                                                >
                                                    Invoice
                                                </DropdownItem>
                                                {row.status === 'IN_PROCESS' && (
                                                    <DropdownItem onClick={() => controllerStatusUpdateModal.show(row)}>
                                                        Update Status
                                                    </DropdownItem>
                                                )}
                                            </DropdownList>
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {isLoading && (
                <div className="flex justify-center items-center min-h-[500px]">
                    <Spinner />
                </div>
            )}
            {/* {controllerStatusUpdateModal.isOpen && (
                <StatusUpdateModal
                    booking={controllerStatusUpdateModal.data}
                    show={controllerStatusUpdateModal.isOpen}
                    onClose={controllerStatusUpdateModal.hide}
                    identifier={generateQueryIdentifier()}
                />
            )} */}
        </div>
    );
};

export default ManualBookingTable;
