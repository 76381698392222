/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IPayableSubAgeny } from '../../types';
import { formatCurrency } from 'app/utils/currency-utils';

interface SubAgencyTableProps {
    subAgencies: IPayableSubAgeny[];
}

const SubAgencyTable: FC<SubAgencyTableProps> = (props) => {
    const { subAgencies } = props;

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50">
                        <th className="th-1">Agency ID</th>
                        <th className="th-1">Agency Name & Address</th>
                        <th className="th-1">Agency Contact & Email</th>
                        <th className="th-1">Amount to be Paid</th>
                    </tr>
                </thead>
                <tbody>
                    {subAgencies.map((row, i) => (
                        <tr key={row._id} className="border-b border-gray-200 last:border-none">
                            <td className="td-1">
                                <Link className="font-semibold text-blue-500" to={`/sub-agencies/${row._id}/details`}>
                                    {row._id}
                                </Link>
                            </td>
                            <td className="td-1">
                                <Link className="font-semibold text-blue-500" to={`/sub-agencies/${row._id}/details`}>
                                    {row.name}
                                </Link>
                                <p className="mt-1">
                                    {row.address.city}, {row.address.state}
                                </p>
                            </td>
                            <td className="td-1">
                                <div className="font-semibold">{row.phoneNumber}</div>
                                <p className="mt-1">{row.email}</p>
                            </td>
                            <td className="td-1">
                                <div className="font-semibold text-green-500 text-base">
                                    {formatCurrency(row.amountToBePaid)}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SubAgencyTable;
