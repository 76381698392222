import * as Yup from 'yup';
import { FC } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { MenuDotIcon } from 'app/icons';
import useDialog from 'app/hooks/use-dialog';
import Modal from 'app/components/Modal/Modal';
import Spinner from 'app/components/Spinner/Spinner';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SYSTEM_DATE_TIME_FORMAT } from 'app/utils/date-utils';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import { createReceivableNote, getReceivableNotes } from 'app/api/services/accounting.service';

enum DialogStateEnum {
    ADD_NOTE = 'ADD_NOTE',
    LIST_NOTE = 'LIST_NOTE',
    VIEW_NOTE = 'VIEW_NOTE',
}

type TNoteDialog = {
    dialogState: DialogStateEnum;
};

interface IReceivableNote {
    subAgencyId: string;
    subAgencyName: string;
}

const ReceivableNote: FC<IReceivableNote> = (props) => {
    const { subAgencyId, subAgencyName } = props;

    const noteDialog = useDialog<TNoteDialog | null>(null);

    return (
        <>
            <Dropdown
                target={
                    <a className="dropdown-action">
                        <MenuDotIcon />
                    </a>
                }
                menu={
                    <DropdownList>
                        <DropdownItem
                            onClick={() =>
                                noteDialog.show({
                                    dialogState: DialogStateEnum.ADD_NOTE,
                                })
                            }
                        >
                            Add Remark
                        </DropdownItem>
                        <DropdownItem
                            onClick={() =>
                                noteDialog.show({
                                    dialogState: DialogStateEnum.LIST_NOTE,
                                })
                            }
                        >
                            <span className="">View Remarks</span>
                        </DropdownItem>
                    </DropdownList>
                }
            />
            {noteDialog.isOpen && (
                <ReceivableNoteModal
                    dialogState={noteDialog.data.dialogState}
                    onClose={noteDialog.hide}
                    show={noteDialog.isOpen}
                    subAgencyId={subAgencyId}
                    subAgencyName={subAgencyName}
                />
            )}
        </>
    );
};

interface IReceivableNoteModal {
    onClose: () => void;
    show: boolean;
    dialogState: DialogStateEnum;
    subAgencyId: string;
    subAgencyName: string;
}

const ReceivableNoteModal: FC<IReceivableNoteModal> = (props) => {
    const { show, onClose, dialogState, subAgencyId, subAgencyName } = props;

    const createNoteMutation = useMutation((note: string) =>
        createReceivableNote({ subAgencyId: subAgencyId, note: note })
    );

    const { data: paymentReminderNotes, isLoading } = useQuery(['PAYMENT_REMINDER_NOTE', subAgencyId], () =>
        getReceivableNotes({ subAgencyId })
    );

    return (
        <Modal onClose={onClose} show={show} className="w-[960px]  p-6 overflow-scroll ">
            <div className=" text-xl font-bold">Payment Reminder Remark - {`${subAgencyId} | ${subAgencyName}`} </div>
            <div className="mt-4">
                {dialogState === DialogStateEnum.ADD_NOTE && (
                    <>
                        <Formik
                            initialValues={{ message: '' }}
                            onSubmit={async ({ message }) => {
                                try {
                                    await createNoteMutation.mutateAsync(message);
                                    toast.success('Payment reminder note created successfully');
                                    onClose();
                                } catch (e: any) {
                                    toast.error(e?.data?.message || 'Something went wrong');
                                }
                            }}
                            validationSchema={Yup.object({
                                message: Yup.string().trim().required('This field is required'),
                            })}
                        >
                            <Form>
                                <label className="text-xs mb-2" htmlFor="message">
                                    Remark
                                </label>

                                <Field
                                    as="textarea"
                                    id="message"
                                    name="message"
                                    placeholder="Add remark"
                                    rows={8}
                                    className="form-control"
                                />

                                <div className="form-error">
                                    <ErrorMessage name="message" />
                                </div>

                                <LoaderButton
                                    type="submit"
                                    className="bg-primary text-white w-32 px-4 py-6 rounded-lg mt-6"
                                    isLoading={createNoteMutation.isLoading}
                                >
                                    Save
                                </LoaderButton>
                            </Form>
                        </Formik>
                    </>
                )}

                {dialogState === DialogStateEnum.LIST_NOTE && (
                    <div className="px-2 pr-3 h-80 overflow-scroll">
                        {isLoading && (
                            <div className="flex justify-center items-center h-56">
                                <Spinner />
                            </div>
                        )}

                        {!isLoading && paymentReminderNotes.length === 0 && (
                            <div className="flex justify-center items-center h-56">No remark have been added.</div>
                        )}

                        {!isLoading && paymentReminderNotes.length > 0 && (
                            <ul>
                                {paymentReminderNotes.map((paymentReminderNote) => (
                                    // <li className="mb-2   border-b last:border-none py-2">
                                    //     <div className="flex justify-between gap-2 items-end">
                                    //         <div className="text-base font-medium">{paymentReminderNote.message}</div>

                                    //         <div className="flex flex-col justify-between items-center">
                                    //             <div className="text-xs font-semibold text-black/60 w-40">
                                    //                 {paymentReminderNote.createdBy.name}
                                    //             </div>
                                    //             <div className="text-xs font-semibold text-black/60 w-40">
                                    //                 {moment(
                                    //                     paymentReminderNote.createdAt,
                                    //                     SYSTEM_DATE_TIME_FORMAT
                                    //                 ).format('hh:mm A - DD MMM, YYYY')}
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </li>
                                    <li className="mb-2 border-b py-2 first:pt-0 last:pb-0 last:border-none">
                                        <div className="flex flex-col justify-start">
                                            <div className="text-base font-medium">{paymentReminderNote.message}</div>

                                            <div className="flex gap-2 text-primary mt-[5px]">
                                                <div className="text-xs font-semibold">
                                                    {paymentReminderNote.createdBy.name}
                                                </div>
                                                <div className="text-xs font-semibold">
                                                    {moment(
                                                        paymentReminderNote.createdAt,
                                                        SYSTEM_DATE_TIME_FORMAT
                                                    ).format('hh:mm A - DD MMM, YYYY')}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}

                {dialogState === DialogStateEnum.VIEW_NOTE && <></>}
            </div>
        </Modal>
    );
};

export default ReceivableNote;
