import { FC } from 'react';
import moment from 'moment';
import capitalize from 'lodash.capitalize';
import useDialog from 'app/hooks/use-dialog';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import AmendmentChat from 'app/components/AmendmentChat/AmendmentChat';
import { getAmendmentStatusColor, getAmendmentStatusText } from 'app/utils/common-utils';
import AmendmentActionDialog from '../AmendmentActionDialog/AmendmentActionDialog';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import { MenuDotIcon } from 'app/icons';
import { useQueryClient } from '@tanstack/react-query';
import { AmendmentStatusEnum } from 'app/enums/amendment.status.enum';
import RejectAmendmentDialog from '../RejectAmendmentDialog/RejectAmendmentDialog';

interface IOpenAmendment {
    bookingId: string;
    amendmentId: string;
}
interface AmendmentTableProps {
    list: any[];
    refetchQueryIdentifier: any;
    bookingId: string;
}

const AmendmentTable: FC<AmendmentTableProps> = (props) => {
    const { list, refetchQueryIdentifier, bookingId } = props;
    const queryClient = useQueryClient();

    const amendmentDialog = useDialog<IOpenAmendment>({ amendmentId: '', bookingId: '' });
    const amendmentActionDialog = useDialog<string>('');
    const amendmentRejectDialog = useDialog<string>('');

    const openChatDialog = (row: any) => {
        amendmentDialog.show({
            amendmentId: row._id,
            bookingId: row.booking,
        });
    };

    const handleActionCloseDialog = () => {
        amendmentActionDialog.hide();
        queryClient.fetchQuery(refetchQueryIdentifier);
    };

    const handleRejectCloseDialog = () => {
        amendmentRejectDialog.hide();
        queryClient.fetchQuery(refetchQueryIdentifier);
    };

    return (
        <>
            <div className="overflow-auto mt-5">
                <table className="card-table text-sm">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="th-1">Request ID</th>
                            <th className="th-1">Request Date & Time</th>
                            <th className="th-1">Change Type</th>
                            <th className="th-1">Change Amount</th>
                            <th className="th-1">Status</th>
                            <th className="th-1" />
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((amendment) => {
                            return (
                                <tr key={amendment._id}>
                                    <td className="td-1">
                                        <div>
                                            <div className="font-medium text-blue-500 cursor-pointer">
                                                <div onClick={() => openChatDialog(amendment)}>{amendment._id}</div>
                                            </div>
                                            {amendment.actionNeeded && (
                                                <StatusBadge className="mt-1" color={'red'}>
                                                    Action needed
                                                </StatusBadge>
                                            )}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">
                                            <div>
                                                {moment(amendment.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}
                                            </div>
                                            <div className="text-xs font-medium text-gray-500">
                                                {moment(amendment.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}
                                            </div>
                                        </div>
                                    </td>

                                    <td className="td-1">
                                        <div className="font-semibold">{capitalize(amendment.type.toLowerCase())}</div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-medium">{amendment.amount ? amendment.amount : '-'}</div>
                                    </td>
                                    <td>
                                        <StatusBadge className="mt-1" color={getAmendmentStatusColor(amendment.status)}>
                                            {getAmendmentStatusText(amendment.status, amendment.subAgency.name)}
                                        </StatusBadge>
                                    </td>
                                    <td className="td-1">
                                        {amendment.status === AmendmentStatusEnum.REJECTED ||
                                        amendment.status === AmendmentStatusEnum.COMPLETED ? (
                                            <div className="cursor-not-allowed">
                                                <MenuDotIcon />
                                            </div>
                                        ) : (
                                            <Dropdown
                                                target={
                                                    <a className="dropdown-action">
                                                        <MenuDotIcon />
                                                    </a>
                                                }
                                                menu={
                                                    <DropdownList>
                                                        <>
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    amendmentActionDialog.show(amendment._id)
                                                                }
                                                            >
                                                                <span className="text-green-500 ">Approve</span>
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    amendmentRejectDialog.show(amendment._id)
                                                                }
                                                            >
                                                                <span className="text-red-500">Reject</span>
                                                            </DropdownItem>
                                                        </>
                                                    </DropdownList>
                                                }
                                            />
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {amendmentDialog.isOpen && (
                <AmendmentChat
                    show={amendmentDialog.isOpen}
                    onClose={amendmentDialog.hide}
                    amendmentId={amendmentDialog.data.amendmentId}
                    bookingId={amendmentDialog.data.bookingId}
                    refetchQueryIdentifier={refetchQueryIdentifier}
                />
            )}
            {amendmentActionDialog.isOpen && (
                <AmendmentActionDialog
                    amendmentId={amendmentActionDialog.data}
                    bookingId={bookingId}
                    show={amendmentActionDialog.isOpen}
                    onClose={handleActionCloseDialog}
                />
            )}

            {amendmentRejectDialog.isOpen && (
                <RejectAmendmentDialog
                    amendmentId={amendmentRejectDialog.data}
                    bookingId={bookingId}
                    show={amendmentRejectDialog.isOpen}
                    onClose={handleRejectCloseDialog}
                    refetchQueryIdentifier={refetchQueryIdentifier}
                />
            )}
        </>
    );
};







export default AmendmentTable;
