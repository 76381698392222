/* eslint-disable jsx-a11y/anchor-is-valid */
import http from 'app/config/http';
import { toast } from 'react-toastify';
import useDialog from 'app/hooks/use-dialog';
import BackLink from 'app/components/BackLink/BackLink';
import { formatCurrency } from 'app/utils/currency-utils';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { FC, useEffect, useRef, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import AmendmentTable from './components/AmendmentTable/AmendmentTable';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import FlightDetailsCard from './components/FlightDetailsCard/FlightDetailsCard';
import CancelTicketConfirmationDialog from './components/CancelTicketConfirmationDialog/CancelTicketConfirmationDialog';
import { bookingStatusEnum } from 'app/enums/booking-status.enum';
import EditBooking from 'app/modules/EditBooking/EditBooking';
import BookingHistoryCard from './components/BookingHistoryCard/BookingHistoryCard';
import TravelerDetailsCard from './components/TravelerDetailsCard/TravelerDetailsCard';
import ContactDetailsCard from './components/ContactDetailsCard/ContactDetailsCard';
import FareSupplierAndSubAgencyDetailsCard from './components/FareSupplierAndSubAgencyDetailsCard/FareSupplierAndSubAgencyDetailsCard';
import BookingFlowLogsModal from './components/BookingFlowLogsModal/BookingFlowLogsModal';
import BookingRemarksCard from './components/BookingRemarksCard/BookingRemarksCard';
import moment from 'moment';
import { SYSTEM_DATE_TIME_FORMAT } from 'app/utils/date-utils';

interface BookingDetailProps {}

const BookingDetail: FC<BookingDetailProps> = (props) => {
    const { bookingId, type } = useParams();
    const queryClient = useQueryClient();
    const [colDistancFromTop, setColDistancFromTop] = useState(0);
    const navigate = useNavigate();
    const mainColRed = useRef(null);
    const newEditBookingDialog = useDialog();

    const cancelTicketDialog = useDialog();
    const abortPendingBookingDialog = useDialog();
    const bookingFlowLogsDialog = useDialog();

    let api = '';

    if (type === 'AG') {
        api = '/api/v1/agent/bookings/';
    } else if (type === 'SAG') {
        api = '/api/v1/agent/agency/sub-agency-bookings/';
    } else {
        navigate('/404');
    }

    const query = useQuery(['bookingDetail', bookingId], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}${api}${bookingId}`);

        return data;
    });

    const amendmentQuery = useQuery(['amendment', bookingId], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/amendments`
        );

        return data;
    });

    const abortPendingBookingMutation = useMutation(async (bookingId: string) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/agent-abort`
        );
        return data;
    });

    const handleBookingAbort = async (bookingId: string) => {
        try {
            await abortPendingBookingMutation.mutateAsync(bookingId);
            // queryClient.setQueryData(['bookingDetail'], (prev: any) => {
            //     const index = prev.findIndex((p) => p._id === _id);
            //     return update(prev, {
            //         [index]: {
            //             $set: payment,
            //         },
            //     });
            // });
            queryClient.fetchQuery(['bookingDetail', bookingId]);
            abortPendingBookingDialog.hide();
            toast.success('Booking Aborted.');
        } catch (ex: any) {
            toast.error(ex.data.message);
        }
    };

    // const formTop = useMemo(() => {
    //     console.log({ dataHere: mainColRed.current });

    //     if (query.isLoading) return 0;

    //     if (mainColRed !== null) return mainColRed.current.getBoundingClientRect().top;

    //     console.log('it went wront');
    // }, [query.data]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (mainColRed.current) {
            setColDistancFromTop(() => mainColRed.current.getBoundingClientRect().top);
        }
    });

    if (query.isLoading || amendmentQuery.isLoading) return <PageLoader />;

    const booking = query.data;
    const showCancel =
        amendmentQuery.data.length > 0
            ? amendmentQuery.data[amendmentQuery.data.length - 1].type === 'CANCELLATION' &&
              amendmentQuery.data[amendmentQuery.data.length - 1].status === 'APPROVED_BY_SUB_AGENCY' &&
              booking.status !== 'CANCELLED'
                ? true
                : false
            : false;

    // const downloadMutation = useMutation(async () => {
    //     const { data } = await http.get(
    //         `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/print-ticket`,
    //         {
    //             responseType: 'blob',
    //         }
    //     );

    //     return data;
    // });

    // const handleDownloadTicket = async () => {
    //     try {
    //         const res = await downloadMutation.mutateAsync();

    //         const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
    //         const link = document.createElement('a');

    //         link.href = url;

    //         link.setAttribute('download', `eTicket-${bookingId}.pdf`);

    //         // Append to html link element page
    //         document.body.appendChild(link);

    //         // Start download
    //         link.click();

    //         // Clean up and remove the link
    //         link.parentNode.removeChild(link);
    //     } catch (ex: any) {
    //         toast.error(ex?.data?.message || 'Some error occured, please try again.');
    //         console.log(ex);
    //     }
    // };

    return (
        <>
            <div className="mb-5 flex items-center justify-between">
                <BackLink />
                <div className="">
                    Booked On :{' '}
                    <span className="font-semibold mx-1">
                        {moment(booking.createdAt, SYSTEM_DATE_TIME_FORMAT).format('DD MMM, YYYY')}
                    </span>{' '}
                    at{' '}
                    <span className="font-semibold mx-1">
                        {moment(booking.createdAt, SYSTEM_DATE_TIME_FORMAT).format('h:mm:ss a')}
                    </span>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-9" ref={mainColRed}>
                {/* Col */}
                <div className="col-span-3 lg:col-span-2">
                    {/* Card */}
                    <FlightDetailsCard booking={booking} />
                    {/* Card */}
                    <TravelerDetailsCard booking={booking} viewingOldVersion={booking.version} />

                    <ContactDetailsCard booking={booking} viewingOldVersion={booking.version} />

                    <FareSupplierAndSubAgencyDetailsCard booking={booking} />
                    {/* Card */}
                    {/* <div className="card px-6 pt-5 pb-6 mt-6">
                        <div className="font-bold">Sub Agent Details</div>

                        <div className="mt-8">
                            <Link to={`/sub-agencies/${booking.subAgency.id}/details`}>
                                <div className="text-blue-500 font-medium">{booking.subAgency.name}</div>
                            </Link>
                        </div>
                    </div> */}
                    <div className="card  py-6 mt-6">
                        <div className="font-bold mx-6">Amendments</div>
                        <AmendmentTable
                            list={amendmentQuery.data}
                            refetchQueryIdentifier={['amendment', bookingId]}
                            bookingId={bookingId}
                        />
                    </div>
                    <BookingRemarksCard bookingId={booking._id} remarks={booking.remarks} />
                </div>
                {/* Col */}
                <div className="col-span-3 lg:col-span-1">
                    <div className="sticky top-[calc(var(--navbar-height)+20px)]">
                        {booking.version ? (
                            <div
                                onClick={() => query.refetch()}
                                className="cursor-pointer card px-6 pt-5 pb-6 mb-4 flex flex-col items-center gap-1 justify-center bg-red-500"
                            >
                                <div className="text-white  text-sm font-bold">Version {booking.version}</div>
                                <div className="text-white text-sm font-bold text-center">
                                    You are viewing an old version of this booking.
                                    <br />
                                    <span className="underline">View latest</span>{' '}
                                </div>
                            </div>
                        ) : (
                            <div
                                onClick={() => query.refetch()}
                                className="card px-6 pt-5 pb-6 mb-4 flex flex-col items-center gap-1 justify-center bg-green-500"
                            >
                                <div className="text-white text-sm font-bold text-center">
                                    You are viewing the latest version of this booking.
                                </div>
                            </div>
                        )}

                        {/* Card */}
                        <div className="card px-6 pt-5 pb-6">
                            <div className="font-bold ">Ticket(s)</div>
                            {/* <a className="block text-sm text-primary cursor-pointer mt-6">Share Ticket</a> */}
                            {/* {downloadMutation.isLoading ? (
                                <div className="mt-4">
                                    <Spinner size={28} />
                                </div>
                            ) : ( */}
                            <Link
                                to={`/bookings/${bookingId}/ticket/${type}`}
                                target="_blank"
                                className="block text-sm text-primary cursor-pointer mt-4"
                            >
                                Print Ticket
                            </Link>
                            {/* <button
                                className="block text-sm text-primary cursor-pointer mt-2"
                                onClick={editBookingDialog.show}
                            >
                                Edit Ticket
                            </button> */}
                            <button
                                className="block text-sm text-primary cursor-pointer mt-2"
                                onClick={newEditBookingDialog.show}
                                disabled={booking.version}
                            >
                                Edit Booking
                            </button>
                            {/* {(showCancel || true) && (
                                <button
                                    className="block text-sm cursor-pointer mt-2 text-red-500"
                                    onClick={cancelTicketDialog.show}
                                    disabled={booking.version}
                                >
                                    Cancel Ticket
                                </button>
                            )} */}
                            {booking.status === bookingStatusEnum.PENDING && (
                                <button
                                    className="block text-sm cursor-pointer mt-2 text-red-500"
                                    onClick={() => abortPendingBookingDialog.show(booking._id)}
                                    disabled={booking.version}
                                >
                                    Abort Booking
                                </button>
                            )}

                            {/* <button
                                className="block text-sm text-primary cursor-pointer mt-2"
                                onClick={bookingFlowLogsDialog.show}
                            >
                                View Booking flow logs (Beta)
                            </button> */}
                            {/* )} */}
                            {/* {printMutation.isLoading ? (
                            <div className="mt-4">
                                <Spinner size={28} />
                            </div>
                        ) : (
                            <a className="block text-sm text-primary cursor-pointer mt-4" onClick={handlePrintTicket}>
                                Print Ticket
                            </a>
                        )} */}
                        </div>

                        <div className="card px-6 pt-5 pb-6 mt-4">
                            <div className="font-bold ">Logs</div>
                            <button
                                className="block text-sm text-primary cursor-pointer mt-2"
                                onClick={bookingFlowLogsDialog.show}
                            >
                                Booking Flow <sup className="text-black"> Beta</sup>
                            </button>
                        </div>

                        {/* Card */}
                        {/* <div className="card px-6 pt-5 pb-6 mt-4">
                            <div className="font-bold">Change Ticket</div>
                            <a className="block text-sm text-primary cursor-pointer mt-6">Request Change</a>
                            <a className="block text-sm text-danger cursor-pointer mt-4">Cancel Ticket</a>
                        </div> */}
                        {/* Card */}
                        <div className="card px-6 pt-5 pb-6 mt-4">
                            <div className="font-bold">Fare Summary </div>

                            {booking.isTaxed && (
                                <>
                                    <div className="mt-8">
                                        <div className="flex justify-between items-center text-sm">
                                            <div>Base Fare</div>
                                            <div>{formatCurrency(booking.taxDetails.totalBaseFare)}</div>
                                        </div>
                                        <div className="flex justify-between items-center text-sm mt-4">
                                            <div>Fee & Surcharges</div>
                                            <div>{formatCurrency(booking.taxDetails.totalTaxesAndFees)}</div>
                                        </div>

                                        <div className="flex justify-between items-center text-sm mt-4 font-semibold">
                                            <div>Original Fare</div>
                                            <div>{formatCurrency(booking.taxDetails.originalFare)}</div>
                                        </div>

                                        <div className="flex justify-between items-center text-sm mt-4">
                                            <div>Mgt. Fee</div>
                                            <div>{formatCurrency(booking.taxDetails.managementFees)}</div>
                                        </div>
                                        <div className="flex justify-between items-center text-sm mt-4 ">
                                            <div>Mgt. Fee GST</div>
                                            <div>{formatCurrency(booking.taxDetails.managementFeesGST)}</div>
                                        </div>
                                        <div className="flex justify-between items-center text-sm mt-4 font-semibold">
                                            <div>Mgt. Fee Total</div>
                                            <div>{formatCurrency(booking.taxDetails.managementFeesTotal)}</div>
                                        </div>
                                    </div>

                                    <div className="font-semibold flex justify-between items-center mt-8">
                                        <div>Total Amount</div>
                                        <div>{formatCurrency(booking.taxDetails.totalFare)}</div>
                                    </div>
                                </>
                            )}

                            {!booking.isTaxed && (
                                <>
                                    <div className="mt-6">
                                        <div className="flex justify-between">
                                            <div className="text-sm">Base Fare</div>
                                            <div className="text-sm">
                                                {formatCurrency(booking.priceDetail.totalBaseFare)}
                                            </div>
                                        </div>
                                        <div className="mt-4 flex justify-between">
                                            <div className="text-sm">Fees & Surcharges</div>
                                            <div className="text-sm">
                                                {/* {formatCurrency(booking.priceDetail.totalTaxesAndFees + booking.markup)} */}
                                                {formatCurrency(
                                                    booking.priceDetail.totalFare - booking.priceDetail.totalBaseFare
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-sm font-semibold flex justify-between mt-8">
                                        <div>Total Amount</div>
                                        <div>{formatCurrency(booking.priceDetail.totalFare)}</div>
                                    </div>
                                    <div className="mt-3 text-sm font-semibold flex justify-between">
                                        <div>Payment (AGY BAL)</div>
                                        <div className="text-success">
                                            -{formatCurrency(booking.priceDetail.totalFare)}
                                        </div>
                                    </div>
                                    <div className="mt-8 text-sm font-semibold flex justify-between">
                                        <div>Balance</div>
                                        <div className="text-success">₹ 0</div>
                                    </div>
                                </>
                            )}

                            {booking.priceDetail.changeFee.map((amount: number) => {
                                return (
                                    <div className="mt-8 text-sm font-semibold flex justify-between">
                                        <div>{`Change Fee - ${amount < 0 ? 'Charged' : 'Refund Issued'}`}</div>
                                        <div className="text-success">{formatCurrency(Math.abs(amount))}</div>
                                    </div>
                                );
                            })}
                        </div>

                        <BookingHistoryCard bookingId={booking._id} />
                    </div>
                </div>

                {newEditBookingDialog.isOpen && (
                    <EditBooking
                        booking={booking}
                        onClose={newEditBookingDialog.hide}
                        show={newEditBookingDialog.isOpen}
                        renderDistanceFromTop={colDistancFromTop}
                    />
                )}
                {(showCancel || true) && (
                    <CancelTicketConfirmationDialog
                        show={cancelTicketDialog.isOpen}
                        onClose={cancelTicketDialog.hide}
                        bookingId={bookingId}
                        ammendment={amendmentQuery.data[amendmentQuery.data.length - 1]}
                        // ammendmentId={amendmentQuery.data[amendmentQuery.data.length - 1]._id}
                    />
                )}
                <DeleteModal
                    show={abortPendingBookingDialog.isOpen}
                    message="Are you sure you want to abort this booking"
                    onConfirm={() => handleBookingAbort(abortPendingBookingDialog.data)}
                    onClose={abortPendingBookingDialog.hide}
                    actionLabel="Abort"
                    isLoading={abortPendingBookingMutation.isLoading}
                />

                {bookingFlowLogsDialog.isOpen && (
                    <BookingFlowLogsModal
                        show={bookingFlowLogsDialog.isOpen}
                        bookingId={bookingId}
                        onClose={bookingFlowLogsDialog.hide}
                    />
                )}
            </div>
        </>
    );
};

export default BookingDetail;
