const Icon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        className="bi bi-clock"
        viewBox="0 0 16 16"
        {...props}
    >
        <path d="M8 3.5a.5.5 0 00-1 0V9a.5.5 0 00.252.434l3.5 2a.5.5 0 00.496-.868L8 8.71z" />
        <path d="M8 16A8 8 0 108 0a8 8 0 000 16m7-8A7 7 0 111 8a7 7 0 0114 0" />
    </svg>
);

export default Icon;
