import { useQuery } from '@tanstack/react-query';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import Spinner from 'app/components/Spinner/Spinner';
import http from 'app/config/http';
import { MenuDotIcon } from 'app/icons';
import { ITourQuery } from 'app/types';
import { formatDate } from 'app/utils/date-utils';
import moment from 'moment';
import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

interface TourQueryListProps {}

const TourQueryList: FC<TourQueryListProps> = (props) => {
    const { subAgencyId } = useParams();

    const tourQuery = useQuery<ITourQuery[]>(['tour-query'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/tour-queries`
        );
        return data;
    });

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm mt-6">
                <thead>
                    <tr className="bg-gray-50">
                        <th className="th-1">Arrival Date</th>
                        <th className="th-1">Departure Date</th>
                        <th className="th-1">Adults</th>
                        <th className="th-1">Children</th>
                        <th className="th-1">Sub Agency</th>
                        <th className="th-1">Request on</th>
                        <th className="th-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {!tourQuery.isLoading && tourQuery.data.length === 0 && (
                        <tr>
                            <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                - No data available -
                            </td>
                        </tr>
                    )}

                    {!tourQuery.isLoading &&
                        tourQuery.data.map((row, i) => (
                            <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                <td className="td-1">
                                    <div className="font-semibold">{formatDate(row.arrivalDate)}</div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">{formatDate(row.departureDate)}</div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">{row.adults}</div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">{row.children}</div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">
                                        <div className="font-semibold text-blue-500">
                                            <Link to={`/sub-agencies/${row.subAgency._id}/details?tab=1`}>
                                                {row.subAgency.name}
                                            </Link>
                                        </div>
                                    </div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                    </div>
                                </td>

                                {/* <td className="td-1">
                                                <div className="font-semibold">{row.subAgencyDetails.name}</div>
                                                <p className="mt-1">{row.subAgencyDetails.address}</p>
                                            </td>

                                            <td className="td-1">
                                                <div className="font-semibold">{row.subAgencyDetails.phoneNumber}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{row.subAgencyDetails.email}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{row.personalDetails.name}</div>
                                                <p className="text-xs">{row.personalDetails.designation}</p>
                                                <p className="text-xs mt-1">{row.personalDetails.phoneNumber}</p>
                                            </td>
                                            <td className="td-1">
                                                <div className="min-w-max">
                                                    <p>
                                                        {moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}
                                                    </p>
                                                    <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                                </div>
                                            </td> */}

                                <td className="td-1">
                                    <Dropdown
                                        target={
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a className="dropdown-action">
                                                <MenuDotIcon />
                                            </a>
                                        }
                                        menu={
                                            <DropdownList>
                                                <DropdownItem to={`/tour-query/${row.subAgency._id}/${row._id}`}>
                                                    View
                                                </DropdownItem>
                                            </DropdownList>
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {tourQuery.isLoading && (
                <div className="flex justify-center items-center min-h-[500px]">
                    <Spinner />
                </div>
            )}
        </div>
    );
};

export default TourQueryList;
