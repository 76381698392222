import http from 'app/config/http';
import { IAgency } from 'app/types';
import { IAgencySetting } from 'app/types/agency-setting';

export const getAgencyDetails = async () => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency`);
    return data as IAgency;
};

export const getAgencySettings = async () => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency-settings`);
    return data as IAgencySetting;
};

export const updateAgencySettings = async (values: Omit<IAgencySetting, 'agency' | '_id'>) => {
    const { data } = await http.patch(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency-settings`, values);
    return data;
};
