import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import { DeleteIcon } from 'app/icons';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import FileUploadForm from './components/FileUploadForm/FileUploadForm';
import update from 'immutability-helper';

interface LoginImageListProps {}

const LoginImageList: FC<LoginImageListProps> = (props) => {
    const deleteDialog = useDialog<string>();

    const queryClient = useQueryClient();

    const query = useQuery<any[]>(['loginImageList'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/login-images`);

        return data;
    });

    const deleteMutation = useMutation(async (values: any) => {
        await http.delete(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/login-images/${values._id}`);
    });

    if (query.isLoading) return <PageLoader />;

    const loginImages = query.data;

    const handleDeleteImage = async () => {
        try {
            const _id = deleteDialog.data;

            await deleteMutation.mutateAsync({ _id });

            const index = loginImages.findIndex((r) => r._id === _id);

            queryClient.setQueryData(['loginImageList'], (prev: any) => {
                return update(prev, {
                    $splice: [[index, 1]],
                });
            });
        } catch (err) {
            toast.error('Some error occured while deleting the image, please try again');
        } finally {
            deleteDialog.hide();
        }
    };

    return (
        <div>
            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Login Images</div>

                {loginImages.length > 0 && (
                    <div className="grid grid-cols-2 gap-4 mt-6">
                        {loginImages.map((row, i) => (
                            <div
                                key={row._id}
                                className={i === loginImages.length - 1 && i % 2 === 0 ? 'col-span-2' : 'col-span-1'}
                            >
                                <div className="relative h-full">
                                    <button
                                        className="absolute right-3 top-3 bg-white rounded-md w-8 h-8 flex items-center justify-center text-gray-500 drop-shadow"
                                        onClick={() => deleteDialog.show(row._id)}
                                    >
                                        <DeleteIcon height={18} />
                                    </button>
                                    <img
                                        className="rounded-lg object-cover object-center w-full h-full"
                                        src={row.url}
                                        alt=""
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {loginImages.length < 10 && (
                    <div className="mt-6">
                        <FileUploadForm />
                    </div>
                )}

                <DeleteModal
                    show={deleteDialog.isOpen}
                    message={'Are you sure you want to delete this image?'}
                    onClose={deleteDialog.hide}
                    onConfirm={handleDeleteImage}
                    isLoading={deleteMutation.isLoading}
                />
            </div>
        </div>
    );
};

export default LoginImageList;
