import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import Spinner from 'app/components/Spinner/Spinner';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import { MenuDotIcon } from 'app/icons';
import { formatCurrency } from 'app/utils/currency-utils';
import moment from 'moment';
import { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SettlePaymentDialog from '../SettlePaymentDialog/SettlePaymentDialog';

interface UnsettledPaymentsTableProps {
    UnsettledPaymentList: any[];
    isLoading: boolean;
    refetchQueryIdentifier: any;
}

const UnsettledPaymentsTable: FC<UnsettledPaymentsTableProps> = (props) => {
    const navigate = useNavigate();
    const settlePaymentDialog = useDialog<any>();
    const { UnsettledPaymentList, isLoading, refetchQueryIdentifier } = props;
    const tableTop = useRef();

    const queryClient = useQueryClient();
    const settlePaymentMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${values.booking._id}/amendments/${values._id}/settle`,
            {
                accountSettled: true,
            }
        );
        return data;
    });

    const generateSimpleLabel = (value: string) => {
        if (value === 'CANCELLATION') return 'Cancellation';

        return value;
    };

    const handleSettlePayment = async () => {
        try {
            await settlePaymentMutation.mutateAsync(settlePaymentDialog.data);
            queryClient.fetchQuery(refetchQueryIdentifier);
            toast.success('Amendment payment settled successfully.');
            settlePaymentDialog.hide();
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <>
            <div className="overflow-auto mt-5">
                <table className="card-table text-sm">
                    <thead>
                        <tr className="bg-gray-50" ref={tableTop}>
                            <th className="th-1">Request ID</th>
                            <th className="th-1">Booking Id</th>
                            <th className="th-1">Type</th>
                            <th className="th-1">Amount</th>
                            <th className="th-1">Sub Agency</th>
                            <th className="th-1">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && UnsettledPaymentList.length === 0 && (
                            <tr>
                                <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                    - No data available -
                                </td>
                            </tr>
                        )}

                        {!isLoading &&
                            UnsettledPaymentList.map((unsettledPayment) => {
                                return (
                                    <tr key={unsettledPayment._id}>
                                        <td className="td-1">
                                            <div className="font-semibold text-blue-500 cursor-pointer">
                                                <div onClick={() => settlePaymentDialog.show(unsettledPayment)}>
                                                    {unsettledPayment._id}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold text-blue-500 cursor-pointer">
                                                <div
                                                    onClick={() =>
                                                        navigate(
                                                            `/bookings/${unsettledPayment.booking._id}/${
                                                                unsettledPayment.booking.fareSupplier === '2'
                                                                    ? 'SAG'
                                                                    : 'AG'
                                                            }`
                                                        )
                                                    }
                                                >
                                                    {unsettledPayment.booking._id}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">
                                                {generateSimpleLabel(unsettledPayment.type)}
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">
                                                {formatCurrency(unsettledPayment.amount)}
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div
                                                className="font-semibold text-blue-500 cursor-pointer"
                                                onClick={() =>
                                                    navigate(`/sub-agencies/${unsettledPayment.subAgency._id}/details`)
                                                }
                                            >
                                                {unsettledPayment.subAgency.name}
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">
                                                <p>
                                                    {moment(unsettledPayment.createdAt, 'YYYYMMDDHHmmss').format(
                                                        'DD MMM, YYYY'
                                                    )}
                                                </p>
                                                <p>
                                                    {moment(unsettledPayment.createdAt, 'YYYYMMDDHHmmss').format(
                                                        'hh:mm A'
                                                    )}
                                                </p>
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <Dropdown
                                                target={
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a className="dropdown-action">
                                                        <MenuDotIcon />
                                                    </a>
                                                }
                                                menu={
                                                    <DropdownList>
                                                        {/* <DropdownItem to={`/users/${row._id}/edit`}>Edit</DropdownItem> */}
                                                        <DropdownItem
                                                            onClick={() => settlePaymentDialog.show(unsettledPayment)}
                                                        >
                                                            <span className="text-red-500">Settle Payment</span>
                                                        </DropdownItem>
                                                    </DropdownList>
                                                }
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                {isLoading && (
                    <div className="flex justify-center items-center min-h-[500px]">
                        <Spinner />
                    </div>
                )}
            </div>
            <SettlePaymentDialog
                show={settlePaymentDialog.isOpen}
                onClose={settlePaymentDialog.hide}
                onConfirm={handleSettlePayment}
                isLoading={settlePaymentMutation.isLoading}
            />
        </>
    );
};

export default UnsettledPaymentsTable;
