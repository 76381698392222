import moment from 'moment';
import { ISegmentInitial } from '../types/IManualBooking';
import { PassengerTypeEnum } from 'app/enums/passenger-type.enum';
import { PASSENGER_TITLES } from 'app/shared/constants/passenger-titles';
import { IPassengerWithTicketNumber, ISegment } from 'app/types/manual-booking';

export const createBlankSegment = (): ISegmentInitial => {
    return {
        flight: null,
        flightNumber: '',
        arrival: {
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
            airport: null,
        },
        departure: {
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
            airport: null,
        },
        stopsOverAirport: [],
    };
};

const generateTitleFromPassengerType = (passengerType: PassengerTypeEnum): string => {
    if (passengerType === PassengerTypeEnum.ADULT) return PASSENGER_TITLES.adults[0];
    if (passengerType === PassengerTypeEnum.CHILD) return PASSENGER_TITLES.children[0];
    if (passengerType === PassengerTypeEnum.INFANT) return PASSENGER_TITLES.infants[0];

    return '';
};

export const createPassengerTemplate = (
    passengerType: PassengerTypeEnum,
    segments: Array<ISegment>,
    isInternational: boolean
): IPassengerWithTicketNumber => {
    return {
        title: generateTitleFromPassengerType(passengerType),
        firstName: '',
        lastName: '',
        dob: '',
        passport: {
            expiry: '',
            issueDate: '',
            number: '',
            nationality: 'IN',
        },
        savePassengerDetails: false,
        ticketNumbers: segments.map((segment) => ({
            number: '',
            sector: `${segment.departure.airport.code}-${segment.arrival.airport.code}`,
        })),
        isInternational: isInternational,
    };
};
