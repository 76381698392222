import http from 'app/config/http';
import { IBank } from 'app/types';

export const getAgencyBankAccounts = async () => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/banks`);
    return data as IBank[];
};

//     const agencyBankAccountQuery = useQuery<IBank[]>(['Agency Bank Account List'], async () => {
//         const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/banks`);
//         return data;
//     });

// import { ICommissionPlan } from 'app/enums/commission-plan';
// import { commissionPlanBondingEnum } from 'app/enums/commission-plan-bonding-enum';
// import { ICommissionPlanSubAgency } from 'app/enums/commission-plan-sub-agency.enum';
// import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';

// import { IFareComponents } from 'app/pages/FareSupplier/FareSupplierTripJack/types/fare-components';

// export const getFareComponents = async (identifier: FareSupplierEnum) => {
//     const { data } = await http.get(
//         `${process.env.REACT_APP_API_URL}/api/v1/agent/third-party-faresuppliers/${identifier}/fare-components`
//     );
//     return data as IFareComponents;
// };

// export const getCommissionPlans = async () => {
//     const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/commission-plans`);

//     return data.data.commissionPlans;
// };

// export const createCommissionPlan = async <T>(payload: ICommissionPlan<T>) => {
//     const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/commission-plans`, payload);
//     console.log({ data });
//     return data.commissionPlans;
// };

// export const getSubAgenciesWithAttachedCommissionPlans = async () => {
//     const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/commission-plans/sub-agencies`);
//     return data.data.subAgencies as ICommissionPlanSubAgency[];
// };

// export const toggleCommissionPlanBonding = async <T>(payload: {
//     commissionPlanId: string;
//     SubAgencyId: string;
//     operation: commissionPlanBondingEnum;
// }) => {
//     const { data } = await http.patch(
//         `${process.env.REACT_APP_API_URL}/api/v1/agent/commission-plans/${payload.commissionPlanId}/sub-agencies/${payload.SubAgencyId}`,
//         {
//             operation: payload.operation,
//         }
//     );
//     return data.data as {
//         commissionPlan: ICommissionPlan<T>;
//         subAgency: ICommissionPlanSubAgency;
//     };
// };

// export const getCommissionPlanById = async (commissionPlanId: string) => {
//     const { data } = await http.get(
//         `${process.env.REACT_APP_API_URL}/api/v1/agent/commission-plans/${commissionPlanId}`
//     );
//     return data.commissionPlan;
// };

// export const updateCommissionPlan = async (payload: { id: string; values: any }) => {
//     const { data } = await http.put(
//         `${process.env.REACT_APP_API_URL}/api/v1/agent/commission-plans/${payload.id}`,
//         payload.values
//     );

//     console.log({ testData: data });
//     return;
// };
