import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { Status } from 'app/enums/status.enum';
import { IMarkUpHistory } from '../shared/types/mark-up-history';
import FareBoutiqueCredentialsForm from '../shared/components/FareBoutiqueCredentialForm/FareBoutiqueCredentialsForm';
import { IFareBoutiqueDetails } from '../shared/types/fare-boutique';

import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';
import FareSupplierDefaultCommissionPlan from '../shared/components/FareSupplierDefaultCommissionPlan/FareSupplierDefaultCommissionPlan';
import FareRuleForm from 'app/components/FareRuleForm/FareRuleForm';
import FareIdentifierMappingForm from '../shared/components/FareIdentifierMappingForm/FareIdentifierMappingForm';
import FareSupplierActivationToggle from '../shared/components/FareSupplierActivationToggle/FareSupplierActivationToggle';
import ContactDetailsMasking from '../shared/components/ContactDetailsMasking/ContactDetailsMasking';

const FareSupplierFareBoutique = () => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
        fareSupplier,
        fareSupplierLabel,
        fareSupplierUrlKey,
        isUpdatingFareSupplierStatus,
    } = useFareSupplierStatus<IFareBoutiqueDetails>({
        fareIdentifier: FareSupplierEnum.FARE_BOUTIQUE,
    });

    if (isLoading) return <PageLoader />;

    const data: IFareBoutiqueDetails = isError
        ? {
              partnerUserId: '',
              token: '',
              markup: 0,
              fareRules: [] as string[],
              markupHistory: [] as IMarkUpHistory[],
              status: Status.DISABLED,
              baseUrl: '',
              maskContactDetails: {
                  email: '',
                  isEnabled: false,
                  mobileNumber: '',
              },
          }
        : queryData;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">Fare Boutique Details</div>
                    <FareSupplierActivationToggle
                        checked={data.status}
                        handleToggleStatus={handleToggleStatus}
                        fareSupplierLabel={fareSupplierLabel}
                        isLoading={isUpdatingFareSupplierStatus}
                    />
                </div>

                <FareBoutiqueCredentialsForm
                    baseUrl={data.baseUrl}
                    partnerUserId={data.partnerUserId}
                    token={data.token}
                />
            </div>

            <FareSupplierDefaultCommissionPlan fareSupplier={fareSupplier} fareSupplierId={fareSupplierUrlKey} />

            <FareIdentifierMappingForm fareSupplier={fareSupplierUrlKey} />

            <ContactDetailsMasking
                fareSupplier={fareSupplierUrlKey}
                maskContactDetails={data.maskContactDetails}
                refetchQueryIdentifier={queryIdentifier}
            />

            <FareSupplierAirlineFilter fareSupplier={fareSupplierUrlKey} />

            {!isError && (
                <FareRuleForm
                    fareRules={data.fareRules}
                    fareSupplier={'fare-boutique'}
                    refetchQueryIdentifier={queryIdentifier}
                />
            )}
        </>
    );
};

export default FareSupplierFareBoutique;
