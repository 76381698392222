import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import Modal from 'app/components/Modal/Modal';
import http from 'app/config/http';
import React, { FC } from 'react';
import { toast } from 'react-toastify';

interface IRejectAmendmentDialog {
    show: boolean;
    onClose: () => void;
    bookingId: string;
    amendmentId: string;
    refetchQueryIdentifier: Array<any>;
}

const RejectAmendmentDialog: FC<IRejectAmendmentDialog> = (props) => {
    const { refetchQueryIdentifier } = props;

    const clientQuery = useQueryClient();
    const rejectAmendmentMutation = useMutation(async () => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${props.bookingId}/amendments/${props.amendmentId}/reject`
        );
        return data;
    });

    const handleReject = async () => {
        try {
            const { data } = await rejectAmendmentMutation.mutateAsync();
            toast.success('Amendment rejected.');
            clientQuery.fetchQuery(refetchQueryIdentifier);
            props.onClose();
        } catch (e: any) {
            toast.error(e?.data?.message || 'Something went wrong');
        }
    };

    return (
        <Modal className="px-10 py-10 w-[520px] " show={props.show} onClose={props.onClose}>
            <div className="text-gray-900 mt-4 text-center">Are you sure you want to reject this amendment</div>
            <div className="flex justify-center gap-4 mt-6">
                <LoaderButton
                    className="bg-red-500 text-white px-6 text-sm rounded-lg"
                    // onClick={handleConfirm}
                    onClick={handleReject}
                    isLoading={rejectAmendmentMutation.isLoading}
                >
                    Yes, Reject
                </LoaderButton>
                <button className="bg-gray-200 text-gray-600 px-6 text-sm rounded-lg" onClick={props.onClose}>
                    No, keep it
                </button>
            </div>
        </Modal>
    );
};

export default RejectAmendmentDialog;
