import { FC } from 'react';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';

import MarkupControlForm from 'app/components/MarkupControlForm/MarkupControlForm';
import TripJackCredentialForm from '../shared/components/TripJackCredentialForm/TripJackCredentialForm';
import { Status } from 'app/enums/status.enum';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { ITripJackDetails } from '../shared/types/trip-jack';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import { IMarkUpHistory } from '../shared/types/mark-up-history';
import { mapFareSupplierStatusToBoolean } from '../shared/utils';
import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';
import { FareSupplierIdEnum } from 'app/enums/FareSupplierIdEnum.enum';
import FareSupplierDefaultCommissionPlan from '../shared/components/FareSupplierDefaultCommissionPlan/FareSupplierDefaultCommissionPlan';
import FareRuleForm from 'app/components/FareRuleForm/FareRuleForm';

interface FareSupplierTripJackProps {}

const FareSupplierTripJack: FC<FareSupplierTripJackProps> = (props) => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
    } = useFareSupplierStatus<ITripJackDetails>({
        fareIdentifier: FareSupplierEnum.TRIP_JACK,
    });

    if (isLoading) return <PageLoader />;

    const data: ITripJackDetails = isError
        ? {
              apiKey: '',
              markup: 0,
              markupHistory: [] as IMarkUpHistory[],
              baseUrl: '',
              status: Status.DISABLED,
              fareRules: [],
          }
        : queryData;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">TripJack Details</div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-switch success"
                            checked={mapFareSupplierStatusToBoolean(data.status)}
                            onChange={(e) => handleToggleStatus(e.target.checked)}
                        />
                    </div>
                </div>

                <TripJackCredentialForm apiKey={data.apiKey} baseUrl={data.baseUrl} />
            </div>
            <FareSupplierDefaultCommissionPlan
                fareSupplier={FareSupplierEnum.TRIP_JACK}
                fareSupplierId={FareSupplierIdEnum.TRIP_JACK}
            />

            <FareSupplierAirlineFilter fareSupplier={FareSupplierIdEnum.TRIP_JACK} />

            {!isError && (
                <FareRuleForm
                    fareRules={data.fareRules}
                    fareSupplier={'trip-jack'}
                    refetchQueryIdentifier={queryIdentifier}
                />
            )}

            {!isError && (
                <MarkupControlForm
                    currentMarkup={data.markup}
                    fareSupplier={'trip-jack'} // usage::  /api/v1/agent/fare-suppliers/${fareSupplier}/markup
                    refetchQueryIdentifier={queryIdentifier}
                    markupHistory={data.markupHistory}
                />
            )}
        </>
    );
};

export default FareSupplierTripJack;
