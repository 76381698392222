import { FC, useMemo } from 'react';
import countryList from 'country-list';
import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';
import { PassengerTypeEnum } from 'app/enums/passenger-type.enum';
import { PASSENGER_TITLES } from 'app/shared/constants/passenger-titles';
import { getPassengerTypeKey, getPassengerTypeLabel } from 'app/utils/ticket-utils';
import { DeleteIcon } from 'app/icons';
// import { TPaxAndContactDetails } from '../../EditPassengerAndContactDetailsDialog';
import { toast } from 'react-toastify';
import { TPassengerDetails } from '../../EditPassengerDetailsDialog';

interface IPassengerFormItem {
    passengerIndex: number;
    passengerType: PassengerTypeEnum;
    segmentCodes: Array<string>;
    isInternational: boolean;
    remove: (index: number) => void;
}

const PassengerFormItem: FC<IPassengerFormItem> = (props) => {
    const { passengerIndex, passengerType, isInternational, segmentCodes, remove } = props;
    const paxType = getPassengerTypeKey(passengerType);
    const paxLabel = getPassengerTypeLabel(passengerType);
    const nations = useMemo(() => countryList.getNameList(), []);

    const { values } = useFormikContext<TPassengerDetails>();

    const handleRemovePax = (index: number) => {
        if (passengerType === PassengerTypeEnum.ADULT) {
            if (values.passengers.adults.length === 1) {
                toast.warning(
                    'This passenger cannot be removed, as a booking must include at least one adult passenger.'
                );
                return;
            }
        }

        remove(index);
    };

    return (
        <div>
            <div className="flex justify-between font-semibold mt-4 items-center">
                <div>{`${paxLabel} ${passengerIndex + 1}`}</div>
                <div className="cursor-pointer text-red-500" onClick={() => handleRemovePax(passengerIndex)}>
                    <DeleteIcon height={20} width={20} />
                </div>
            </div>

            <div className="mt-2  grid grid-cols-1 gap-2 sm:grid-cols-12 sm:gap-4">
                <div className="sm:col-span-2">
                    <label className="text-xs mb-2" htmlFor={`passengers.${paxType}[${passengerIndex}].title`}>
                        TITLE
                    </label>
                    <Field
                        as="select"
                        name={`passengers.${paxType}[${passengerIndex}].title`}
                        type="text"
                        id={`passengers.${paxType}[${passengerIndex}].title`}
                        className="form-control"
                    >
                        {PASSENGER_TITLES[paxType].map((title) => (
                            <option key={title} value={title}>
                                {title}
                            </option>
                        ))}
                    </Field>
                    <div className="form-error">
                        <ErrorMessage name={`passengers.${paxType}[${passengerIndex}].title`} />
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label className="text-xs mb-2" htmlFor={`passengers.${paxType}[${passengerIndex}].firstName`}>
                        FIRST NAME
                    </label>
                    <Field
                        type="text"
                        id={`passengers.${paxType}[${passengerIndex}].firstName`}
                        name={`passengers.${paxType}[${passengerIndex}].firstName`}
                        className="form-control"
                    />
                    <div className="form-error">
                        <ErrorMessage name={`passengers.${paxType}[${passengerIndex}].firstName`} />
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label className="text-xs mb-2" htmlFor={`passengers.${paxType}[${passengerIndex}].lastName`}>
                        LAST NAME
                    </label>
                    <Field
                        type="text"
                        id={`passengers.${paxType}[${passengerIndex}].lastName`}
                        name={`passengers.${paxType}[${passengerIndex}].lastName`}
                        className="form-control"
                    />
                    <div className="form-error">
                        <ErrorMessage name={`passengers.${paxType}[${passengerIndex}].lastName`} />
                    </div>
                </div>
                <div className="sm:col-span-4">
                    <label className="text-xs mb-2  " htmlFor={`passengers.${paxType}[${passengerIndex}].dob`}>
                        D.O.B
                    </label>
                    <Field
                        type="date"
                        id={`passengers.${paxType}[${passengerIndex}].dob`}
                        name={`passengers.${paxType}[${passengerIndex}].dob`}
                        className="form-control"
                    />
                    <div className="form-error">
                        <ErrorMessage name={`passengers.${paxType}[${passengerIndex}].dob`} />
                    </div>
                </div>
                {/* /////////////////////////////////////////////// */}
                {isInternational && (
                    <>
                        {/* <div className='mt-4 text-xs'>Passport Details</div> */}
                        {/* nationality */}
                        <div className=" sm:col-span-3">
                            <label
                                htmlFor={`passengers.${paxType}[${passengerIndex}].passport.nationality`}
                                className="text-xs text-slate-500"
                            >
                                Nationality
                            </label>

                            <Field
                                as="select"
                                name={`passengers.${paxType}[${passengerIndex}].passport.nationality`}
                                placeholder="Mr"
                                className="form-control appearance-none"
                            >
                                {Object.keys(nations).map((val) => (
                                    <option key={val} value={nations[val]}>
                                        {val.charAt(0).toUpperCase() + val.slice(1)}
                                    </option>
                                ))}
                            </Field>
                            <div className="form-error">
                                <ErrorMessage name={`passengers.${paxType}[${passengerIndex}].passport.nationality`} />
                            </div>
                        </div>
                        {/* number */}
                        <div className=" sm:col-span-3">
                            <label
                                htmlFor={`passengers.${paxType}[${passengerIndex}].passport.number`}
                                className="text-xs text-slate-500"
                            >
                                Passport Number
                            </label>
                            <Field
                                type="text"
                                name={`passengers.${paxType}[${passengerIndex}].passport.number`}
                                placeholder="Passport Number"
                                className="form-control"
                            />
                            <div className="form-error">
                                <ErrorMessage name={`passengers.${paxType}[${passengerIndex}].passport.number`} />
                            </div>
                        </div>
                        {/* issueDate */}
                        <div className=" sm:col-span-3">
                            <label
                                htmlFor={`passengers.${paxType}[${passengerIndex}].passport.issueDate`}
                                className="text-xs text-slate-500"
                            >
                                Passport Issue Date
                            </label>

                            <Field
                                type="date"
                                id={`passengers.${paxType}[${passengerIndex}].passport.issueDate`}
                                name={`passengers.${paxType}[${passengerIndex}].passport.issueDate`}
                                className="form-control"
                            />

                            <div className="form-error">
                                <ErrorMessage name={`passengers.${paxType}[${passengerIndex}].passport.issueDate`} />
                            </div>
                        </div>
                        {/* expiryData */}
                        <div className=" sm:col-span-3">
                            <label
                                htmlFor={`passengers.${paxType}[${passengerIndex}].passport.expiry`}
                                className="text-xs text-slate-500"
                            >
                                Passport Expiry Date
                            </label>
                            <Field
                                type="date"
                                id={`passengers.${paxType}[${passengerIndex}].passport.expiry`}
                                name={`passengers.${paxType}[${passengerIndex}].passport.expiry`}
                                className="form-control"
                            />

                            <div className="form-error">
                                <ErrorMessage name={`passengers.${paxType}[${passengerIndex}].passport.expiry`} />
                            </div>
                        </div>
                    </>
                )}
                <div className="sm:col-span-4">
                    <FieldArray name={`passengers.${paxType}[${passengerIndex}].pnrs`}>
                        {({ push, remove }) => (
                            <>
                                <label
                                    className="text-xs mb-2"
                                    htmlFor={`passengers.${paxType}[${passengerIndex}].pnrs`}
                                >
                                    PNR
                                </label>
                                <div className="flex flex-col gap-3">
                                    {segmentCodes.map((segment, segmentIndex) => (
                                        <div key={segmentIndex}>
                                            <div className="input-group group relative">
                                                <Field
                                                    type="text"
                                                    name={`passengers.${paxType}[${passengerIndex}].pnrs[${segmentIndex}].number`}
                                                    id={`passengers.${paxType}[${passengerIndex}].pnrs[${segmentIndex}].number`}
                                                    className="form-control pl-36 focus:outline-none" // Ensure this has no outline so that you see the border effect
                                                />
                                                <div className="absolute top-0 left-0 w-32 h-full">
                                                    <Field
                                                        type="text"
                                                        disabled
                                                        name={`passengers.${paxType}[${passengerIndex}].pnrs[${segmentIndex}].sector`}
                                                        id={`passengers.${paxType}[${passengerIndex}].pnrs[${segmentIndex}].sector`}
                                                        className="form-control rounded-r-none bg-primary/50 text-white px-5 border-gray-200 group-focus-within:border-[#219653]"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-error ">
                                                <ErrorMessage
                                                    name={`${paxType}[${passengerIndex}].pnrs[${segmentIndex}].number`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </FieldArray>
                </div>
                <div className="sm:col-span-4">
                    <FieldArray name={`passengers.${paxType}[${passengerIndex}].ticketNumbers`}>
                        {({ push, remove }) => (
                            <>
                                <label
                                    className="text-xs mb-2"
                                    htmlFor={`passengers.${paxType}[${passengerIndex}].ticketNumbers`}
                                >
                                    TICKET NUMBER
                                </label>
                                <div className="flex flex-col gap-3">
                                    {segmentCodes.map((segment, segmentIndex) => (
                                        <div key={segmentIndex}>
                                            <div className="input-group group relative">
                                                <Field
                                                    type="text"
                                                    name={`passengers.${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].number`}
                                                    id={`passengers.${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].number`}
                                                    className="form-control pl-36 focus:outline-none" // Ensure this has no outline so that you see the border effect
                                                />
                                                <div className="absolute top-0 left-0 w-32 h-full">
                                                    <Field
                                                        type="text"
                                                        disabled
                                                        name={`passengers.${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].sector`}
                                                        id={`passengers.${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].sector`}
                                                        className="form-control rounded-r-none bg-primary/50 text-white px-5 border-gray-200 group-focus-within:border-[#219653]"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-error ">
                                                <ErrorMessage
                                                    name={`${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].number`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </FieldArray>
                </div>

                <div className="sm:col-span-4">
                    <FieldArray name={`passengers.${paxType}[${passengerIndex}].seatNumbers`}>
                        {({ push, remove }) => (
                            <>
                                <label
                                    className="text-xs mb-2"
                                    htmlFor={`passengers.${paxType}[${passengerIndex}].seatNumbers`}
                                >
                                    SEAT NUMBER
                                </label>
                                <div className="flex flex-col gap-3">
                                    {segmentCodes.map((segment, segmentIndex) => (
                                        <div key={segmentIndex}>
                                            <div className="input-group group relative">
                                                <Field
                                                    type="text"
                                                    name={`passengers.${paxType}[${passengerIndex}].seatNumbers[${segmentIndex}].number`}
                                                    id={`passengers.${paxType}[${passengerIndex}].seatNumbers[${segmentIndex}].number`}
                                                    className="form-control pl-36 focus:outline-none" // Ensure this has no outline so that you see the border effect
                                                />
                                                <div className="absolute top-0 left-0 w-32 h-full">
                                                    <Field
                                                        type="text"
                                                        disabled
                                                        name={`passengers.${paxType}[${passengerIndex}].seatNumbers[${segmentIndex}].sector`}
                                                        id={`passengers.${paxType}[${passengerIndex}].seatNumbers[${segmentIndex}].sector`}
                                                        className="form-control rounded-r-none bg-primary/50 text-white px-5 border-gray-200 group-focus-within:border-[#219653]"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-error ">
                                                <ErrorMessage
                                                    name={`${paxType}[${passengerIndex}].seatNumbers[${segmentIndex}].number`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </FieldArray>
                </div>
            </div>
        </div>
    );
};

export default PassengerFormItem;
