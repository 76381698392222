import { AmendmentStatusEnum } from 'app/enums/amendment.status.enum';
import moment from 'moment';

export const getGenderFromTitle = (title: string) => {
    if (['Ms', 'Mrs'].includes(title)) return 'Female';
    else return 'Male';
};

export const getInitials = (name: string) => {
    const arr = name.split(' ');

    let initials = arr[0].substring(0, 1);

    if (arr.length > 1) {
        initials += arr[arr.length - 1].substring(0, 1);
    }

    return initials.toUpperCase();
};

export const formatDOB = (dob: string, format: string) => {
    return moment(dob, format).format('DD MMM YYYY');
};

export const getAmendmentStatusColor = (status: AmendmentStatusEnum) => {
    switch (status) {
        case AmendmentStatusEnum.PENDING:
            return 'yellow';
        case AmendmentStatusEnum.COMPLETED:
            return 'green';
        case AmendmentStatusEnum.AWAITING_AGENCY_APPROVAL:
            return 'yellow';
        case AmendmentStatusEnum.APPROVED_BY_SUB_AGENCY:
            return 'yellow';
        case AmendmentStatusEnum.REJECTED_BY_SUB_AGENCY:
            return 'yellow';
        case AmendmentStatusEnum.AWAITING_SUB_AGENCY_APPROVAL:
            return 'yellow';
        case AmendmentStatusEnum.REJECTED:
            return 'red';
        default:
            assertUnreachable(status);
            return '';
    }
};

export const getAmendmentStatusText = (status: AmendmentStatusEnum, subAgency: string = '') => {
    switch (status) {
        case AmendmentStatusEnum.PENDING:
            return 'Pending';
        case AmendmentStatusEnum.COMPLETED:
            return 'Completed';
        case AmendmentStatusEnum.AWAITING_AGENCY_APPROVAL:
            return 'Awaiting agency approval';
        case AmendmentStatusEnum.APPROVED_BY_SUB_AGENCY:
            return `Approved by ${subAgency ? subAgency : 'sub agency'}`;
        case AmendmentStatusEnum.AWAITING_SUB_AGENCY_APPROVAL:
            return `Awaiting ${subAgency ? subAgency : 'sub agency'} approval`;
        case AmendmentStatusEnum.REJECTED_BY_SUB_AGENCY:
            return `Rejected by ${subAgency ? subAgency : 'sub agency'}`;
        case AmendmentStatusEnum.REJECTED:
            return 'Rejected';
        default:
            assertUnreachable(status);
            return '';
    }
};

export const capFirst = (str: string) => {
    let tmp = str.toLowerCase();
    return tmp[0].toUpperCase() + tmp.slice(1);
};

export const snakeCaseToWord = (stringInSnakeCase: string): string => {
    let inWords = stringInSnakeCase
        .toLowerCase()
        .split('_')
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(' ');
    return inWords;
};

export const secureString = ({ text, showCharacters }: { text: string; showCharacters: number }) => {
    return '*'.repeat(text.length - showCharacters) + text.slice(-showCharacters);
};

export const valueArrayFromEnum = (enumObject: { [key: string]: string | number }): (string | number)[] => {
    return Object.values(enumObject);
};

export const assertUnreachable = (x: never): never => {
    throw new Error(`Unhandled case: ${x}`);
};
