/* eslint-disable jsx-a11y/anchor-is-valid */
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { FC } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import { IAgency } from 'app/types';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { IAgencyForm } from '../../types';
import update from 'immutability-helper';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'app/store/auth';
import { TRootState } from 'app/store';

const AgencyFormSchema = Yup.object().shape({
    name: Yup.string().trim().required('This field is required'),
    email: Yup.string().trim().required('This field is required').email('Please enter a valid email address'),
    phoneNumber: Yup.string()
        .trim()
        .required('This field is required')
        .matches(/^\d{10}$/, 'Please enter a valid mobile number'),
    address: Yup.object().shape({
        line: Yup.string().trim().required('This field is required'),
        state: Yup.string().trim().required('This field is required'),
        city: Yup.string().trim().required('This field is required'),
        country: Yup.string().trim().required('This field is required'),
    }),
    primaryColor: Yup.string().trim().required('This field is required'),
    secondaryColor: Yup.string().trim().required('This field is required'),
    tagline: Yup.string().trim().max(26, 'Tagline cannot exceed 26 characters'),
    fareDisplayCount: Yup.number()
        .required('This field is required')
        .typeError('You must specify a number')
        .min(1, 'Value must be greater than zero')
        .max(10, 'Maximum value can be 10'),
    subAgencyCommission: Yup.number()
        .required('This field is required')
        .typeError('You must specify a number')
        .min(0, 'Value must be greater than zero')
        .max(100, 'Value must be less than 100'),
});

interface EditSettingsFormProps {
    agency: IAgency;
}

const EditSettingsForm: FC<EditSettingsFormProps> = (props) => {
    const { agency } = props;

    const queryClient = useQueryClient();

    const { user } = useSelector((state: TRootState) => state.auth);
    const dispatch = useDispatch();

    const bankListDialog = useDialog();

    const initialValues: IAgencyForm = useMemo(() => {
        if (!agency)
            return {
                name: '',
                email: '',
                phoneNumber: '',
                website: '',
                address: {
                    line: '',
                    country: '',
                    state: '',
                    city: '',
                    pincode: '',
                },
                gst: {
                    number: '',
                },
                primaryColor: '#0052cc',
                secondaryColor: '#0052cc',
                tagline: '',
                fareDisplayCount: 0,
                subAgencyCommission: 0,
            };

        return {
            name: agency.name,
            email: agency.email,
            phoneNumber: agency.phoneNumber,
            website: agency.website,
            address: agency.address,
            gst: agency.gst,
            primaryColor: agency.primaryColor,
            secondaryColor: agency.secondaryColor,
            tagline: agency.tagline,
            fareDisplayCount: agency.fareDisplayCount,
            subAgencyCommission: agency.subAgencyCommission * 100,
        };
    }, [agency]);

    const updateMutation = useMutation(async (payload: any) => {
        const { data } = await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency`, payload);

        return data;
    });

    const updateLogoMutation = useMutation(async (payload: any) => {
        const { data } = await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/logo`, payload);
        return data;
    });

    const updateIconMutation = useMutation(async (payload: any) => {
        const { data } = await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/icon`, payload);
        return data;
    });

    const handleLogoChange = async (files: File[]) => {
        if (files && files.length > 0) {
            const formData = new FormData();

            const file = files[0];
            formData.append('file', file);

            if (file.size > 1024 * 1024) {
                toast.error('Maximum logo size is 1MB.');
                return;
            }

            try {
                const { logoUrl } = await updateLogoMutation.mutateAsync(formData);

                queryClient.setQueryData(['agencyDetail'], (prev: IAgency) => {
                    return update(prev, {
                        logoUrl: {
                            $set: logoUrl,
                        },
                    });
                });

                // Update Logo in redux
                dispatch(
                    authActions.setUser({
                        ...user,
                        agency: {
                            ...user.agency,
                            logoUrl: logoUrl,
                        },
                    })
                );

                toast.success('Logo updated successfully');
            } catch (err: any) {
                toast.error(err.data.message || 'Some error occured while updating the image, please try again.');
            }
        }
    };

    const handleIconChange = async (files: File[]) => {
        if (files && files.length > 0) {
            const formData = new FormData();

            const file = files[0];
            formData.append('file', file);

            if (file.size > 1024 * 1024) {
                toast.error('Maximum icon size is 1MB.');
                return;
            }

            try {
                const { iconUrl } = await updateIconMutation.mutateAsync(formData);

                queryClient.setQueryData(['agencyDetail'], (prev: IAgency) => {
                    return update(prev, {
                        iconUrl: {
                            $set: iconUrl,
                        },
                    });
                });

                toast.success('Icon updated successfully');
            } catch (err: any) {
                toast.error(err.data.message || 'Some error occured while updating the image, please try again.');
            }
        }
    };

    const handleSubmit = async (values: IAgencyForm) => {
        const updateValues = {
            ...values,
            subAgencyCommission: values.subAgencyCommission === 0 ? 0 : values.subAgencyCommission / 100,
        };

        try {
            await updateMutation.mutateAsync(updateValues);

            dispatch(
                authActions.setUser({
                    ...user,
                    agency: {
                        ...user.agency,
                        name: values.name,
                    },
                })
            );

            toast.success('Agency updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <div>
            <div className="grid grid-cols-12 gap-6 mt-8">
                <div className="col-span-4">
                    <label className="text-xs mb-3" htmlFor="logo">
                        AGENCY LOGO
                    </label>
                    <div className="flex items-center gap-4">
                        <img crossOrigin="anonymous" className="h-8" src={agency.logoUrl} alt="" />
                        <div className="relative">
                            <span className="text-primary font-medium">Upload New</span>
                            <input
                                type="file"
                                id="logo"
                                accept="image/*"
                                className="absolute top-0 left-0 w-full h-full opacity-0"
                                onChange={(e: any) => handleLogoChange(e.target.files)}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-span-4">
                    <label className="text-xs mb-3" htmlFor="icon">
                        AGENCY ICON
                    </label>
                    <div className="flex items-center gap-4">
                        <img crossOrigin="anonymous" className="h-8" src={agency.iconUrl} alt="" />
                        <div className="relative">
                            <span className="text-primary font-medium">Upload New</span>
                            <input
                                type="file"
                                id="icon"
                                accept="image/*"
                                className="absolute top-0 left-0 w-full h-full opacity-0"
                                onChange={(e: any) => handleIconChange(e.target.files)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={AgencyFormSchema}
                onSubmit={handleSubmit}
            >
                {({ values, getFieldProps }: FormikProps<IAgencyForm>) => (
                    <Form>
                        <div className="grid grid-cols-12 gap-6 mt-8">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="name">
                                    AGENCY NAME
                                </label>
                                <Field type="text" id="name" name="name" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="name" />
                                </div>
                            </div>

                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="gst.number">
                                    GST NUMBER
                                </label>
                                <Field type="text" id="gst.number" name="gst.number" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="gst.number" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-10 gap-6 mt-6">
                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="line">
                                    AGENCY ADDRESS
                                </label>
                                <Field type="text" id="line" name="address.line" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.line" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="city">
                                    AGENCY CITY
                                </label>
                                <Field type="text" id="city" name="address.city" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.city" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="state">
                                    AGENCY STATE
                                </label>
                                <Field type="text" id="state" name="address.state" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.state" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="country">
                                    AGENCY COUNTRY
                                </label>
                                <Field type="text" id="country" name="address.country" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.country" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="pincode">
                                    AGENCY PINCODE
                                </label>
                                <Field type="text" id="pincode" name="address.pincode" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.pincode" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="email">
                                    EMAIL ADDRESS
                                </label>
                                <Field type="email" id="email" name="email" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="email" />
                                </div>
                            </div>

                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="phoneNumber">
                                    CONTACT NUMBER
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">+91</div>
                                    <Field
                                        type="text"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        className="form-control pl-12"
                                    />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="phoneNumber" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="website">
                                    B2B PORTAL DOMAIN
                                </label>
                                <Field type="text" id="website" name="website" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="website" />
                                </div>
                            </div>

                            <div className="col-span-4">
                                <label className="text-xs mb-2">B2C PORTAL DOMAIN</label>
                                <div className="font-semibold text-green-500 py-2">Coming soon</div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="fareDisplayCount">
                                    FARE DISPLAY COUNT
                                </label>
                                <Field id="fareDisplayCount" name="fareDisplayCount" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="fareDisplayCount" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="primaryColor">
                                    PRIMARY COLOR
                                </label>
                                <div className="input-group">
                                    <Field
                                        type="text"
                                        id="primaryColor"
                                        name="primaryColor"
                                        className="form-control pr-8"
                                    />
                                    <div
                                        className="absolute top-0 right-0 w-12 h-full rounded-r-lg border-l border-gray-200"
                                        style={{ backgroundColor: values.primaryColor }}
                                    >
                                        <input
                                            type="color"
                                            className="h-full opacity-0"
                                            {...getFieldProps('primaryColor')}
                                        />
                                    </div>
                                </div>

                                <div className="form-error">
                                    <ErrorMessage name="primaryColor" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="secondaryColor">
                                    SECONDARY COLOR
                                </label>
                                <div className="input-group">
                                    <Field
                                        type="text"
                                        id="secondaryColor"
                                        name="secondaryColor"
                                        className="form-control pr-8"
                                    />
                                    <div
                                        className="absolute top-0 right-0 w-12 h-full rounded-r-lg border-l border-gray-200"
                                        style={{ backgroundColor: values.secondaryColor }}
                                    >
                                        <input
                                            type="color"
                                            className="h-full opacity-0"
                                            {...getFieldProps('secondaryColor')}
                                        />
                                    </div>
                                </div>

                                <div className="form-error">
                                    <ErrorMessage name="secondaryColor" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-8">
                                <label className="text-xs mb-2" htmlFor="primaryColor">
                                    TAGLINE
                                </label>
                                <Field type="text" id="tagline" name="tagline" className="form-control" />

                                <div className="form-error">
                                    <ErrorMessage name="tagline" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="subAgencyCommission">
                                    SUB AGENCY COMMISSION PERCENTAGE
                                </label>
                                <Field
                                    type="number"
                                    id="subAgencyCommission"
                                    name="subAgencyCommission"
                                    className="form-control"
                                />

                                <div className="form-error">
                                    <ErrorMessage name="subAgencyCommission" />
                                </div>
                            </div>
                        </div>

                        {/* <div className="mt-8">
                            <label className="text-xs mb-4" htmlFor="website">
                                BANK ACCOUNT(S)
                            </label>

                            <div className="flex items-center gap-8">
                                <a className="text-blue-500 text-sm cursor-pointer" onClick={bankListDialog.show}>
                                    {agency.banks.length} Bank Account{agency.banks.length !== 1 && 's'}
                                </a>
                                <a className="text-blue-500 text-sm cursor-pointer" onClick={bankAddDialog.show}>
                                    + Add New
                                </a>
                            </div>
                        </div> */}

                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-14"
                            isLoading={updateMutation.isLoading}
                        >
                            Save Changes
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditSettingsForm;
