import { FC } from 'react';
import http from 'app/config/http';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from 'app/components/PageLoader/PageLoader';
import FareDetails from './components/FareDetails/FareDetails';
import TickerHeader from './components/TicketHeader/TicketHeader';
import PassengerList from './components/PassengerList/PassengerList';
import FlightItinerary from './components/FlightItinerary/FlightItinerary';
import PrintLayoutWrapper from 'app/components/PrintLayoutWrapper/PrintLayoutWrapper';
import TicketInformationList from './components/TicketInformationList/TicketInformationList';

interface BookingTicketProps {}

const BookingTicket: FC<BookingTicketProps> = (props) => {
    const { bookingId, type } = useParams();
    const navigate = useNavigate();

    let api = '';
    if (type === 'AG') {
        api = `/api/v1/agent/bookings/${bookingId}`;
    } else if (type === 'SAG') {
        api = `/api/v1/agent/agency/sub-agency-bookings/${bookingId}/print-ticket`;
    } else {
        navigate('/404');
    }

    const query = useQuery(['bookingTicket'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}${api}`);

        return data;
    });

    if (query.isLoading) return <PageLoader />;

    const booking = query.data;

    return (
        <PrintLayoutWrapper>
            <div className="flex flex-col min-h-screen">
                <TickerHeader agency={booking.agency} subAgency={booking.subAgency} />
                <FlightItinerary
                    segments={booking.segments}
                    cabinClass={booking.priceDetail.fareDescription.adult.cabinClass}
                    refundableType={booking.priceDetail.fareDescription.adult.refundableType}
                    createdAt={booking.createdAt}
                />
                {/* {Passenger Details} */}
                <PassengerList booking={booking} />
                {/* {Fare details} */}
                <FareDetails booking={booking} />
                {/* {Important information} */}
                <TicketInformationList htmlContent={booking.tnc.content} />
                {/* {Helpline} */}
                <div className="mt-12 px-10 pb-8">
                    <div className="text-sm font-semibold text-primary">
                        {booking.agency.email} | {booking.agency.website}
                    </div>
                </div>
            </div>
        </PrintLayoutWrapper>
    );
};

export default BookingTicket;
