import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { EditPencilIcon } from 'app/icons';
import { getFareSupplier, getFareSupplierLabelFromEnum } from 'app/utils/flight-utils';

interface IFareSupplierAndSubAgencyDetailsCard {
    booking: any;
    isEditable?: boolean;
    openModal?: () => void;
}

const CreateLabel: FC = (booking: any) => {
    const fareSupplier = booking.fareSupplier;

    if (fareSupplier === '0') return <div className="mt-8 font-semibold">{getFareSupplier(fareSupplier)}</div>;
    if (fareSupplier === '1')
        return <img className="w-20" src="/icons/fare-supplier-updated/TJLogo.png" alt="Trip Jack" />;
    if (fareSupplier === '2')
        return (
            <Link className=" block mt-8" to={`/sub-agencies/${booking?.listedBySubAgency?._id}/details`}>
                <div className="text-blue-500 font-medium">{booking?.listedBySubAgency?.name}</div>
            </Link>
        );

    if (fareSupplier === '3')
        return <img className="w-20" src="/icons/fare-supplier-updated/AirIQLogo.png" alt="Air IQ" />;
    if (fareSupplier === '4') return <img className="w-20" src="/icons/fare-supplier-updated/TBOLogo.png" alt="" />;
    if (fareSupplier === '5')
        return <img className="w-20" src="/icons/fare-supplier-updated/KafilaLogo.png" alt="kafila" />;
    if (fareSupplier === '6')
        return <img className="w-20" src="/icons/fare-supplier-updated/FBLogo.png" alt="Fare Boutique" />;
    if (fareSupplier === '100')
        return (
            <div className="mt-8 font-semibold">
                Manual Booking -{' '}
                {booking.meta?.fareSupplierName
                    ? booking.meta.fareSupplierName
                    : getFareSupplierLabelFromEnum(booking.meta.fareSupplier)}
            </div>
        );
    return <span>-</span>;
};

const FareSupplierAndSubAgencyDetailsCard: FC<IFareSupplierAndSubAgencyDetailsCard> = (props) => {
    const { booking } = props;

    return (
        <>
            <div className="grid grid-cols-3 gap-4 mb-4">
                <div className="col-span-1 card px-6 pt-5 pb-6 mt-6 min-h-[144px] ">
                    <div className="flex justify-between items-center">
                        <div className="flex gap-4">
                            <div className="font-bold ">Provider </div>
                        </div>

                        <div className="flex justify-start items-center gap-2">
                            {props.isEditable && (
                                <div className="hover:cursor-pointer" onClick={props.openModal}>
                                    <EditPencilIcon />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mt-0">{CreateLabel(booking)}</div>
                </div>
                <div className="col-span-1 card px-6 pt-5 pb-6 mt-6 min-h-[144px] ">
                    <div className="flex justify-between items-center">
                        <div className="flex gap-4">
                            <div className="font-bold ">Sub Agency</div>
                        </div>

                        <div className="flex justify-start items-center gap-2">
                            {props.isEditable && (
                                <div className="hover:cursor-pointer" onClick={props.openModal}>
                                    <EditPencilIcon />
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="mt-8">
                            <Link to={`/sub-agencies/${booking.subAgency.id}/details`}>
                                <div className="text-blue-500 font-medium">{booking.subAgency.name}</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-span-1 card px-6 pt-5 pb-6 mt-6 min-h-[144px] ">
                    <div className="flex justify-between items-center">
                        <div className="flex gap-4">
                            <div className="font-bold ">Channel</div>
                        </div>

                        <div className="flex justify-start items-center gap-2">
                            {props.isEditable && (
                                <div className="hover:cursor-pointer" onClick={props.openModal}>
                                    <EditPencilIcon />
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="mt-8">
                            <div className="font-semibold">{booking.isOutward ? 'Outward API' : 'Web'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FareSupplierAndSubAgencyDetailsCard;
