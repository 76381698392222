import Spinner from 'app/components/Spinner/Spinner';
import { TBookingHistory } from 'app/types/booking-history';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getBookingHistory } from 'app/api/services/booking.service';

import React, { FC } from 'react';
import moment from 'moment';
import { getInitials } from 'app/utils/common-utils';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'app/components/Tooltip/Tooltip';
import { ClockIcon, InfoCircleIcon, MessageIcon, UserIcon } from 'app/icons';

interface IBookingHistoryCard {
    bookingId: string;
}

const BookingHistoryCard: FC<IBookingHistoryCard> = (props) => {
    const { bookingId } = props;
    const queryClient = useQueryClient();

    const { data, isLoading } = useQuery(['BOOKING', 'HISTORY', bookingId], async () => getBookingHistory(bookingId));

    const handleView = (history: TBookingHistory) => {
        if (history.isCurrent) {
            queryClient.fetchQuery(['bookingDetail', bookingId]);
        } else {
            queryClient.setQueryData(['bookingDetail', bookingId], history.state);
        }
    };

    const visibleBookingVersion = queryClient.getQueryData<TBookingHistory>(['bookingDetail', bookingId]);
    const currentlyViewing = visibleBookingVersion?.version || null;

    return (
        <>
            <div className="card px-6 pt-5 pb-6 mt-4">
                <div className="font-bold">Edit History </div>

                <div className="mt-8  min-h-[144px] ">
                    {isLoading && (
                        <div className="w-full flex justify-center items-center">
                            <Spinner />
                        </div>
                    )}

                    {!isLoading &&
                        data.map((history) => (
                            <div
                                onClick={() => handleView(history)}
                                className="mb-2 flex gap-2 items-center cursor-pointer hover:font-semibold"
                            >
                                <div
                                    className={`flex justify-between items-center text-sm mb-1 cursor-pointer  ${
                                        currentlyViewing === history.version
                                            ? 'font-bold'
                                            : currentlyViewing === null && history.isCurrent
                                            ? 'font-bold'
                                            : ''
                                    }`}
                                >
                                    <div>{history.isCurrent ? 'Latest' : `Version  ${history.version}`}</div>
                                </div>
                                <Tooltip
                                    content={
                                        <div className="p-4 h-44 w-56">
                                            <div className="flex gap-3 items-center">
                                                <ClockIcon />
                                                <div className="">
                                                    {moment(history.createdAt, 'YYYYMMDDHHmmss').format(
                                                        'DD MMM YYYY hh:mm'
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex gap-3 items-center mt-4">
                                                {' '}
                                                <UserIcon /> <div className="">{history?.action?.by?.name}</div>
                                            </div>
                                            <div className="flex gap-3 items-center mt-4">
                                                {' '}
                                                <MessageIcon /> <div className="">{history?.action?.message}</div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <InfoCircleIcon height={12} width={12} />
                                </Tooltip>

                                <div className="text-primary ml-auto">View</div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default BookingHistoryCard;

interface IHistoryTab {
    historicalBooking: TBookingHistory;
    handleView: (history: TBookingHistory) => void;
}

// const HistoryTab: FC<IHistoryTab> = (props) => {
//     const { historicalBooking, handleView } = props;

    // return 
        // <div onClick={() => handleView(historicalBooking)} className="shadow-sm py-3 cursor-pointer hover:bg-primary/5">
        //     <div className="flex gap-2">
        //         <div className="flex items-center gap-3">
        //             <div className="w-10 h-10 bg-primary/10 rounded-full flex items-center justify-center">
        //                 <div className="text-primary font-semibold">
        //                     {getInitials(historicalBooking.action.by.name)}
        //                 </div>
        //             </div>

        //             <div>
        //                 <div
        //                     className="font-semibold text-blue-500 cursor-pointer"
        //                     // onClick={() => navigate(`/users/${historicalBooking.action.by._id}/edit`)}
        //                 />
        //                 <div className="font-medium">{historicalBooking.action.by.name}</div>
        //                 <div className="text-sm"> {historicalBooking.action.message} </div>
        //             </div>
        //         </div>
        //         <div className="ml-auto text-sm font-semibold">
        //             <div className="text-center text-xs font-light ">Date Time</div>
        //             <div className="text-center ">
        //                 {moment(historicalBooking.createdAt, 'YYYYMMDDHHmmss').format('DD MMM YYYY')}
        //             </div>
        //             <div className="text-center">
        //                 {moment(historicalBooking.createdAt, 'YYYYMMDDHHmmss').format('hh:mm')}
        //             </div>
        //         </div>
        //     </div>
        // </div>
//     );
// };




