/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import { ISubAgency } from 'app/types';
import { formatCurrency } from 'app/utils/currency-utils';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import update from 'immutability-helper';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { IOpeninigBalance, ISubAgencyForm } from '../../types';
import BankAddModal from '../BankAddModal/BankAddModal';
import BankListModal from '../BankListModal/BankListModal';
import OpeningBalanceEditModal from '../OpeningBalanceEditModal/OpeningBalanceEditModal';

const SubAgencyFormSchema = Yup.object().shape({
    name: Yup.string().trim().required('This field is required'),
    gst: Yup.object().shape({
        number: Yup.string().trim(),
    }),
    pan: Yup.object().shape({
        number: Yup.string().trim().required('This field is required'),
    }),
    phoneNumber: Yup.string()
        .trim()
        .required('This field is required')
        .matches(/^\d{10}$/, 'Please enter a valid mobile number'),
    email: Yup.string().trim().required('This field is required').email('Please enter a valid email address'),
    creditLimit: Yup.string().trim().required('This field is required').matches(/^\d+$/, 'Please enter a valid amount'),
    address: Yup.object().shape({
        line: Yup.string().trim().required('This field is required'),
        state: Yup.string().trim().required('This field is required'),
        city: Yup.string().trim().required('This field is required'),
        pincode: Yup.string().trim().required('This field is required'),
        country: Yup.string().trim().required('This field is required'),
    }),
    banks: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().trim().required('This field is required'),
            ifsc: Yup.string().trim().required('This field is required'),
            account: Yup.object().shape({
                type: Yup.string().trim().required('This field is required'),
                number: Yup.string().trim().required('This field is required'),
                holderName: Yup.string().trim().required('This field is required'),
            }),
        })
    ),
});

interface SubAgencyFormProps {
    subAgency: ISubAgency;
    openingBalance: IOpeninigBalance;
}

const SubAgencyForm: FC<SubAgencyFormProps> = (props) => {
    const { subAgency, openingBalance } = props;

    const queryClient = useQueryClient();
    const bankListDialog = useDialog();
    const bankAddDialog = useDialog();
    const openingBalanceEditDialog = useDialog();

    const initialValues: ISubAgencyForm = useMemo(() => {
        if (!subAgency)
            return {
                name: '',
                email: '',
                phoneNumber: '',
                creditLimit: 0,
                openingBalance: 0,
                gst: {
                    number: '',
                },
                pan: {
                    number: '',
                },
                address: {
                    line: '',
                    state: '',
                    city: '',
                    pincode: '',
                    country: '',
                },
                banks: [],
            };

        return {
            name: subAgency.name,
            email: subAgency.email,
            phoneNumber: subAgency.phoneNumber,
            creditLimit: subAgency.creditLimit,
            gst: { number: subAgency.gst.number },
            pan: { number: subAgency.pan.number },
            address: subAgency.address,
            banks: subAgency.banks,
            openingBalance: subAgency.openingBalance,
        };
    }, [subAgency]);

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgency._id}`,
            payload
        );

        return data;
    });

    const changeEnabledMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgency._id}/is-enabled`,
            { isEnabled: values.isEnabled }
        );

        return data;
    });

    const handleSubmit = async (values: ISubAgencyForm) => {
        try {
            await mutation.mutateAsync(values);

            toast.success('Sub Agency updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const handleEnabledChange = async (row: ISubAgency, isChecked: boolean) => {
        queryClient.setQueryData(['subAgencyDetail', subAgency._id], (prevValues: any) => {
            return update(prevValues, {
                isEnabled: { $set: isChecked },
            });
        });

        try {
            await changeEnabledMutation.mutateAsync({ _id: row._id, isEnabled: isChecked });

            toast.success(`Sub Agency ${isChecked ? 'enabled' : 'disabled'} successfully`);
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');

            queryClient.setQueryData(['subAgencyDetail'], (prevValues: any) => {
                return update(prevValues, { isEnabled: { $set: !isChecked } });
            });
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={SubAgencyFormSchema}
                onSubmit={handleSubmit}
            >
                {({ values, getFieldProps }: FormikProps<ISubAgencyForm>) => (
                    <Form>
                        {/* agecy id and create */}
                        <div className="grid grid-cols-12 gap-6 mt-8">
                            <div className="col-span-3">
                                <label className="text-xs mb-2">AGENCY ID</label>
                                <div className="font-semibold py-2">{subAgency._id}</div>
                            </div>
                            <div className="col-span-3">
                                <label className="text-xs mb-2">CREATED AT</label>
                                <div className="font-semibold py-2 flex gap-2">
                                    {moment(subAgency.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}{' '}
                                    <span>at</span>
                                    {moment(subAgency.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="isEnabled">
                                    ENABLED
                                </label>

                                <div className="mt-2">
                                    <input
                                        type="checkbox"
                                        className="form-switch success"
                                        checked={subAgency.isEnabled}
                                        onChange={(e) => handleEnabledChange(subAgency, e.target.checked)}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* name , gst */}
                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="name">
                                    NAME
                                </label>
                                <Field type="text" id="name" name="name" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="name" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="gst.number">
                                    GST NUMBER
                                </label>
                                <Field type="text" id="gst.number" name="gst.number" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="gst.number" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="pan.number">
                                    PAN
                                </label>
                                <Field type="text" id="pan.number" name="pan.number" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="pan.number" />
                                </div>
                            </div>
                        </div>

                        {/* address details */}
                        <div className="grid grid-cols-10 gap-6 mt-6">
                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="line">
                                    ADDRESS LINE
                                </label>
                                <Field type="text" id="line" name="address.line" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.line" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="city">
                                    CITY
                                </label>
                                <Field type="text" id="city" name="address.city" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.city" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="state">
                                    STATE
                                </label>
                                <Field type="text" id="state" name="address.state" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.state" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="country">
                                    COUNTRY
                                </label>
                                <Field type="text" id="country" name="address.country" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.country" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="pincode">
                                    PINCODE
                                </label>
                                <Field type="text" id="pincode" name="address.pincode" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.pincode" />
                                </div>
                            </div>
                        </div>

                        {/* email and number */}
                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="email">
                                    EMAIL
                                </label>
                                <Field type="email" id="email" name="email" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="email" />
                                </div>
                            </div>

                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="phoneNumber">
                                    PHONE NUMBER
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">+91</div>
                                    <Field
                                        type="text"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        className="form-control pl-12"
                                    />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="phoneNumber" />
                                </div>
                            </div>
                        </div>

                        {/* credit details */}
                        <div className="grid grid-cols-12 gap-6 mt-8">
                            <div className="col-span-3">
                                <label className="text-xs mb-2" htmlFor="creditLimit">
                                    CREDIT LIMIT
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">₹</div>
                                    <Field
                                        type="text"
                                        id="creditLimit"
                                        name="creditLimit"
                                        className="form-control pl-8"
                                    />
                                </div>

                                <div className="form-error">
                                    <ErrorMessage name="creditLimit" />
                                </div>
                            </div>
                        </div>

                        {/* banking details */}
                        <div className="mt-8">
                            <label className="text-xs mb-4" htmlFor="website">
                                BANK ACCOUNT(S)
                            </label>

                            <div className="flex items-center gap-8">
                                <a className="text-blue-500 text-sm cursor-pointer" onClick={bankListDialog.show}>
                                    {subAgency.banks.length} Bank Account{subAgency.banks.length !== 1 && 's'}
                                </a>
                                <a className="text-blue-500 text-sm cursor-pointer" onClick={bankAddDialog.show}>
                                    + Add New
                                </a>
                            </div>
                        </div>

                        <div className="mt-8">
                            <label className="text-xs mb-4" htmlFor="website">
                                OPENING BALANCE
                            </label>

                            <div className="flex flex-col gap-2 ">
                                <div className="flex gap-8">
                                    <div> {formatCurrency(openingBalance.amount)}</div>
                                    <button
                                        type="button"
                                        className="text-sm text-blue-500"
                                        onClick={openingBalanceEditDialog.show}
                                    >
                                        Edit Opening Balance
                                    </button>
                                </div>
                                <div className="flex gap-8 items-center">
                                    <div className="text-xs">UPDATED BY</div>
                                    <div className="font-medium ">{openingBalance.updatedBy.name}</div>
                                </div>
                            </div>
                        </div>

                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                            isLoading={mutation.isLoading}
                        >
                            Save Changes
                        </LoaderButton>
                    </Form>
                )}
            </Formik>

            <BankListModal
                show={bankListDialog.isOpen}
                onClose={bankListDialog.hide}
                subAgencyId={subAgency._id}
                banks={subAgency.banks}
            />
            <BankAddModal show={bankAddDialog.isOpen} onClose={bankAddDialog.hide} subAgencyId={subAgency._id} />

            <OpeningBalanceEditModal
                show={openingBalanceEditDialog.isOpen}
                onClose={openingBalanceEditDialog.hide}
                subAgencyId={subAgency._id}
                openingBalance={openingBalance.amount}
            />

            {/* <BankListModal
                show={bankListDialog.isOpen}
                onClose={bankListDialog.hide}
                subAgencyId={subAgency._id}
                banks={subAgency.banks}
            /> */}
        </>
    );
};

export default SubAgencyForm;
