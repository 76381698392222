import { useMutation } from '@tanstack/react-query';
import { ICommissionPlan } from 'app/enums/commission-plan';
import { createCommissionPlan } from 'app/api/services/fare-commission.service';

const useCommissionPlan = () => {
    const createCommissionPlanMutation = useMutation((payload: ICommissionPlan) => createCommissionPlan(payload));

    return {
        createCommissionPlanMutation,
    };
};

export default useCommissionPlan;
