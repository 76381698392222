import { EditPencilIcon } from 'app/icons';
import React, { FC } from 'react';

interface IContactDetailsCard {
    booking: any;
    isEditable?: boolean;
    openModal?: () => void;
    viewingOldVersion: boolean;
}

const ContactDetailsCard: FC<IContactDetailsCard> = (props) => {
    const { booking } = props;
    return (
        <div className="card px-6 pt-5 pb-6 mt-6">
            <div className="flex justify-between items-center">
                <div className="flex gap-4">
                    <div className="font-bold ">Contact Details</div>
                </div>

                <div className="flex justify-start items-center gap-2">
                    {props.isEditable && (
                        <div className="hover:cursor-pointer" onClick={props.openModal}>
                            <EditPencilIcon />
                        </div>
                    )}
                </div>
            </div>

            <div className="grid grid-cols-5 gap-8 mt-8 mb-4">
                <div className="col-start-2 col-span-1">
                    <div className="text-xs text-gray-500">Mobile Number</div>
                </div>
                <div className="col-span-1">
                    <div className="text-xs text-gray-500">Email Address</div>
                </div>
            </div>

            <div className="grid grid-cols-5 gap-8">
                <div className="col-span-1">
                    <div className="font-medium">Contact</div>
                </div>
                <div className="col-span-1">
                    <div className="font-medium">{booking.contactDetails.mobileNumber}</div>
                </div>
                <div className="col-span-1">
                    <div className="font-medium">{booking.contactDetails.email}</div>
                </div>
            </div>
        </div>
    );
};

ContactDetailsCard.defaultProps = {
    isEditable: false,
    openModal: () => {},
    viewingOldVersion: false,
};

export default ContactDetailsCard;
