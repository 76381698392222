import React, { FC } from 'react';
import { IAmendmentSearch } from '../hooks/use-search-query';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { getAmendmentStatusColor, getAmendmentStatusText } from 'app/utils/common-utils';
import { useNavigate } from 'react-router-dom';

interface IRenderAmendment {
    amendment: IAmendmentSearch;
    handlePreNavigate: () => void;
}

const RenderAmendment: FC<IRenderAmendment> = (props) => {
    const { amendment, handlePreNavigate } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        handlePreNavigate();
        navigate(`/amendments?tab=1&query=${amendment._id}`);
    };

    return (
        <div className="grid grid-cols-12 px-2 py-2 gap-2 hover:bg-gray-50 cursor-pointer" onClick={handleClick}>
            <div className="col-span-4 flex flex-col items-center justify-between">
                <div className="font-semibold text-primary">{amendment._id}</div>
                <StatusBadge className="" color={getAmendmentStatusColor(amendment.status)}>
                    {getAmendmentStatusText(amendment.status)}
                </StatusBadge>
            </div>
            <div className="col-span-4 flex flex-col items-start justify-start gap-2">
                <div className="text-sm font-bold">{amendment.subAgency.name}</div>
                <div className="font-xs font-semibold leading-3 text-black/60">{amendment.booking._id}</div>
            </div>
            <div className="col-span-4">
                <div className="">
                    <div className="text-xs font-semibold">{amendment.passengers[0]}</div>
                    {amendment.passengers.length > 1 && (
                        <p className="mt-1">+ {amendment.passengers.length - 1} Passengers</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RenderAmendment;
