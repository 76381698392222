import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import http from 'app/config/http';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import { IMarkupControlForm } from '../../types';
import * as Yup from 'yup';
import MarkupHistoryTable from '../MarkupHistoryTable/MarkupHistoryTable';

const MarkupControlFormSchema = Yup.object({
    markup: Yup.string().trim().required('This field is required'),
});

interface MarkupControlFormProps {
    currentMarkup: number;
    fareSupplier: string;
    refetchQueryIdentifier: any[];
    markupHistory: any[];
}

const MarkupControlForm: FC<MarkupControlFormProps> = (props) => {
    const { currentMarkup, fareSupplier, refetchQueryIdentifier, markupHistory } = props;

    const queryClient = useQueryClient();

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/${fareSupplier}/markup`,
            payload
        );
        return data;
    });

    const initialValues: IMarkupControlForm = useMemo(() => {
        if (!currentMarkup)
            return {
                markup: 0,
            };

        return {
            markup: currentMarkup,
        };
    }, [currentMarkup]);

    const handleSubmit = async (values: IMarkupControlForm) => {
        const payload = {
            markup: +values.markup,
        };

        try {
            await mutation.mutateAsync(payload);

            queryClient.fetchQuery(refetchQueryIdentifier);

            toast.success('Markup updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occurred, please try again.');
            console.log(ex);
        }
    };

    return (
        <div className="card py-5 mt-8">
            <div className="px-6 font-bold">Outward API Markup Control</div>
            <div className="px-6 mt-8">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={MarkupControlFormSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="markup">
                                    CURRENT MARKUP
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">₹</div>
                                    <Field type="text" id="markup" name="markup" className="form-control pl-8" />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="markup" />
                                </div>
                            </div>
                            <div className="col-span-4 self-center">
                                <LoaderButton
                                    type="submit"
                                    className="bg-primary text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4"
                                    isLoading={mutation.isLoading}
                                >
                                    Update Markup
                                </LoaderButton>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
            <div className="mt-8">
                {markupHistory.length > 0 && <MarkupHistoryTable markupHistory={markupHistory} />}
            </div>
        </div>
    );
};

export default MarkupControlForm;
