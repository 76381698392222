import { FC, useState } from 'react';
import Modal from 'app/components/Modal/Modal';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'app/components/Spinner/Spinner';
import { createBookingFlowLogsByBookingId } from 'app/api/services/booking-flow-service.service';
import { IBookingFlowEvent } from 'app/types/booking-flow';
import moment from 'moment';
import { SYSTEM_DATE_TIME_FORMAT } from 'app/utils/date-utils';
import ReactJson from 'react-json-view';

interface IBookingFlowLogsModal {
    show: boolean;
    onClose: () => void;
    bookingId: string;
}

const BookingFlowLogsModal: FC<IBookingFlowLogsModal> = (props) => {
    const { bookingId } = props;

    const { data, isLoading, isError } = useQuery(['BOOKING_FLOWS', 'LOGS', bookingId, 'ACB'], async () =>
        createBookingFlowLogsByBookingId(bookingId)
    );

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-8 pt-8 pb-10 w-[1280px] ">
            {/* {(data, isLoading, isError)} */}
            <div className="font-bold text-lg mb-4">Booking Flow - {bookingId}</div>

            {isLoading ? (
                <div className="flex flex-col gap-2 items-center justify-center h-32">
                    <Spinner />
                    <div className="text-base font-medium text-black/60">Loading...</div>
                </div>
            ) : isError ? (
                <div className="flex flex-col gap-2 items-center justify-center h-32">
                    <div className="text-base font-medium text-black/60">Oops no logs for this booking</div>
                </div>
            ) : (
                <div>
                    <ul>
                        {data.bookingFlow.events.map((even_t) => (
                            <li className="border-b last:border-none" key={even_t.createdAt}>
                                <ViewBookingEventLog bookingFlowEvent={even_t} />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </Modal>
    );
};

export default BookingFlowLogsModal;

interface IViewBookingEventLog {
    bookingFlowEvent: IBookingFlowEvent;
}

const ViewBookingEventLog: FC<IViewBookingEventLog> = (props) => {
    const { bookingFlowEvent } = props;
    const [viewMeta, setViewMeta] = useState(false);

    return (
        <div className="py-2 grid grid-cols-12">
            <div className="col-span-2 flex gap-2 font-semibold">
                <div>{moment(bookingFlowEvent.createdAt, SYSTEM_DATE_TIME_FORMAT).format('HH:mm')}</div>
                <div>{moment(bookingFlowEvent.createdAt, SYSTEM_DATE_TIME_FORMAT).format('DD MMM YY')}</div>
            </div>
            <div className="col-span-10">
                {bookingFlowEvent.message}{' '}
                {bookingFlowEvent.eventType === 'ADDED_PAX' && (
                    <span
                        onClick={() => setViewMeta((prev) => !prev)}
                        className="cursor-pointer text-blue-500 text-sm font-bold ml-6"
                    >
                        {!viewMeta ? 'View' : 'Hide'}
                    </span>
                )}
            </div>
            {viewMeta && (
                <div className="col-span-12">
                    {bookingFlowEvent?.meta ? (
                        <ReactJson src={bookingFlowEvent?.meta} />
                    ) : (
                        <div className="flex justify-center items-center">No Meta Found</div>
                    )}
                </div>
            )}
        </div>
    );
};


