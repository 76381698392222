import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import Modal from 'app/components/Modal/Modal';
import http from 'app/config/http';
import { AmendmentStatusEnum } from 'app/enums/amendment.status.enum';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import { toast } from 'react-toastify';

interface IAmendmentActionDialog {
    show: boolean;
    onClose: () => void;
    bookingId: string;
    amendmentId: string;
}

const paymentActions = [
    {
        label: 'Yes',
        value: true,
    },
    { label: 'No', value: false },
];

const AmendmentActionDialog: FC<IAmendmentActionDialog> = (props) => {
    const queryClient = useQueryClient();

    const approveAmendmentMutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${props.bookingId}/amendments/${props.amendmentId}/complete`,
            payload
        );
        return data;
    });

    const handleConfirm = async (values: any) => {
        const data = {
            status: 'COMPLETED',
            ...values,
        };

        try {
            await approveAmendmentMutation.mutateAsync(data);
            queryClient.fetchQuery(['bookingDetail']);
            toast.success('Amendment Completed');
            props.onClose();
        } catch (e: any) {
            toast.error(e.data.message);
        }
    };

    const handleSubmit = (values: any) => {
        handleConfirm(values);
    };

    return (
        <Modal className="px-10 py-10 w-[520px] " show={props.show} onClose={props.onClose}>
            <Formik initialValues={{ accountSettled: true }} onSubmit={handleSubmit}>
                {({ setFieldValue, values }) => (
                    <Form>
                        {/* {props.ammendment.noteType === 'CREDIT' && ( */}
                        {true && (
                            <div className="mt-2 flex flex-col items-center">
                                <label className="text-base mb-2 block" htmlFor="Amount">
                                    {/* Instantly refund to the wallet */}
                                    Do you want to make account changes instantly
                                </label>
                                <div className="flex gap-4 ">
                                    {paymentActions.map((row) => (
                                        <div key={row.label} className="flex gap-1 ">
                                            <Field
                                                type="radio"
                                                id={row.label}
                                                name="type"
                                                onChange={() => setFieldValue('accountSettled', row.value)}
                                                checked={row.value === values.accountSettled}
                                                className="accent-primary"
                                            />
                                            <label className={'text-sm block'} htmlFor={row.label}>
                                                {row.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <div className="form-error">
                                    <ErrorMessage name="type" />
                                </div>
                            </div>
                        )}

                        <div className="text-gray-900 mt-4 text-center">
                            Are you sure you want to complete this amendment
                        </div>
                        <div className="flex justify-center gap-4 mt-6">
                            <LoaderButton
                                className="bg-red-500 text-white px-6 text-sm rounded-lg"
                                // onClick={handleConfirm}
                                onClick={handleSubmit}
                                isLoading={approveAmendmentMutation.isLoading}
                            >
                                Yes, Proceed
                            </LoaderButton>
                            <button
                                className="bg-gray-200 text-gray-600 px-6 text-sm rounded-lg"
                                onClick={props.onClose}
                            >
                                No, keep it
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default AmendmentActionDialog;
