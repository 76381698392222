import React, { FC } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Modal from 'app/components/Modal/Modal';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import * as Yup from 'yup';

interface IBookingChangeRemarkDialog {
    show: boolean;
    onClose: () => void;
    handleRequest: (remark: string) => void;
    isLoading: boolean;
}

const validationSchema = Yup.object({
    remark: Yup.string().trim().required('Remark is required'),
});

const BookingChangeRemarkDialog: FC<IBookingChangeRemarkDialog> = (props) => {
    const { handleRequest } = props;

    const initialValues = {
        remark: '',
    };

    const onSubmit = (values: { remark: string }) => {
        handleRequest(values.remark);
    };

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-8 pt-8 pb-10 w-[1280px]">
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {({ values }) => (
                    <Form>
                        <div className="col-span-1">
                            <label className="text-xs mb-2" htmlFor={`remark`}>
                                Remark
                            </label>
                            <div className="input-group">
                                <Field type="text" id={`remark`} name={`remark`} className="form-control" />
                            </div>

                            <div className="form-error">
                                <ErrorMessage name={`remark`} />
                            </div>
                        </div>

                        <LoaderButton
                            isLoading={props.isLoading}
                            className="bg-primary text-white px-4 py-6 rounded-lg mt-6"
                        >
                            Update Booking
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default BookingChangeRemarkDialog;
