import { useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import TableSearch from 'app/components/TableSearch/TableSearch';
import http from 'app/config/http';
import { produce } from 'immer';
import { FC, useEffect, useState } from 'react';
import SubAgencyTable from './components/SubAgencyTable/SubAgencyTable';
import { IReceviableSubAgency } from './types';
import { cloneDeep } from 'lodash';
import { IReceviable } from 'app/types';

interface SendReminderMailProps {}

const SendReminderMail: FC<SendReminderMailProps> = (props) => {
    const [allSubAgencyList, setAllSubAgencyList] = useState<IReceviable[]>([]);
    const [filteredSubAgencyList, setFilteredSubAgencyList] = useState<IReceviable[]>([]);

    const recieveablesQuery = useQuery<IReceviableSubAgency>(['subAgenciesReceivablesReport'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/reports/recieveables`);

        return data;
    });

    const transformData = (data: IReceviableSubAgency): IReceviable[] => {
        let temp = data.reports;

        return temp.map((val) => ({ ...val, isChecked: false }));
    };

    const useBatchMutation = () => {
        const [map, setMap] = useState<{
            [x: string]: { isLoading: boolean; isError: boolean; data: any; error: any };
        }>({});

        const mutateAllAsync = async (inputList: any[]) => {
            setMap(
                inputList.reduce((acc, cur) => {
                    acc[cur._id] = { isLoading: true, isError: false, data: null, error: null };

                    return acc;
                }, {})
            );

            // setList(inputList.map(() => ({ isLoading: true, isError: false, data: null, error: null })));

            await Promise.all(
                inputList.map(async (item, i) => {
                    try {
                        const { data } = await http.post(
                            `${process.env.REACT_APP_API_URL}/api/v1/agent/reports/recieveables/${item._id}/reminder`
                        );

                        // setList((prev) =>
                        //     produce(prev, (draftList) => {
                        //         draftList[i].data = data;
                        //     })
                        // );

                        setMap((prev) =>
                            produce(prev, (draftMap) => {
                                draftMap[item._id].data = data;
                            })
                        );
                    } catch (err) {
                        setMap((prev) =>
                            produce(prev, (draftMap) => {
                                draftMap[item._id].isError = true;
                                draftMap[item._id].error = err;
                            })
                        );
                        // setList((prev) =>
                        //     produce(prev, (draftList) => {
                        //         draftList[i].isError = true;
                        //         draftList[i].error = err;
                        //     })
                        // );
                    } finally {
                        setMap((prev) =>
                            produce(prev, (draftMap) => {
                                draftMap[item._id].isLoading = false;
                            })
                        );
                        // setList((prev) =>
                        //     produce(prev, (draftList) => {
                        //         draftList[i].isLoading = false;
                        //     })
                        // );
                    }
                })
            );
        };

        return {
            map,
            mutateAllAsync,
        };
    };

    const batchMutation = useBatchMutation();

    const handleBatchReminders = async () => {
        const filterSubAgencies = allSubAgencyList
            .filter((val) => val.isChecked === true)
            .map((val) => ({ _id: val._id }));

        allSubAgencyList.map((subAgency) => ({ ...subAgency, isChecked: false }));
        // let resetArray = allSubAgencyList.map((subAgency) => ({ ...subAgency, isChecked: false }));
        // this code resets both arrays
        // setFilteredSubAgencyList((subAgencies) => {
        //     return resetArray;
        //     // return subAgencies.map((subAgency) => ({ ...subAgency, isChecked: false }));
        // });
        // setAllSubAgencyList((subAgencies) => {
        //     return resetArray;
        //     // return subAgencies.map((subAgency) => ({ ...subAgency, isChecked: false }));
        // });

        // this code only removes check mark
        setFilteredSubAgencyList((subAgencies) => {
            return subAgencies.map((val) => ({
                ...val,
                isChecked: false,
            }));
            // return subAgencies.map((subAgency) => ({ ...subAgency, isChecked: false }));
        });
        setAllSubAgencyList((subAgencies) => {
            return subAgencies.map((val) => ({
                ...val,
                isChecked: false,
            }));
        });

        await batchMutation.mutateAllAsync(filterSubAgencies);
    };

    useEffect(() => {
        if (recieveablesQuery.data) {
            const data = transformData(recieveablesQuery.data);

            setAllSubAgencyList(cloneDeep(data));
            setFilteredSubAgencyList(cloneDeep(data));
        }
    }, [recieveablesQuery.data]);

    const handleToggle = (_id: string, checked: boolean) => {
        setFilteredSubAgencyList((val) => {
            let temp = [...val];
            let i = temp.findIndex((val) => val._id === _id);
            temp[i].isChecked = checked;
            return temp;
        });
        setAllSubAgencyList((val) => {
            let temp = [...val];
            let i = temp.findIndex((val) => val._id === _id);
            temp[i].isChecked = checked;
            return temp;
        });
    };

    const handleToggleAll = (checked: boolean) => {
        setFilteredSubAgencyList((val) => {
            let temp = [...val];
            return temp.map((iVal) => {
                return {
                    ...iVal,
                    isChecked: checked,
                };
            });
        });
        setAllSubAgencyList((val) => {
            let temp = [...val];
            return temp.map((iVal) => {
                return {
                    ...iVal,
                    isChecked: checked,
                };
            });
        });
    };

    const handleSearch = (q: string) => {
        const list = allSubAgencyList.filter((row) => {
            const val = q.toLowerCase();
            let flag = row.name.toLowerCase().includes(val);
            flag = flag || row._id.toLowerCase().includes(val);
            return flag;
        });
        setFilteredSubAgencyList(list);
    };

    if (recieveablesQuery.isLoading) return <PageLoader />;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="flex justify-between items-center">
                <div>
                    <h5 className="font-bold">Receivables</h5>
                    <p className="mt-1">Sub Agency Balances </p>
                </div>
            </div>

            <div className="card mt-8">
                <div className="flex items-center px-6 pt-5">
                    <TableSearch
                        placeholder="Search for a sub agency"
                        onSearch={handleSearch}
                        onSearchReset={() => setFilteredSubAgencyList([...allSubAgencyList])}
                    />

                    <button
                        disabled={!allSubAgencyList.some((val) => val.isChecked === true)}
                        onClick={handleBatchReminders}
                        className="ml-auto bg-black text-white text-sm font-semibold px-6 rounded-lg text-center h-[38px]"
                    >
                        Send Mail
                    </button>
                </div>

                <div className="mt-6">
                    <SubAgencyTable
                        subAgencies={filteredSubAgencyList}
                        handleToggle={handleToggle}
                        handleToggleAll={handleToggleAll}
                        batchMutation={batchMutation.map}
                        allSelected={allSubAgencyList.every((val) => val.isChecked === true)}
                    />
                </div>
            </div>
        </>
    );
};

export default SendReminderMail;
