/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from '@tanstack/react-query';
import PageHeading from 'app/components/PageHeading/PageHeading';
import Spinner from 'app/components/Spinner/Spinner';
import StatsCard from 'app/components/StatsCard/StatsCard';
import http from 'app/config/http';
import { ChevronRightIcon } from 'app/icons';
import { ISeriesTicket } from 'app/types/series-ticket';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import SalesChart from './components/SalesChart/SalesChart';
import TopAirlineList from './components/TopAirlineList/TopAirlineList';
import TopSubAgencyList from './components/TopSubAgencyList/TopSubAgencyList';
import UpcomingDepartureTable from './components/UpcomingDepartureTable/UpcomingDepartureTable';
import { formatCurrency } from 'app/utils/currency-utils';
import moment from 'moment';
import styles from './Dashboard.module.scss';

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = (props) => {
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').format('YYYYMMDD'));
    const [toDate, setToDate] = useState(moment().format('YYYYMMDD'));

    const statsQuery = useQuery<any>(['stats'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/dashboard/stats`);

        return data;
    });

    const salesChartQuery = useQuery<any[]>(['salesChart', fromDate, toDate], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/dashboard/sales-chart?fromDate=${fromDate}&toDate=${toDate}`
        );

        return data;
    });

    const topAirlinesQuery = useQuery<any[]>(['topAirlines'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/dashboard/top-airlines`);

        return data;
    });

    const upcomingBookingsQuery = useQuery<ISeriesTicket[]>(['upcomingBookings'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/dashboard/upcoming-bookings`);

        return data;
    });

    const topSubagenciesQuery = useQuery<any[]>(['topSubagencies'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/dashboard/top-sub-agencies`);

        return data;
    });

    const todayPaymentQuery = useQuery<any>(["today's recived payments"], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/dashboard/daywise-payments`);

        return data;
    });

    return (
        <div>
            <PageHeading message="here’s what’s happening with your agency today" />

            {
                <div className="grid grid-cols-4 gap-6 mt-8">
                    <div className="col-span-4 sm:col-span-2 xl:col-span-1">
                        <StatsCard
                            title="Today’s Sale"
                            content={statsQuery.isLoading ? 0 : formatCurrency(statsQuery.data.sale.today)}
                        />
                    </div>
                    <div className="col-span-4 sm:col-span-2 xl:col-span-1">
                        <StatsCard
                            title="Total Sales"
                            content={statsQuery.isLoading ? 0 : formatCurrency(statsQuery.data.sale.total)}
                        />
                    </div>
                    {/* <div className="col-span-4 sm:col-span-2 xl:col-span-1">
                        <StatsCard
                            title="Pending Name Submission"
                            content={statsQuery.isLoading ? 0 : statsQuery.data.pendingTicketCount}
                        />
                    </div> */}

                    <div className="col-span-4 sm:col-span-2 xl:col-span-1">
                        <StatsCard
                            title="Today's Payments"
                            content={
                                todayPaymentQuery.isLoading
                                    ? 0
                                    : formatCurrency(todayPaymentQuery.data.currentDayPayment)
                            }
                        />
                    </div>
                    <div className="col-span-4 sm:col-span-2 xl:col-span-1">
                        <StatsCard
                            title="Total Sub Agents"
                            content={statsQuery.isLoading ? 0 : statsQuery.data.subAgencyUserCount}
                        />
                    </div>
                </div>
            }

            <div className="grid grid-cols-12 gap-6 mt-8">
                <div className="col-span-12 md:col-span-7">
                    {/* Card 1 */}
                    <div className="card px-6 pt-5 pb-8 mb-6">
                        <div className="flex justify-between items-center">
                            <div className="font-bold">Sales Report</div>

                            <ul className="flex items-center gap-1 ">
                                <li>
                                    <div className=" py-[0.5rem] text-xs font-semibold leading-4 rounded-md text-gray-500 flex justify-start items-center gap-1">
                                        <span>From</span>
                                        <label htmlFor="formDate" className="relative text-blue-500">
                                            <input
                                                id="fromDate"
                                                type="date"
                                                className={[
                                                    'inline opacity-0 absolute top-0 left-0 w-full h-full p-0',
                                                    styles['linkDate'],
                                                ].join(' ')}
                                                value={moment(fromDate, 'YYYYMMDD').format('YYYY-MM-DD')}
                                                min={moment().subtract(1, 'years').format('YYYY-MM-DD')}
                                                max={moment().format('YYYY-MM-DD')}
                                                onChange={(event: any) =>
                                                    setFromDate(() =>
                                                        moment(event.target.value, 'YYYY-MM-DD').format('YYYYMMDD')
                                                    )
                                                }
                                            />
                                            {moment(fromDate, 'YYYYMMDD').format('DD MMM, YYYY')}
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="py-[0.5rem] text-xs font-semibold leading-4 rounded-md text-gray-500 flex justify-start items-center gap-1">
                                        <span>to</span>
                                        <label htmlFor="toDate" className="relative text-blue-500">
                                            <input
                                                id="toDate"
                                                type="date"
                                                className={[
                                                    'inline opacity-0 absolute top-0 left-0 w-full h-full p-0',
                                                    styles['linkDate'],
                                                ].join(' ')}
                                                value={moment(toDate, 'YYYYMMDD').format('YYYY-MM-DD')}
                                                min={moment().subtract(1, 'years').format('YYYY-MM-DD')}
                                                max={moment().format('YYYY-MM-DD')}
                                                onChange={(event: any) =>
                                                    setToDate(() =>
                                                        moment(event.target.value, 'YYYY-MM-DD').format('YYYYMMDD')
                                                    )
                                                }
                                            />
                                            {moment(toDate, 'YYYYMMDD').format('DD MMM, YYYY')}
                                        </label>
                                    </div>
                                </li>
                                {/* <li>
                                    <a className="px-3 py-[0.4rem] text-xs font-semibold rounded-md border border-gray-300">
                                        12 Months
                                    </a>
                                </li>
                                <li>
                                    <a className="px-3 py-[0.4rem] text-xs font-semibold rounded-md border border-transparent text-gray-500">
                                        6 Months
                                    </a>
                                </li>
                                <li>
                                    <a className="px-3 py-[0.4rem] text-xs font-semibold rounded-md border border-transparent text-gray-500">
                                        30 Days
                                    </a>
                                </li>
                                <li>
                                    <a className="px-3 py-[0.4rem] text-xs font-semibold rounded-md border border-transparent text-gray-500">
                                        7 Days
                                    </a>
                                </li> */}
                            </ul>

                            {/* <button className="border border-gray-300 px-4 py-[0.4rem] text-xs font-semibold rounded-md">
                                Export PDF
                            </button> */}
                        </div>

                        <div className="mt-8">
                            {salesChartQuery.isLoading ? (
                                <div className="text-center">
                                    <Spinner />
                                </div>
                            ) : (
                                <SalesChart data={salesChartQuery.data} />
                            )}
                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className="card mb-6">
                        <div className="flex justify-between items-start px-6 pt-5">
                            <div>
                                <div className="font-bold">Upcoming Series Departures</div>
                                <p className="mt-2">All upcoming departures for series fare on your account</p>
                            </div>

                            <Link
                                to="/bookings"
                                className="inline-flex items-center gap-2 text-primary text-sm mt-2 font-medium"
                            >
                                See All Departures <ChevronRightIcon />
                            </Link>
                        </div>

                        <div className="mt-6">
                            {upcomingBookingsQuery.isLoading ? (
                                <div className="text-center pb-5">
                                    <Spinner />
                                </div>
                            ) : (
                                <UpcomingDepartureTable bookings={upcomingBookingsQuery.data} />
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-span-12 md:col-span-5">
                    {/* Card 3 */}
                    <div className="card px-6 pt-5 pb-8 mb-6">
                        <div className="flex justify-between items-start">
                            <div className="font-bold">Top Airlines by booking</div>

                            {/* <select className="text-sm px-1 outline-none">
                                <option value="">Last 7 Days</option>
                            </select> */}
                        </div>

                        <div className="mt-8">
                            {topAirlinesQuery.isLoading ? (
                                <div className="text-center">
                                    <Spinner />
                                </div>
                            ) : (
                                <TopAirlineList airlines={topAirlinesQuery.data} />
                            )}
                        </div>
                    </div>

                    {/* Card 4 */}
                    <div className="card mb-6">
                        <div className="px-6 pt-5">
                            <div className="font-bold">Top Sub Agencies</div>
                            <p className="mt-2">Top Sub Agencies by Sale - All time</p>
                        </div>

                        <div className="mt-6 pb-5">
                            {topSubagenciesQuery.isLoading ? (
                                <div className="text-center">
                                    <Spinner />
                                </div>
                            ) : (
                                <>
                                    <TopSubAgencyList subAgencies={topSubagenciesQuery.data} />

                                    <div className="px-6 pt-2">
                                        <Link
                                            to="/sub-agencies"
                                            className="text-gray-500 text-xs font-semibold tracking-wider uppercase"
                                        >
                                            SEE All SUB AGENCIES
                                        </Link>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
