import moment from 'moment-timezone';
import { IAirport } from 'app/types';
import airportTimezone from 'airport-timezone';

// Helper function to get the airport timezone
const getAirportTimezone = (code: string) => {
    return airportTimezone.find((airport: any) => airport.code === code)?.timezone;
};

// Main function to calculate time difference
export const calculateTimeDifferenceInMinutesWithTimeZone = (
    from: { date: string; time: string; airport: IAirport },
    to: { date: string; time: string; airport: IAirport }
) => {
    // Get timezones for the from and to airports
    const fromTimezone = getAirportTimezone(from.airport.code);
    const toTimezone = getAirportTimezone(to.airport.code);

    if (!fromTimezone || !toTimezone) {
        throw new Error('Invalid airport code');
    }

    // Combine date and time for the "from" and "to" times in the respective timezones
    const fromDateTime = `${from.date} ${from.time}`;
    const toDateTime = `${to.date} ${to.time}`;

    // Convert "from" and "to" datetime strings into moment objects, using their respective timezones
    const fromMoment = moment.tz(fromDateTime, 'YYYYMMDD HHmmss', fromTimezone);
    const toMoment = moment.tz(toDateTime, 'YYYYMMDD HHmmss', toTimezone);

    // Calculate the difference in minutes
    const timeDifferenceMinutes = toMoment.diff(fromMoment, 'minutes');

    // Optionally convert to hours and minutes for easier readability
    // const hours = Math.floor(timeDifferenceMinutes / 60);
    // const minutes = timeDifferenceMinutes % 60;

    return timeDifferenceMinutes;
};

// Example usage:
// const from = {
//     date: '20231022',
//     time: '140000',
//     code: 'DXB',
// };

// const to = {
//     date: '20231022',
//     time: '180000',
//     code: 'JFK',
// };
// const timeDifference = calculateTimeDifferenceInMinutes(from, to);
