/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import { FC } from 'react';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { MenuDotIcon } from 'app/icons';
import useDialog from 'app/hooks/use-dialog';
import { formatDate } from 'app/utils/date-utils';
import BackLink from 'app/components/BackLink/BackLink';
import Currency from 'app/components/Currency/Currency';
import { useNavigate, useParams } from 'react-router-dom';
import { formatCurrency } from 'app/utils/currency-utils';
import PageLoader from 'app/components/PageLoader/PageLoader';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import StatusUpdateModal from './components/StatusUpdateModal/StatusUpdateModal';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';

interface SeriesInventoryBookingListProps {}

const SeriesInventoryBookingList: FC<SeriesInventoryBookingListProps> = (props) => {
    const { inventoryId, type } = useParams();
    const statusUpdateDialog = useDialog<string>();
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    let api = '';

    if (type === 'AG') {
        api = `/api/v1/agent/series-tickets/${inventoryId}`;
    } else if (type === 'SAG') {
        api = `/api/v1/agent/agency/series-supplier-ticket/${inventoryId}`;
    } else {
        navigate('/404');
    }

    const query = useQuery(['seriesInventoryTicket', inventoryId], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}${api}`);

        return data;
    });

    const downloadExcelMutation = useMutation(async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/series-tickets/${inventoryId}/airline-excel`,
            {
                responseType: 'blob',
            }
        );

        return data;
    });

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'yellow';
            case 'COMPLETED':
            case 'SUBMITTED':
                return 'green';
            case 'CANCELLED':
                return 'red';
        }
    };

    const getStatusText = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'Naming Pending';
            case 'COMPLETED':
                return 'Completed';
            case 'SUBMITTED':
                return 'Submitted';
            case 'CANCELLED':
                return 'Cancelled';
        }
    };

    const handleDownloadExcel = async () => {
        try {
            const res = await downloadExcelMutation.mutateAsync();

            const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
            const link = document.createElement('a');

            link.setAttribute('href', url);
            link.setAttribute('download', `${inventoryId}-airline-excel.xlsx`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            window.URL.revokeObjectURL(url);
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const updateStatusMutation = useMutation(async () => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/series-tickets/${inventoryId}/submit`
        );

        return data;
    });

    const handleUpdateStatus = async () => {
        try {
            await updateStatusMutation.mutateAsync();

            toast.success('Status updated successfully.');

            // queryClient.setQueryData(['agencyDetail'], (prev: any) => {
            //     return update(prev, {
            //         status: 'SUBMITTED',
            //     });
            // });

            queryClient.invalidateQueries({ queryKey: ['seriesInventoryTicket', inventoryId] });
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        } finally {
            statusUpdateDialog.hide();
        }
    };

    if (query.isLoading) return <PageLoader />;

    const ticket = query.data;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card py-5">
                <div className="flex items-center px-6">
                    <div className="font-bold">Bookings - {ticket._id}</div>
                    <div className="flex items-center gap-6 ml-auto">
                        <StatusBadge className="mt-1" color={getStatusClass(ticket.status)}>
                            {getStatusText(ticket.status)}
                        </StatusBadge>
                        {type === 'AG' && (
                            <>
                                <button
                                    disabled={ticket.status === 'SUBMITTED'}
                                    onClick={() => statusUpdateDialog.show('put id here')}
                                    className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                                >
                                    Mark Submitted
                                </button>
                                <LoaderButton
                                    className="bg-[#1D6F42] text-white text-sm font-semibold px-6 rounded-lg text-center w-56"
                                    onClick={handleDownloadExcel}
                                    isLoading={downloadExcelMutation.isLoading}
                                >
                                    Download Excel for Airline
                                </LoaderButton>
                            </>
                        )}
                    </div>
                </div>

                <div className="px-6 mt-10">
                    <div className="grid grid-cols-4 gap-6">
                        <div className="col-span-1">
                            <div className="font-semibold">
                                {ticket.trip.departure.airport.city} to {ticket.trip.arrival.airport.city}
                            </div>
                            <p className="mt-1">{formatDate(ticket.trip.departure.date, 'DD MMM, YYYY')}</p>
                        </div>
                        <div className="col-span-1">
                            <div className="font-semibold">
                                {ticket.bookingCount} / {ticket.totalSeats} Seats Sold
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="flex items-center gap-3">
                                <img className="w-9 h-9" crossOrigin="anonymous" src={ticket.flight.logo} alt="" />
                                <div>
                                    <div className="font-semibold">{ticket.flight.name}</div>
                                    <p className="mt-1">
                                        {ticket.flight.code} {ticket.flight.number}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="font-semibold">{ticket.pnr}</div>
                        </div>
                    </div>
                </div>

                <div className="mt-10">
                    <div className="overflow-auto">
                        <table className="card-table text-sm">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="th-1">Trip ID</th>
                                    <th className="th-1">Traveller(s)</th>
                                    <th className="th-1">Booked at Fare</th>
                                    <th className="th-1">Booked By</th>
                                    <th className="th-1">Created At</th>
                                    <th className="th-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {ticket.bookings.length > 0 ? (
                                    ticket.bookings.map((row: any, i: number) => (
                                        <tr key={i} className="border-b border-gray-200">
                                            <td className="td-1">
                                                <div
                                                    className="font-semibold text-blue-500 cursor-pointer"
                                                    onClick={() => navigate(`/bookings/${row.booking._id}/${type}`)}
                                                >
                                                    {row.booking._id}
                                                </div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">
                                                    {row.title} {row.firstName} {row.lastName}
                                                </div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold"> {formatCurrency(row.amountPaid)}</div>
                                                <p className="mt-1">{'Total'}</p>
                                            </td>
                                            <td className="td-1">
                                                <div
                                                    className="font-semibold text-blue-500 cursor-pointer"
                                                    onClick={() =>
                                                        navigate(`/sub-agencies/${row.booking.subAgency._id}/details`)
                                                    }
                                                >
                                                    {row.booking.subAgency.name}
                                                </div>
                                            </td>
                                            <td className="td-1">
                                                <div className="min-w-max">
                                                    <p>
                                                        {moment(row.booking.createdAt, 'YYYYMMDDHHmmss').format(
                                                            'DD MMM, YYYY'
                                                        )}
                                                    </p>
                                                    <p>
                                                        {moment(row.booking.createdAt, 'YYYYMMDDHHmmss').format(
                                                            'hh:mm A'
                                                        )}
                                                    </p>
                                                </div>
                                            </td>
                                            <td className="td-1">
                                                <Dropdown
                                                    target={
                                                        <a className="dropdown-action">
                                                            <MenuDotIcon />
                                                        </a>
                                                    }
                                                    menu={
                                                        <DropdownList>
                                                            <DropdownItem to={`/bookings/${row.booking._id}/${type}`}>
                                                                View Ticket
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    window.open(
                                                                        `/bookings/${row.booking._id}/invoice/${type}`
                                                                    )
                                                                }
                                                            >
                                                                Invoice
                                                            </DropdownItem>
                                                        </DropdownList>
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="td-1" colSpan={4}>
                                            <p className="text-center">No bookings yet</p>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <table className="card-table text-sm mt-6">
                            <thead>
                                <tr>
                                    <th className="th-1">TOTAL COST PRICE OF THE TICKET</th>
                                    <th className="th-1">TOTAL SALE PRICE</th>
                                    <th className="th-1">ESTIMATED PROFIT / LOSS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="td-1 text-lg">
                                        <div className="font-semibold">{formatCurrency(ticket.totalfare)}</div>
                                    </td>
                                    <td className="td-1 text-lg">
                                        <div className="font-semibold">{formatCurrency(ticket.selling.price)}</div>
                                    </td>
                                    <td className="td-1 text-lg">
                                        <div className="font-semibold">
                                            <Currency>{ticket.selling.earnings}</Currency>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <StatusUpdateModal
                    show={statusUpdateDialog.isOpen}
                    message={'Are you sure that you want to mark this ticket as submitted'}
                    onClose={statusUpdateDialog.hide}
                    onConfirm={handleUpdateStatus}
                    isLoading={updateStatusMutation.isLoading}
                />
            </div>
        </>
    );
};

export default SeriesInventoryBookingList;
