import React, { FC } from 'react';

interface IPageTitle {
    title: string;
}

const PageTitle: FC<IPageTitle> = (props) => {
    return <h5 className="font-bold mb-8">{props.title}</h5>;
};

export default PageTitle;
