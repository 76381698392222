import { Dispatch, FC } from 'react';

interface ITab {
    label: string;
    sign: string;
}

interface ITabButton {
    tab: ITab;
}

const tabMenu: Array<ITab> = [
    { label: 'Agency Profile', sign: 'profile' },
    { label: 'Bank Accounts', sign: 'bank' },
    { label: 'Global Config', sign: 'config' },
];

interface IAgencySettingsNav {
    activeTab: string;
    setActiveTab: Dispatch<React.SetStateAction<string>>;
}

const AgencySettingsNav: FC<IAgencySettingsNav> = (props) => {
    const { activeTab, setActiveTab } = props;

    const TabButton: FC<ITabButton> = (props) => {
        const { tab } = props;

        return (
            <li className="tab-item">
                <button
                    className={`mr-14 cursor-pointer ${activeTab === tab.sign ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab(tab.sign)}
                >
                    {tab.label}
                </button>
            </li>
        );
    };

    return (
        <ul className="flex text-base font-normal px-6 pt-6 pb-2">
            {tabMenu.map((tab) => (
                <TabButton tab={tab} key={tab.sign} />
            ))}

            {/* <li className="tab-item">
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '1' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('1')}
                >
                    All Bookings
                </a>
            </li>
            <li className="tab-item">
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '2' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('2')}
                >
                    Agency Bookings
                </a>
            </li>
            <li className="tab-item">
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '3' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('3')}
                >
                    Sub Agency Series Bookings
                </a>
            </li>
            <li className="tab-item">
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '4' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('4')}
                >
                    Manual Bookings
                </a>
            </li> */}
        </ul>
    );
};

export default AgencySettingsNav;
