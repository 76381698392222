import { FC } from 'react';
import { IBank } from 'app/types';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { MenuDotIcon } from 'app/icons';
import update from 'immutability-helper';
import useDialog from 'app/hooks/use-dialog';
import BankAddModal from '../BankAddModal/BankAddModal';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';

interface BankProps {
    banks: IBank[];
    subAgencyId: string;
}

const Bank: FC<BankProps> = (props) => {
    const { banks, subAgencyId } = props;

    const deleteDialog = useDialog<string>();
    const queryClient = useQueryClient();
    const bankAddDialog = useDialog();

    const deleteMutation = useMutation(async (values: any) => {
        const { data } = await http.delete(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/banks/${values._id}`
        );

        return data;
    });

    const handleDeleteBank = async () => {
        try {
            const _id = deleteDialog.data;

            await deleteMutation.mutateAsync({ _id });

            toast.success('Bank deleted successfully.');

            const index = banks.findIndex((b) => b._id === _id);

            queryClient.setQueryData(['subAgency', subAgencyId], (prev: any) => {
                return update(prev, {
                    banks: {
                        $splice: [[index, 1]],
                    },
                });
            });
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        } finally {
            deleteDialog.hide();
        }
    };

    return (
        <>
            <div className="overflow-auto mt-6">
                <div className="flex justify-end px-3">
                    <button
                        onClick={bankAddDialog.show}
                        className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                    >
                        Add Bank
                    </button>
                </div>
                <div className="mt-6">
                    {banks.length > 0 ? (
                        <table className="card-table text-sm">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="th-1">Bank Name</th>
                                    <th className="th-1">Account Number</th>
                                    <th className="th-1">IFSC</th>
                                    <th className="th-1">Account Type</th>
                                    <th className="th-1">Account Name</th>
                                    <th className="th-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {banks.map((row) => (
                                    <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                        <td className="td-1">{row.name}</td>
                                        <td className="td-1">{row.account.number}</td>
                                        <td className="td-1">{row.ifsc}</td>
                                        <td className="td-1">{row.account.type}</td>
                                        <td className="td-1">{row.account.holderName}</td>
                                        <td className="td-1">
                                            <Dropdown
                                                target={
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a className="dropdown-action">
                                                        <MenuDotIcon />
                                                    </a>
                                                }
                                                menu={
                                                    <DropdownList>
                                                        <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                                                            <span className="text-red-500">Delete</span>
                                                        </DropdownItem>
                                                    </DropdownList>
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="flex justify-center pb-4">
                            <p className="px-10 w-[480px] text-center max-w-full">No Banks added yet</p>
                        </div>
                    )}
                </div>
            </div>
            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this bank?'}
                onClose={deleteDialog.hide}
                onConfirm={handleDeleteBank}
                isLoading={deleteMutation.isLoading}
            />
            <BankAddModal show={bankAddDialog.isOpen} onClose={bankAddDialog.hide} subAgencyId={subAgencyId} />
        </>
    );
};

export default Bank;
