import http from 'app/config/http';
import { ISubAgency } from 'app/types';

export const getSubAgencies = async (query: string) => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies`, {
        params: {
            searchParam: query,
        },
    });
    return data.data as ISubAgency[];
};

export const getSubAgencyById = async (id: string) => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${id}`);
    return data as ISubAgency;
};

