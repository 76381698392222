import React from 'react';
import { IMtcDetails } from '../shared/types/mtc';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import PageLoader from 'app/components/PageLoader/PageLoader';
import BackLink from 'app/components/BackLink/BackLink';
import { IMarkUpHistory } from '../shared/types/mark-up-history';
import { Status } from 'app/enums/status.enum';
import FareSupplierDefaultCommissionPlan from '../shared/components/FareSupplierDefaultCommissionPlan/FareSupplierDefaultCommissionPlan';

import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';
import FareRuleForm from 'app/components/FareRuleForm/FareRuleForm';
import MtcCredentialForm from '../shared/components/MtcCredentialForm/MtcCredentialForm';
import FareIdentifierMappingForm from '../shared/components/FareIdentifierMappingForm/FareIdentifierMappingForm';
import FareSupplierActivationToggle from '../shared/components/FareSupplierActivationToggle/FareSupplierActivationToggle';
import ContactDetailsMasking from '../shared/components/ContactDetailsMasking/ContactDetailsMasking';

const FareSupplierMtc = () => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
        fareSupplier,
        fareSupplierLabel,
        fareSupplierUrlKey,
        isUpdatingFareSupplierStatus,
    } = useFareSupplierStatus<IMtcDetails>({
        fareIdentifier: FareSupplierEnum.MTC,
    });

    if (isLoading) return <PageLoader />;

    const data: IMtcDetails = isError
        ? {
              auth_channel: '',
              token: '',
              token_id: '',
              username: '',
              password: '',
              markup: 0,
              markupHistory: [] as IMarkUpHistory[],
              fareRules: [] as string[],
              baseUrl: '',
              status: Status.DISABLED,
              maskContactDetails: {
                  email: '',
                  isEnabled: false,
                  mobileNumber: '',
              },
          }
        : queryData;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>
            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">MTC Details</div>
                    <FareSupplierActivationToggle
                        checked={data.status}
                        handleToggleStatus={handleToggleStatus}
                        fareSupplierLabel={fareSupplierLabel}
                        isLoading={isUpdatingFareSupplierStatus}
                    />
                </div>
                <MtcCredentialForm
                    auth_channel={data.auth_channel}
                    baseUrl={data.baseUrl}
                    password={data.password}
                    // token_id={data.token_id}
                    token={data.token}
                    username={data.username}
                />
            </div>
            <FareSupplierDefaultCommissionPlan fareSupplier={fareSupplier} fareSupplierId={fareSupplierUrlKey} />

            <FareIdentifierMappingForm fareSupplier={fareSupplierUrlKey} />

            <ContactDetailsMasking
                fareSupplier={fareSupplierUrlKey}
                maskContactDetails={data.maskContactDetails}
                refetchQueryIdentifier={queryIdentifier}
            />

            <FareSupplierAirlineFilter fareSupplier={fareSupplierUrlKey} />

            {/* <div className="card pt-5 mt-8 pb-10">
                <div className="px-6 font-bold">Fare Identifier</div>

                <div className="px-6 mt-8">
                    <AirIqFareIdentifierForm fareIdentifier={data.fareIdentifier} />
                </div>
            </div> */}

            {!isError && (
                <>
                    <FareRuleForm
                        fareRules={data.fareRules}
                        fareSupplier={'mtc'}
                        refetchQueryIdentifier={queryIdentifier}
                    />
                </>
            )}
        </>
    );
};

export default FareSupplierMtc;
