import * as Yup from 'yup';
import moment from 'moment';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { CrossIcon, PlusIcon } from 'app/icons';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { SYSTEM_DATE_TIME_FORMAT } from 'app/utils/date-utils';
import { IBookingRemark } from '../../types/booking-remark.type';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addRemarkToBooking } from 'app/api/services/booking.service';

interface IBookingRemarksCard {
    bookingId: string;
    remarks: Array<IBookingRemark>;
}

enum remarkStateEnum {
    VIEW_REMARK = 'VIEW_REMARK',
    ADD_REMARK = 'ADD_REMARK',
}

const BookingRemarksCard: FC<IBookingRemarksCard> = (props) => {
    const { bookingId, remarks } = props;
    const queryClient = useQueryClient();
    const [view, setView] = useState<remarkStateEnum>(remarkStateEnum.VIEW_REMARK);

    const createBookingRemarkMutation = useMutation((remark: string) =>
        addRemarkToBooking({ bookingId: bookingId, remark })
    );

    return (
        <div className="card px-6 pt-5 pb-6 mt-4 h-80  overflow-hidden">
            <div className="flex justify-between items-center">
                <div className="font-bold">Remarks</div>
                {view === remarkStateEnum.VIEW_REMARK && (
                    <div
                        className="cursor-pointer bg-primary text-white p-2 rounded-full"
                        onClick={() => setView(remarkStateEnum.ADD_REMARK)}
                    >
                        <PlusIcon height={12} width={12} />
                    </div>
                )}

                {view === remarkStateEnum.ADD_REMARK && (
                    <div
                        className="cursor-pointer bg-primary text-white p-2 rounded-full"
                        onClick={() => setView(remarkStateEnum.VIEW_REMARK)}
                    >
                        <CrossIcon height={12} width={12} />
                    </div>
                )}
            </div>

            <div className="mt-6">
                {view === remarkStateEnum.VIEW_REMARK && (
                    <>
                        {remarks.length === 0 ? (
                            <div className="text-center text-sm font-medium">No remarks added</div>
                        ) : (
                            <ul className="h-60 overflow-scroll pb-6 pr-3">
                                {remarks.map((remark) => (
                                    <li className="mb-2 border-b py-2 first:pt-0 last:pb-0 last:border-none">
                                        <div className="flex flex-col justify-start">
                                            <div className="text-base font-medium">{remark.remark}</div>

                                            <div className="flex gap-2 text-primary mt-[5px]">
                                                <div className="text-xs font-semibold">{remark.agencyUser.name}</div>
                                                <div className="text-xs font-semibold">
                                                    {moment(remark.createdAt, SYSTEM_DATE_TIME_FORMAT).format(
                                                        'hh:mm A - DD MMM, YYYY'
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}{' '}
                    </>
                )}

                {view === remarkStateEnum.ADD_REMARK && (
                    <div className="min-h-[144px]">
                        <Formik
                            initialValues={{ remark: '' }}
                            onSubmit={async ({ remark }) => {
                                try {
                                    await createBookingRemarkMutation.mutateAsync(remark);
                                    toast.success('Remark added to booking');
                                    queryClient.fetchQuery(['bookingDetail', bookingId]);
                                    setView(remarkStateEnum.VIEW_REMARK);
                                } catch (e: any) {
                                    toast.error(e?.data?.message || 'Something went wrong');
                                }
                            }}
                            validationSchema={Yup.object({
                                remark: Yup.string().trim().required('This field is required'),
                            })}
                        >
                            <Form>
                                <label className="text-xs mb-2" htmlFor="remark">
                                    Remark
                                </label>

                                <Field
                                    as="textarea"
                                    id="remark"
                                    name="remark"
                                    placeholder="Add Remark"
                                    rows={3}
                                    className="form-control"
                                />

                                <div className="form-error">
                                    <ErrorMessage name="remark" />
                                </div>

                                <LoaderButton
                                    type="submit"
                                    className="bg-primary text-white w-full px-4 py-6 rounded-lg mt-6"
                                    isLoading={createBookingRemarkMutation.isLoading}
                                >
                                    Save
                                </LoaderButton>
                            </Form>
                        </Formik>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BookingRemarksCard;
