/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IBooking, IBookingPassenger } from 'app/types';
import { formatDate } from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { Dropdown, DropdownList } from 'app/components/Dropdown/Dropdown';
import { MenuDotIcon, ReloadIcon } from 'app/icons';
import { DropdownItem } from 'app/components/Dropdown/Dropdown';
import Tooltip from 'app/components/Tooltip/Tooltip';
import moment from 'moment';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Spinner from 'app/components/Spinner/Spinner';
import ReactDOM from 'react-dom';
import { getFareSupplier, getStatusClass, getStatusText } from 'app/utils/flight-utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import useDialog from 'app/hooks/use-dialog';
import Modal from 'app/components/Modal/Modal';
import { IBookingWithListedBySuyAgency } from '../AllBooking/AllBooking';

interface BookingTableProps {
    bookings: IBooking[];
    type: 'AG' | 'SAG';
    isLoading?: boolean;
}

export const getFirstPassengerName = (booking: IBooking) => {
    const firstPassenger = (booking?.passengers as unknown as IBookingPassenger[])[0] || booking.passengers.adults[0];
    return `${firstPassenger.title} ${firstPassenger.firstName} ${firstPassenger.lastName}`;
};

export const getPassengerName = (passenger: IBookingPassenger | IBookingWithListedBySuyAgency) => {
    return `${passenger.title} ${passenger.firstName} ${passenger.lastName}`;
};

const BookingTable: FC<BookingTableProps> = (props) => {
    const { bookings, type, isLoading } = props;
    const navigate = useNavigate();
    const tableTop = useRef();
    const controllerStatusUpdateModal = useDialog<IBooking>();
    const showSupplierStatusOption = useSecretVisibility();

    const [searchParams] = useSearchParams();

    const generateQueryIdentifier = () => {
        let fromDate = searchParams.get('fromDate') || '';
        let toDate = searchParams.get('toDate') || '';
        let query = searchParams.get('query') || '';
        let page = searchParams.get('page') || 1;
        let bookingStatus = searchParams.get('bookingStatus') || '';

        return [
            ['agencyBookingListPaginated', query, page, fromDate, toDate, bookingStatus, 'All bookings'],
            ['agencyBookingListPaginated', query, page, fromDate, toDate, bookingStatus],
        ];
    };

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const handleNavigate = (booking: IBooking) => {
        if (type === 'AG' && booking.fareSupplier === '2') {
            navigate(`/bookings/${booking._id}/SAG`);
            return;
        }
        if (type === 'AG') return navigate(`/bookings/${booking._id}/AG`);
        if (type === 'SAG') return navigate(`/bookings/${booking._id}/SAG`);
    };

    interface ICreateLabel {
        fareSupplier: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '100';
    }

    const CreateLabel: FC<ICreateLabel> = (props) => {
        const { fareSupplier } = props;

        if (fareSupplier === '0') return <span>{getFareSupplier(fareSupplier)}</span>;
        if (fareSupplier === '1')
            return <img className="h-6" src="/icons/fare-supplier/fs_trip-jack.svg" alt="Trip Jack" />;
        if (fareSupplier === '2') return <span>{getFareSupplier(fareSupplier)}</span>;
        if (fareSupplier === '3') return <img className="h-6" src="/icons/fare-supplier/fs_airiq.svg" alt="Air IQ" />;
        if (fareSupplier === '4')
            return <img className="h-6" src="/icons/fare-supplier/fs_travelboutique-logo.svg" alt="" />;
        if (fareSupplier === '5') return <img className="h-6" src="/icons/fare-supplier/fs_kafila.png" alt="kafila" />;
        if (fareSupplier === '6')
            return <img className="h-6" src="/icons/fare-supplier/fs_fare-boutique.png" alt="Fare Boutique" />;
        if (fareSupplier === '100') return <span>Manual Booking</span>;
        return <span>-</span>;
    };

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50" ref={tableTop}>
                        <th className="th-1">Trip ID</th>
                        {type === 'AG' && <th className="th-1">FS Booking ID</th>}
                        <th className="th-1">Travel & Date</th>
                        <th className="th-1">Traveller(s)</th>
                        <th className="th-1">Flight Details</th>
                        <th className="th-1">Fare Details</th>
                        <th className="th-1">{type === 'AG' ? 'Provider' : 'Listed By'}</th>
                        <th className="th-1">Booked By</th>
                        {type === 'AG' && <th className="th-1">Created At</th>}
                        <th className="th-1"></th>
                    </tr>
                </thead>
                <tbody className="">
                    {!isLoading && bookings.length === 0 && (
                        <tr>
                            <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                - No data available -
                            </td>
                        </tr>
                    )}

                    {!isLoading &&
                        bookings.map((row) => (
                            <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                <td className="td-1">
                                    <div
                                        className="font-semibold text-blue-500 !cursor-pointer"
                                        onClick={() => handleNavigate(row)}
                                    >
                                        {row._id}
                                    </div>
                                    <StatusBadge className="mt-1" color={getStatusClass(row.status)}>
                                        {getStatusText(row.status)}
                                    </StatusBadge>
                                </td>
                                {type === 'AG' && (
                                    <td className="td-1 overflow-hidden">
                                        <div className="w-40 overflow-hidden font-semibold overflow-ellipsis ">
                                            {' '}
                                            {row.bookingId}
                                        </div>
                                        {row.fareSupplier === '1' && showSupplierStatusOption && (
                                            <SupplierStatus bookingId={row._id} />
                                        )}
                                    </td>
                                )}
                                <td className="td-1">
                                    <div className="font-semibold  text-nowrap">
                                        {row.trip.departure.airport.city} to {row.trip.arrival.airport.city}{' '}
                                    </div>
                                    <p className="mt-1">{formatDate(row.trip.departure.date, 'DD MMM, YYYY')}</p>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold text-nowrap">{getFirstPassengerName(row)}</div>
                                    {(row.passengers as unknown as IBookingPassenger[]).length > 1 && (
                                        <Tooltip
                                            content={
                                                <div className="px-6 py-4 w-48">
                                                    <ul className="space-y-4">
                                                        {(row.passengers as unknown as IBookingPassenger[]).map(
                                                            (row, i) => (
                                                                <li className="text-sm text-gray-500" key={i}>
                                                                    {getPassengerName(row)}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            }
                                            placement="bottom-start"
                                            triggers={['click']}
                                        >
                                            <p className="mt-1">
                                                + {(row.passengers as unknown as IBookingPassenger[]).length - 1}{' '}
                                                Passengers
                                            </p>
                                        </Tooltip>
                                    )}
                                </td>
                                <td className="td-1">
                                    <div className="flex items-center gap-3 min-w-max">
                                        <img className="w-9 h-9" crossOrigin="anonymous" src={row.flight.logo} alt="" />
                                        <div>
                                            <div className="font-semibold">{row.flight.name}</div>
                                            <p className="mt-1">
                                                {row.flight.code} {row.flight.number}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold text-nowrap">
                                        {formatCurrency(row.payment.amount)}
                                    </div>
                                    <p className="mt-1">Total</p>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold text-nowrap">
                                        {type === 'AG' ? (
                                            row.fareSupplier === '2' ? (
                                                <Link
                                                    to={`/sub-agencies/${row?.listedBy?._id}/details`}
                                                    className="text-primary"
                                                >
                                                    {' '}
                                                    {row.listedBy.name}{' '}
                                                </Link>
                                            ) : (
                                                <CreateLabel
                                                    fareSupplier={
                                                        row.fareSupplier as '0' | '1' | '2' | '3' | '4' | '5' | '6'
                                                    }
                                                />
                                            )
                                        ) : (
                                            <Link
                                                to={`/sub-agencies/${row?.listedBy?._id}/details`}
                                                className="text text-primary cursor-pointer"
                                            >
                                                {row?.listedBy?.name}
                                            </Link>
                                        )}
                                    </div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold text-nowrap">
                                        <Link
                                            to={`/sub-agencies/${row?.bookedBy?._id}/details`}
                                            className="text-primary cursor-pointer"
                                        >
                                            {row.bookedBy.name}
                                        </Link>
                                    </div>
                                    {row?.bookedByUser?.name ? <p className="mt-1">{row.bookedByUser.name} </p> : ''}
                                </td>
                                {type === 'AG' && (
                                    <td className="td-1">
                                        <div className="min-w-max">
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                        </div>
                                    </td>
                                )}
                                <td className="td-1">
                                    <Dropdown
                                        target={
                                            <a className="dropdown-action">
                                                <MenuDotIcon />{' '}
                                            </a>
                                        }
                                        menu={
                                            <DropdownList>
                                                <DropdownItem to={`/bookings/${row._id}/${type}`}>
                                                    View Ticket
                                                </DropdownItem>
                                                <DropdownItem
                                                    onClick={() => window.open(`/bookings/${row._id}/invoice/${type}`)}
                                                >
                                                    Invoice
                                                </DropdownItem>
                                                {type === 'AG' ? null : (
                                                    <DropdownItem
                                                        to={`/series-inventory/${row.ticketId}/bookings/${type}`}
                                                    >
                                                        View Series Ticket
                                                    </DropdownItem>
                                                )}
                                                {row.status === 'IN_PROCESS' && (
                                                    <DropdownItem onClick={() => controllerStatusUpdateModal.show(row)}>
                                                        Update Status
                                                    </DropdownItem>
                                                )}
                                            </DropdownList>
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {isLoading && (
                <div className="flex justify-center items-center min-h-[500px]">
                    <Spinner />
                </div>
            )}
            {controllerStatusUpdateModal.isOpen && (
                <StatusUpdateModal
                    booking={controllerStatusUpdateModal.data}
                    show={controllerStatusUpdateModal.isOpen}
                    onClose={controllerStatusUpdateModal.hide}
                    identifier={generateQueryIdentifier()}
                />
            )}
        </div>
    );
};

BookingTable.defaultProps = {
    isLoading: false,
};

export default BookingTable;

interface ISupplierStatus {
    bookingId: string;
}

const SupplierStatus: FC<ISupplierStatus> = (props) => {
    const [res, setRes] = useState<any>(null);
    const supplierStatusMutation = useMutation(async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/fare-supplier-status`
        );

        return data;
    });

    const handleGetSupplierStatus = async () => {
        try {
            setRes(null);
            const response = await supplierStatusMutation.mutateAsync();
            setRes(response);
        } catch (e: any) {
            toast.error('Something went Wrong');
            console.log(e);
        }
    };

    const { bookingId } = props;
    return (
        <div>
            {res ? (
                <>
                    <div className="flex flex-col">
                        <div>{JSON.stringify(res)} </div>
                        <div className="text-blue-500 font-bold cursor-pointer" onClick={handleGetSupplierStatus}>
                            Refetch
                        </div>
                    </div>
                </>
            ) : supplierStatusMutation.isLoading ? (
                <Spinner size={12} />
            ) : (
                <div onClick={handleGetSupplierStatus} className="text-blue-500 cursor-pointer">
                    fetch supplier status
                </div>
            )}
        </div>
    );
};

const useSecretVisibility = (): boolean => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.shiftKey && event.key === 'Q') {
                window.addEventListener('keydown', handleSecondKeyDown);
            }
        };

        const handleSecondKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'P') {
                setIsVisible(true);
            }
            window.removeEventListener('keydown', handleSecondKeyDown);
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keydown', handleSecondKeyDown);
        };
    }, []);

    return isVisible;
};

interface IStatusUpdateModal {
    booking: IBooking;
    show: boolean;
    onClose: () => void;
    identifier: any[];
}

export const StatusUpdateModal: FC<IStatusUpdateModal> = (props) => {
    const { booking, show, onClose, identifier } = props;
    const [selectedStatus, setSelectedStatus] = useState('');
    const queryClient = useQueryClient();

    const updateInProcessStatusMutation = useMutation(async (route: string) => {
        await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${booking._id}/${route}`);
    });

    const handleSubmit = async () => {
        if (selectedStatus === '') return;
        const api = selectedStatus === 'COMPLETED' ? 'complete' : 'cancel';
        try {
            await updateInProcessStatusMutation.mutateAsync(api);
            toast.success('Booking status updated successfully.');
            onClose();

            identifier.forEach((identity) => {
                queryClient.fetchQuery(identity);
            });
        } catch (e: any) {
            toast.error('Something went wrong while updating status of booking.');
        }
    };

    return (
        <Modal show={show} onClose={onClose} className='px-6 py-8'>
            <div className='font-bold '> Update Booking Status</div>
            <div className='flex items-start gap-4 mt-6 font-normal text-sm'>
                <div>
                    <div className='font-semibold'>{booking._id}</div>
                    <StatusBadge className='mt-1' color={getStatusClass(booking.status)}>
                        {getStatusText(booking.status)}
                    </StatusBadge>
                </div>

                <div className='flex items-center gap-3 min-w-max'>
                    <img
                        className='w-9 h-9'
                        crossOrigin='anonymous'
                        src={booking?.flight?.logo || booking.departure.flight.logo}
                        alt=''
                    />
                    <div>
                        <div className='font-semibold'>{booking?.flight?.name || booking.departure.flight.name}</div>
                        <p className='mt-1'>
                            {booking?.flight?.code || booking.departure.flight.code}{' '}
                            {booking?.flight?.number || booking.departure.flight.number}
                        </p>
                    </div>
                </div>
                <div>
                    <div className='font-semibold  text-nowrap'>
                        {booking?.trip?.departure?.airport?.city || booking.departure.airport.city} to{' '}
                        {booking?.trip?.arrival?.airport?.city || booking.arrival.airport.city}
                    </div>
                    <p className='mt-1'>
                        {booking?.trip?.departure?.date && formatDate(booking.trip.departure.date, 'DD MMM, YYYY')}
                        {booking?.departure?.date && formatDate(booking.departure.date, 'DD MMM, YYYY')}
                    </p>
                </div>
                <div>
                    <div className='font-semibold text-nowrap'>{getFirstPassengerName(booking)}</div>
                    {(booking.passengers as unknown as IBookingPassenger[]).length > 1 && (
                        <p className='mt-1'>
                            + {(booking.passengers as unknown as IBookingPassenger[]).length - 1} Passengers
                        </p>
                    )}
                </div>
                <div>
                    <select
                        id='filter'
                        className='border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium '
                        value={selectedStatus}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedStatus(e.currentTarget.value)}
                    >
                        <option value=''>Processing</option>
                        <option value='COMPLETED'>Completed</option>
                        <option value='CANCELLED'>Cancelled</option>
                    </select>
                </div>
            </div>
            <div className='mt-12'>
                <button
                    disabled={selectedStatus === '' || updateInProcessStatusMutation.isLoading}
                    onClick={handleSubmit}
                    className='text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-40'
                >
                    Update
                </button>
            </div>
        </Modal>
    );
};








