import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { getCommissionPlanById } from 'app/api/services/fare-commission.service';

const CommissionPlanView = () => {
    const { id } = useParams();
    const { isLoading, data } = useQuery(['COMMISSiON_PLAN', id], () => getCommissionPlanById(id));

    if (isLoading) return <PageLoader />;

    return (
        <div>
            <div className="mb-5">
                <BackLink />
            </div>
            <h4 className="font-bold">Commission Plan Details</h4>
            <div className="card mt-8 px-6 py-6">
                <div className="grid grid-cols-12 gap-6 mt-6">
                    <div className="col-span-8">
                        <label className="text-xs mb-2" htmlFor="name">
                            Plan Name
                        </label>
                        <div className="font-semibold py-2 flex gap-2">{data.name}</div>
                    </div>
                    <div className="col-span-8">
                        <label className="text-xs mb-2" htmlFor="commissionFareSupplier">
                            Fare Supplier
                        </label>
                        <div className="font-semibold py-2 flex gap-2">
                            {createReadableFareSupplierLabel(data.commissionFareSupplier as FareSupplierEnum)}
                        </div>
                    </div>

                    <div className="col-span-8">
                        <label className="text-xs mb-2" htmlFor="managementChargesPerPassenger">
                            Management Charges (Per Seat)
                        </label>
                        <div className="font-semibold py-2 flex gap-2">
                            ₹ &nbsp; {data.managementChargesPerPassenger}
                        </div>
                    </div>
                    <div className="col-span-8">
                        <label
                            className="text-xs mb-2"
                            htmlFor="zeroCommissionConditionalManagementChargesPerPassenger"
                        >
                            Management Charges (Per Seat) When Net Commission Is Zero
                        </label>
                        <div className="font-semibold py-2 flex gap-2">
                            ₹ &nbsp; {data.zeroCommissionConditionalManagementChargesPerPassenger}
                        </div>
                    </div>
                    <div className="col-span-4" />

                    <div className="mt-auto col-span-2 flex items-center">
                        <div className="mb-3 text-base font-medium">Commission</div>
                    </div>
                    <div className="col-span-2">
                        <label className="text-xs mb-2" htmlFor="">
                            Flat (Per Seat)
                        </label>
                        <div className="">
                            <div className="font-semibold py-2 flex gap-2">
                                ₹ &nbsp;
                                {data.commissionBreakdown.netCommission.flat}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3">
                        <label className="text-xs mb-2" htmlFor="">
                            Share Net Commission With Sub Agent (%)
                        </label>
                        <div className="">
                            <div className="font-semibold py-2 flex gap-2">
                                {data.commissionBreakdown.netCommission.percentage} %
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-2">
                    <Link
                        className="py-3 bg-primary flex items-center justify-center text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4"
                        to={`/fare-management/commission-plan/${id}/edit`}
                    >
                        Edit
                    </Link>

                    <Link
                        className="bg-primary flex items-center justify-center text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4"
                        to={`/fare-management/commission-plan/${id}/associate`}
                    >
                        Associate
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CommissionPlanView;
