import BackLink from 'app/components/BackLink/BackLink';

import MarkupControlForm from 'app/components/MarkupControlForm/MarkupControlForm';
import PageLoader from 'app/components/PageLoader/PageLoader';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { mapFareSupplierStatusToBoolean } from '../shared/utils';
import { Status } from 'app/enums/status.enum';
import { IMarkUpHistory } from '../shared/types/mark-up-history';
import FareBoutiqueCredentialsForm from '../shared/components/FareBoutiqueCredentialForm/FareBoutiqueCredentialsForm';
import { IFareBoutiqueDetails } from '../shared/types/fare-boutique';
import { FareSupplierIdEnum } from 'app/enums/FareSupplierIdEnum.enum';
import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';
import FareSupplierDefaultCommissionPlan from '../shared/components/FareSupplierDefaultCommissionPlan/FareSupplierDefaultCommissionPlan';
import FareRuleForm from 'app/components/FareRuleForm/FareRuleForm';

const FareSupplierFareBoutique = () => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
    } = useFareSupplierStatus<IFareBoutiqueDetails>({
        fareIdentifier: FareSupplierEnum.FARE_BOUTIQUE,
    });

    if (isLoading) return <PageLoader />;

    const data: IFareBoutiqueDetails = isError
        ? {
              partnerUserId: '',
              token: '',
              markup: 0,
              fareRules: [] as string[],
              markupHistory: [] as IMarkUpHistory[],
              status: Status.DISABLED,
              baseUrl: '',
          }
        : queryData;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">Fare Boutique Details</div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-switch success"
                            checked={mapFareSupplierStatusToBoolean(data.status)}
                            onChange={(e) => handleToggleStatus(e.target.checked)}
                        />
                    </div>
                </div>

                <FareBoutiqueCredentialsForm
                    baseUrl={data.baseUrl}
                    partnerUserId={data.partnerUserId}
                    token={data.token}
                />
            </div>

            <FareSupplierDefaultCommissionPlan
                fareSupplier={FareSupplierEnum.FARE_BOUTIQUE}
                fareSupplierId={FareSupplierIdEnum.FARE_BOUTIQUE}
            />

            <FareSupplierAirlineFilter fareSupplier={FareSupplierIdEnum.FARE_BOUTIQUE} />

            {!isError && (
                <FareRuleForm
                    fareRules={data.fareRules}
                    fareSupplier={'fare-boutique'}
                    refetchQueryIdentifier={queryIdentifier}
                />
            )}

            {!isError && (
                <MarkupControlForm
                    currentMarkup={data.markup}
                    fareSupplier={'fare-boutique'}
                    refetchQueryIdentifier={queryIdentifier}
                    markupHistory={data.markupHistory}
                />
            )}
        </>
    );
};

export default FareSupplierFareBoutique;
