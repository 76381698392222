import AirlineSearchField from 'app/components/AirlineSearchField/AirlineSearchField';
import AirportSearchField from 'app/components/AirportSearchField/AirportSearchField';

import { DeleteIcon } from 'app/icons';

import { IAirline, IAirport, ISubAgency } from 'app/types';

import LoaderButton from 'app/components/LoaderButton/LoaderButton';

import { ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import { FC } from 'react';

interface ISegmentFormItem {
    segmentIndex: number;
}

const SegmentFormItem: FC<ISegmentFormItem> = (props) => {
    const { setFieldValue, values } = useFormikContext<any>();
    const { segmentIndex } = props;
    return (
        <>
            <div className="">
                <div className="grid grid-cols-2 gap-6 mt-2">
                    <div className="col-span-1">
                        <label className="text-xs mb-2 label-required" htmlFor="from">
                            FROM
                        </label>
                        <div className="flex">
                            <AirportSearchField
                                id="from"
                                className="form-control rounded-r-none"
                                placeholder={'Select From Airport'}
                                selectedAirport={values.segments[segmentIndex].departure.airport}
                                onSelectedAirportChange={(airport: IAirport) =>
                                    setFieldValue(`segments[${segmentIndex}].departure.airport`, airport)
                                }
                                disabled
                            />
                            <div>
                                <Field
                                    type="text"
                                    id="fromTerminal"
                                    name="fromTerminal"
                                    className="form-control rounded-l-none"
                                    placeholder="Terminal"
                                />
                            </div>
                        </div>
                        <div className="form-error">
                            <ErrorMessage name={`segments[${segmentIndex}].departure.airport`} />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <label className="text-xs mb-2 label-required " htmlFor="to">
                            TO
                        </label>
                        <div className="flex">
                            <AirportSearchField
                                id="to"
                                className="form-control rounded-r-none"
                                placeholder={'Select From Airport'}
                                selectedAirport={values.segments[segmentIndex].arrival.airport}
                                onSelectedAirportChange={(airport: IAirport) =>
                                    setFieldValue(`segments[${segmentIndex}].arrival.airport`, airport)
                                }
                                disabled
                            />
                            <div>
                                <Field
                                    type="text"
                                    id="toTerminal"
                                    name="toTerminal"
                                    className="form-control rounded-l-none"
                                    placeholder="Terminal"
                                />
                            </div>
                        </div>
                        <div className="form-error">
                            <ErrorMessage name={`segments[${segmentIndex}].arrival.airport`} />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-4 gap-6 mt-8">
                    <div className="col-span-1">
                        <label className="text-xs mb-2 " htmlFor={`segments[${segmentIndex}].departure.date`}>
                            DEPART ON
                        </label>
                        <Field
                            type="date"
                            id={`segments[${segmentIndex}].departure.date`}
                            name={`segments[${segmentIndex}].departure.date`}
                            className="form-control"
                        />
                        <div className="form-error">
                            <ErrorMessage name={`segments[${segmentIndex}].departure.date`} />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <label className="text-xs mb-2 " htmlFor={`segments[${segmentIndex}].departure.time`}>
                            DEPARTURE TIME
                        </label>
                        <Field
                            type="time"
                            id={`segments[${segmentIndex}].departure.time`}
                            name={`segments[${segmentIndex}].departure.time`}
                            className="form-control"
                        />
                        <div className="form-error">
                            <ErrorMessage name={`segments[${segmentIndex}].departure.time`} />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <label className="text-xs mb-2  " htmlFor={`segments[${segmentIndex}].arrival.date`}>
                            ARRIVES ON
                        </label>
                        <Field
                            type="date"
                            id={`segments[${segmentIndex}].arrival.date`}
                            name={`segments[${segmentIndex}].arrival.date`}
                            className="form-control"
                        />
                        <div className="form-error">
                            <ErrorMessage name={`segments[${segmentIndex}].arrival.date`} />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <label className="text-xs mb-2 " htmlFor={`segments[${segmentIndex}].arrival.time`}>
                            ARRIVAL TIME
                        </label>
                        <Field
                            type="time"
                            id={`segments[${segmentIndex}].arrival.time`}
                            name={`segments[${segmentIndex}].arrival.time`}
                            className="form-control"
                        />
                        <div className="form-error">
                            <ErrorMessage name={`segments[${segmentIndex}].arrival.time`} />
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className="form-error">{/* <ErrorMsg error={errors} index={segmentIndex} /> */}</div>
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-6 mt-8">
                    <div className="col-span-1">
                        <label className="text-xs mb-2 label-required " htmlFor="flight">
                            SELECT FLIGHT
                        </label>
                        <AirlineSearchField
                            id="flight"
                            className="form-control"
                            placeholder={'Select Flight'}
                            selectedAirline={values.segments[segmentIndex].flight}
                            onSelectedAirlineChange={(airline: IAirline) =>
                                setFieldValue(`segments[${segmentIndex}].flight`, airline)
                            }
                        />
                        <div className="form-error">
                            <ErrorMessage name={`segments[${segmentIndex}].flight`} />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <label className="text-xs mb-2" htmlFor={`segments[${segmentIndex}].flightNumber`}>
                            FLIGHT NUMBER
                        </label>
                        <div className="input-group">
                            <Field
                                type="text"
                                id={`segments[${segmentIndex}].flight.number`}
                                name={`segments[${segmentIndex}].flight.number`}
                                className="form-control"
                            />
                        </div>

                        <div className="form-error">
                            <ErrorMessage name={`segments[${segmentIndex}].flightNumber`} />
                        </div>
                    </div>
                    {/* <div className="col-span-full">
                        {values.segments.length - 1 === segmentIndex && (
                            <LoaderButton
                                type="button"
                                className="bg-primary text-white text-sm font-semibold rounded-lg w-36 mt-auto ml-auto "
                                onClick={() => push(createBlankSegment())}
                            >
                                Add Segment
                            </LoaderButton>
                        )}
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default SegmentFormItem;
