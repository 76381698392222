import moment from 'moment';



export const SYSTEM_DATE_FORMAT = 'YYYYMMDD';
export const SYSTEM_TIME_FORMAT = 'HHmmss';
export const SYSTEM_DATE_TIME_FORMAT = SYSTEM_DATE_FORMAT + SYSTEM_TIME_FORMAT;

export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
export const TIME_INPUT_FORMAT = 'HH:mm';

export const formatTime = (time: string, format: string = 'HH:mm') => {
    return moment(time, 'HHmmss').format(format);
};

export const formatDate = (date: string, format: string = 'ddd, DD MMM') => {
    return moment(date, 'YYYYMMDD').format(format);
};

export const formatMinutesToTime = (minutes: number) => {
    return `${Math.floor(minutes / 60).toString()}hr ${Math.floor(minutes % 60)
        .toString()
        .padStart(2, '0')}mn`;
};

export const convertSystemDateFormatToDateInputFormat = (date: string): string => {
    return date ? moment(date, SYSTEM_DATE_FORMAT).format(DATE_INPUT_FORMAT) : '';
};

export const convertSystemTimeFormatToTimeInputFormat = (date: string): string => {
    return date ? moment(date, SYSTEM_TIME_FORMAT).format(TIME_INPUT_FORMAT) : '';
};

export const convertDateInputFormatToSystemDateFormat = (date: string): string => {
    return date ? moment(date, DATE_INPUT_FORMAT).format(SYSTEM_DATE_FORMAT) : '';
};

export const convertTimeInputFormatToSystemTimeFormat = (date: string): string => {
    return date ? moment(date, TIME_INPUT_FORMAT).format(SYSTEM_TIME_FORMAT) : '';
};
