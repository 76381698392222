import http from 'app/config/http';
import ReactQuill from 'react-quill';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Spinner from 'app/components/Spinner/Spinner';
import { IHttpResponse } from 'app/types/http-reponse';
import TicketRulesTable from './components/TicketRulesTable';
import { useMutation, useQuery } from '@tanstack/react-query';

export type ITicketTermsAndConditions = {
    _id: string;
    createdBy: {
        id: string;
        name: string;
    };
    agency: string;
    content: string;
    createdAt: string;
};

type IAllBookingTicketsDataRes = { ticketTNCs: Array<ITicketTermsAndConditions> };

const FareRulesEditor = () => {
    const currentTickerTermsQuery = useQuery(['TICKET_TERMS_AND_CONDITIONS', 'CURRENT'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/ticket-terms-and-conditions/current`
        );
        return data.ticketTNC.content;
    });

    const historicTickerTermsQuery = useQuery(['TICKET_TERMS_AND_CONDITIONS'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/ticket-terms-and-conditions`);
        return (data as IHttpResponse<IAllBookingTicketsDataRes>).data.ticketTNCs;
    });

    const formik = useFormik({
        initialValues: {
            content: currentTickerTermsQuery.data ? currentTickerTermsQuery.data : '',
        },
        enableReinitialize: true,
        onSubmit: (values) => handleSubmit(values.content),
    });

    const addTicketRuleMutation = useMutation(async (value: string) => {
        const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/ticket-terms-and-conditions`, {
            content: value,
        });

        return data;
    });

    const handleSubmit = async (rules: string) => {
        try {
            const x = addTicketRuleMutation.mutateAsync(rules.trim());
            console.log({ x });
            toast.success('Ticket Rule added successfully');
        } catch (e) {
            toast.error('Some Thing went wrong');
        }
    };

    if (currentTickerTermsQuery.isLoading || historicTickerTermsQuery.isLoading)
        return (
            <div className="mt-8 px-6 pt-5 pb-10 h-96 flex justify-center items-center ">
                <Spinner />
            </div>
        );

    console.log(currentTickerTermsQuery.data);
    console.warn(historicTickerTermsQuery.data);

    return (
        <>
            <div className="mt-8">
                <div className="font-bold">Ticket Terms and Conditions</div>
                <div className="">
                    <form onSubmit={formik.handleSubmit}>
                        <label className="text-lg font-bold mb-2" htmlFor="content"></label>
                        <ReactQuill
                            theme="snow"
                            value={formik.values.content}
                            onChange={(htmlContent: string) => formik.setFieldValue('content', htmlContent)}
                            modules={{
                                toolbar: [
                                    [],
                                    [{ list: 'bullet' }, { list: 'ordered' }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [],
                                    [],
                                ],
                            }}
                        />
                        <div className="form-error">{formik?.errors?.content && formik.errors.content.toString()}</div>

                        <button
                            className="flex justify-center items-center h-12 px-4 bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-14 disabled:bg-primary/50"
                            type="submit"
                            disabled={formik.values.content.trim() === ''}
                        >
                            Add Ticket Rules
                        </button>
                    </form>
                </div>

                <div className="mt-8 ">
                    <div className=" font-bold py-4">Ticket Terms and Conditions History</div>
                    <TicketRulesTable history={historicTickerTermsQuery.data} />
                </div>
            </div>
        </>
    );
};

export default FareRulesEditor;
