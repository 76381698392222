import React, { FC } from 'react';
import { IFareSupplier } from 'app/types';
import { Status } from 'app/enums/status.enum';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { getAllFareSuppliers } from 'app/api/services/fare-supplier.service';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';

interface FareSupplierListProps {}

const FareSupplierList: FC<FareSupplierListProps> = (props) => {
    const fareSupplierQuery = useQuery(['fareSuppliers'], () => getAllFareSuppliers());

    if (fareSupplierQuery.isLoading) return <PageLoader />;

    const fareSuppliers = fareSupplierQuery.data;

    return (
        // row.isActive === true
        <div>
            <h5 className="font-bold">Fare Suppliers</h5>

            <div className="mt-12 text-lg font-medium">Available for Integration</div>
            <div className="grid grid-cols-4 gap-6 mt-8">
                {fareSuppliers.map((row) => {
                    return row.isActive ? <FareSupplierCard row={row} key={row._id} /> : <></>;
                })}
            </div>

            <div className="mt-12 text-lg font-medium">Coming Soon</div>
            <div className="grid grid-cols-4 gap-6 mt-4">
                {fareSuppliers.map((row) => {
                    return !row.isActive ? <FareSupplierCard row={row} key={row._id} /> : <></>;
                })}
            </div>
        </div>
    );
};

const FareSupplierCard = (props: { row: IFareSupplier }) => {
    const { row } = props;

    const navigate = useNavigate();

    const handleNavigate = (fareSupplier: IFareSupplier) => {
        switch (fareSupplier.identifier) {
            case FareSupplierEnum.TRIP_JACK:
                navigate(`/fare-management/fare-suppliers/trip-jack/${fareSupplier._id}`);
                break;
            case FareSupplierEnum.AIR_IQ:
                navigate('/fare-management/fare-suppliers/air-iq');
                break;
            case FareSupplierEnum.TRAVEL_BOUTIQUE:
                navigate('/fare-management/fare-suppliers/travel-boutique');
                break;
            case FareSupplierEnum.UNIVERSAL_API:
                // navigate('/fare-management/fare-suppliers/trip-jack');
                break;
            case FareSupplierEnum.SPICE_JET:
                // navigate('/fare-management/fare-suppliers/trip-jack');
                break;
            case FareSupplierEnum.KAFILA:
                navigate('/fare-management/fare-suppliers/kafila');
                break;

            case FareSupplierEnum.FARE_BOUTIQUE:
                navigate('/fare-management/fare-suppliers/fare-boutique');
        }
    };

    const getLabel = (fareSupplier: IFareSupplier): string => {
        return fareSupplier.isActive
            ? fareSupplier.status === Status.ENABLED
                ? 'CONNECTED'
                : 'CONNECT'
            : 'COMING SOON';
    };

    const getCapsuleSytle = (fareSupplier: IFareSupplier) => {
        return fareSupplier.status === Status.ENABLED ? 'bg-green-500 text-white' : 'bg-gray-100 text-gray-900';
    };

    return (
        <>
            {' '}
            <div
                key={row._id}
                className="card col-span-4 sm:col-span-2 xl:col-span-1 px-5 pt-4 pb-6 cursor-pointer"
                onClick={() => handleNavigate(row)}
            >
                <div className="flex justify-end">
                    <div className={`${getCapsuleSytle(row)} inline-flex text-xs px-3 py-1 rounded-full font-semibold`}>
                        {getLabel(row)}
                    </div>
                </div>
                <img
                    crossOrigin="anonymous"
                    className={`mt-4 max-w-[140px] h-12 object-contain object-left ${
                        row.name === 'Air IQ' ? 'w-24' : ''
                    }
                    ${row.name === 'Kafila' ? 'h-[38px]' : ''} }
                        } `}
                    src={row.logo}
                    alt=""
                />
                <div className="font-bold mt-3">{row.name}</div>
                <p className="mt-2">{row.description}</p>
            </div>
        </>
    );
};

export default FareSupplierList;
