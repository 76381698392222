import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import FlightForm from './components/FlightForm';
// import BackLink from 'app/components/BackLink/BackLink';

const ManualBookingForm = () => {
    const formKey = useMemo(() => {
        return uuid();
    }, []);

    return (
        <>
            {/* <div className="mb-5">
                <BackLink />
            </div> */}

            <div className="card pt-4 pb-10 px-6">
                <div className="flex items-center justify-between mb-12">
                    <div className="font-bold">Add Manual Booking</div>
                </div>

                <FlightForm formKey={formKey} />
            </div>
        </>
    );
};

export default ManualBookingForm;
