import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const collapseAnimation = {
    variants: {
        collapsed: { opacity: 0, height: 0 },
        expanded: { opacity: 1, height: 'auto' },
    },
    transition: { duration: 0.35, type: 'tweeen' },
    initial: 'collapsed',
    animate: 'expanded',
    exit: 'collapsed',
};

const Return = () => {
    return (
        <div className="flex min-h-[12rem]">
            {/* Date section */}
            <div className="w-48 flex items-center justify-end pr-4">
                <div className="text-xs font-thin">10 November 2024</div>
            </div>

            {/* Timeline connector section */}
            <div className="relative flex items-center justify-center w-10 before:absolute before:top-0 before:bottom-[50%] before:w-2 before:bg-primary after:absolute after:top-[50%] after:bottom-0 after:w-2 after:bg-primary before:z-10 after:z-10">
                <div className="z-[11] p-[3px] bg-[#FAFAFB]">
                    <div className="flex items-center justify-center w-10 h-10 p-4 bg-white border-2 border-primary rounded-full">
                        RF
                    </div>
                </div>
            </div>

            {/* Content section */}
            <div className="flex flex-col justify-center w-[650px] pl-4">
                <div className="relative text-lg font-semibold after:absolute after:top-7 pb-10   after:text-primary/70 after:block after:text-sm after:font-medium after:content-['The_Return_Flights_feature_enables_users_to_easily_book_round-trip_tickets,_allowing_for_a_seamless_and_efficient_travel_planning_experience._With_just_a_few_clicks,_travelers_can_secure_both_their_departure_and_return_flights_in_a_single_transaction,_saving_time_and_ensuring_all_travel_details_are_conveniently_managed_in_one_place.'] after:whitespace-normal   ">
                    Return Flights
                </div>
            </div>

            {/* Additional content */}
            {/* <div className="w-40 ml-auto flex flex-col justify-center">
                <div className="flex flex-col justify-center items-center">
                    <div className="bg-yellow-400 px-4 py-1 text-white rounded-3xl font-bold ">Pending</div>
                </div>
            </div> */}
        </div>
    );
};

const Amendment = () => {
    return (
        <div className="flex min-h-[12rem]">
            {/* Date section */}
            <div className="w-48 flex items-center justify-end pr-4">
                <div className="text-xs font-thin">30 September 2024</div>
            </div>

            {/* Timeline connector section */}
            <div className="relative flex items-center justify-center w-10 before:absolute before:top-0 before:bottom-[50%] before:w-2 before:bg-primary after:absolute after:top-[50%] after:bottom-0 after:w-2 after:bg-primary before:z-10 after:z-10">
                <div className="z-[11] p-[3px] bg-[#FAFAFB]">
                    <div className="flex items-center justify-center w-10 h-10 p-4 bg-white border-2 border-primary rounded-full">
                        AM
                    </div>
                </div>
            </div>

            {/* Content section */}
            <div className="flex flex-col justify-center w-[650px] pl-4">
                <div className="relative text-lg font-semibold after:absolute after:top-7 pb-10  after:text-primary/70 after:block  after:whitespace-normal after:text-sm after:font-medium after:content-['The_Amendment_feature_allows_users_to_easily_modify_their_existing_bookings,_providing_flexibility_to_change_travel_dates,_destinations,_or_passenger_details_with_minimal_hassle._This_ensures_that_plans_can_adapt_to_any_last-minute_changes,_offering_a_smooth_and_user-friendly_experience.']  ">
                    Amendment
                </div>
            </div>

            {/* Additional content */}
            {/* <div className="w-40 ml-auto flex flex-col justify-center">
                <div className="flex flex-col justify-center items-center">
                    <div className="bg-green-500 px-4 py-1 text-white rounded-3xl font-bold ">In Progress</div>
                </div>
            </div> */}
        </div>
    );
};

const Farepod = () => {
    return (
        <div className="flex min-h-[12rem]">
            {/* Date section */}
            <div className="w-48 flex items-center justify-end pr-4">
                <div className="text-xs font-thin">20 August 2024</div>
            </div>

            {/* Timeline connector section */}
            <div className="relative flex items-center justify-center w-10 before:absolute before:top-0 before:bottom-[50%] before:w-2 before:bg-primary after:absolute after:top-[50%] after:bottom-0 after:w-2 after:bg-primary before:z-10 after:z-10">
                <div className="z-[11] p-[3px] bg-[#FAFAFB]">
                    <div className="flex items-center justify-center w-10 h-10 p-4 bg-white border-2 border-primary rounded-full">
                        FP
                    </div>
                </div>
            </div>

            {/* Content section */}
            <div className="flex flex-col justify-center w-[650px] pl-4">
                <div className="relative text-lg font-semibold after:absolute after:top-7  after:bg-white after:text-primary/70 after:block after:content-['Farepod_is_a_comprehensive_platform_designed_to_simplify_the_flight_booking_process_by_offering_access_to_multiple_airlines_and_fare_suppliers._With_a_user-centric_approach,_Farepod_delivers_an_intuitive_UI/UX,_ensuring_a_seamless_and_efficient_booking_experience._Whether_searching_for_the_best_deals_or_managing_travel_plans,_Farepod_is_crafted_to_meet_the_needs_of_todays_travelers.'] after:whitespace-normal after:text-xs ">
                    Farepod
                </div>
            </div>

            {/* Additional content */}
            {/* <div className="w-40 ml-auto flex flex-col justify-center">
                <div className="flex flex-col justify-center items-center">
                    <div className="bg-primary px-4 py-1 text-white rounded-3xl font-bold ">Started</div>
                </div>
            </div> */}
        </div>
    );
};

const ManualBooking = () => {
    return (
        <div className="flex min-h-[12rem]">
            {/* Date section */}
            <div className="w-48 flex items-center justify-end pr-4">
                <div className="text-xs font-thin">20 September 2024</div>
            </div>

            {/* Timeline connector section */}
            <div className="relative flex items-center justify-center w-10 before:absolute before:top-0 before:bottom-[50%] before:w-2 before:bg-primary after:absolute after:top-[50%] after:bottom-0 after:w-2 after:bg-primary before:z-10 after:z-10">
                <div className="z-[11] p-[3px] bg-[#FAFAFB]">
                    <div className="flex items-center justify-center w-10 h-10 p-4 bg-white border-2 border-primary rounded-full">
                        MB
                    </div>
                </div>
            </div>

            {/* Content section */}
            <div className="flex flex-col justify-center w-[650px] pl-4">
                <div className="relative text-lg font-semibold after:absolute after:top-7  pb-10  after:text-primary/70 after:block after:text-sm after:font-medium after:content-['Easily_create_airline_bookings_by_manually_entering_flight_and_passenger_details._This_new_feature_provides_flexibility_to_handle_custom_bookings_and_unique_travel_needs_with_full_control_over_the_booking_process.'] after:whitespace-normal  ">
                    Manual Booking
                </div>
            </div>

            {/* <div className="flex flex-col justify-center w-[650px] pl-4">
                <div className="relative text-lg font-semibold after:absolute after:top-7 pb-10  after:text-primary/70 after:block  after:whitespace-normal after:text-sm after:font-medium after:content-['Easily_create_airline_bookings_by_manually_entering_flight_and_passenger_details._This_new_feature_provides_flexibility_to_handle_custom_bookings_and_unique_travel_needs_with_full_control_over_the_booking_process.']  ">
                    Manual Booking
                </div>
            </div> */}

            {/* Additional content */}
            {/* <div className="w-40 ml-auto flex flex-col justify-center">
                <div className="flex flex-col justify-center items-center">
                    <div className="bg-yellow-400 px-4 py-1 text-white rounded-3xl font-bold ">Pending</div>
                </div>
            </div> */}
        </div>
    );
};

const ZohoIntegration = () => {
    return (
        <div className="flex min-h-[12rem]">
            {/* Date section */}
            <div className="w-48 flex items-center justify-end pr-4">
                <div className="text-xs font-thin">15 October 2024</div>
            </div>

            {/* Timeline connector section */}
            <div className="relative flex items-center justify-center w-10 before:absolute before:top-0 before:bottom-[50%] before:w-2 before:bg-primary after:absolute after:top-[50%] after:bottom-0 after:w-2 after:bg-primary before:z-10 after:z-10">
                <div className="z-[11] p-[3px] bg-[#FAFAFB]">
                    <div className="flex items-center justify-center w-10 h-10 p-4 bg-white border-2 border-primary rounded-full">
                        ZI
                    </div>
                </div>
            </div>

            {/* Content section */}
            <div className="flex flex-col justify-center w-[650px] pl-4">
                <div className="relative text-lg font-semibold after:absolute after:top-7 pb-10  after:text-primary/70 after:block after:text-sm after:font-medium after:content-['Integrate_Zoho_Accounting_into_your_app_to_automate_financial_data_syncing,_manage_expenses,_and_streamline_invoicing._This_feature_simplifies_bookkeeping_and_ensures_real-time_updates_for_smooth_financial_operations.'] after:whitespace-normal  ">
                    Zoho Accounting Integration
                </div>
            </div>
        </div>
    );
};

const stages = [
    {
        label: 'In Development',
        components: [<Amendment />, <Return />],
    },
    {
        label: 'Future Development',
        components: [<ZohoIntegration />],
    },
    {
        label: 'In Production',
        components: [<ManualBooking />],
        // components: [<Farepod />],
    },
];

const RoadMap = () => {
    const [openedBlock, setOpenedBlock] = useState<number>(1);

    return (
        <div className=" py-4">
            <h5 className="font-bold">Development Road Map</h5>
            <ul className="mt-9">
                {stages.map((stage, index) => (
                    <li className="my-6" key={index}>
                        <div
                            className="flex justify-between bg-gray-200 px-4  py-2 shadow-md rounded-sm  cursor-pointer"
                            onClick={() => setOpenedBlock(index)}
                        >
                            <div className="font-bold">{stage.label}</div>
                        </div>

                        <AnimatePresence initial={false}>
                            {(openedBlock === index || true) && (
                                <motion.div
                                    className=" bg-slate-50 shadow  my-2 overflow-hidden "
                                    {...collapseAnimation}
                                >
                                    <div className=""> {stage.components.map((component) => component)} </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RoadMap;
