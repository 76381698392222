import { IAirport } from 'app/types';
import debounce from 'lodash.debounce';
import { FC, useState, useEffect, useCallback } from 'react';
import http from 'app/config/http';

interface AirportSearchFieldProps {
    selectedAirport: IAirport;
    onSelectedAirportChange: (airport: IAirport) => void;
    placeholder: string;
    id?: string;
    className?: string;
    disabled?: boolean;
}

const AirportSearchField: FC<AirportSearchFieldProps> = (props) => {
    const { selectedAirport, onSelectedAirportChange, placeholder, id, className, disabled } = props;

    const [value, setValue] = useState<string>('');
    const [suggestionList, setSuggestionList] = useState<IAirport[]>([]);
    const [showSuggestionList, setShowSuggestionList] = useState<boolean>(false);

    useEffect(() => {
        document.body.addEventListener('click', closeSuggestionList);

        return () => {
            document.body.removeEventListener('click', closeSuggestionList);
        };
    });

    const closeSuggestionList = () => {
        setShowSuggestionList(false);
    };

    const changeHandler = async (e: any) => {
        const _value = e.target.value.trim();
        setValue(_value);

        if (_value.length < 2) {
            closeSuggestionList();
            setSuggestionList([]);

            return;
        }

        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/airports`, {
            params: {
                q: _value,
            },
        });

        setSuggestionList(data);

        setShowSuggestionList(true);
    };

    const handleKeyUp = useCallback(debounce(changeHandler, 200), []);

    const handleFocus = () => {
        setValue('');
    };

    const handleBlur = () => {
        setValue('');
    };

    const handleAirportSelect = (airport: any) => {
        setValue('');
        onSelectedAirportChange(airport);
        closeSuggestionList();
    };

    return (
        <div className="w-full" onClick={(e) => e.stopPropagation()}>
            <div className="relative">
                <input
                    id={id}
                    type="text"
                    className={className}
                    autoComplete="off"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={value}
                    onKeyUp={handleKeyUp}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={disabled}
                />

                {!value && (
                    <div className="absolute top-0 left-0 w-full h-full flex items-center px-4 pointer-events-none ">
                        <div className="w-full whitespace-nowrap overflow-hidden text-ellipsis">
                            {selectedAirport ? (
                                <div className="flex justify-between items-center">
                                    <span className="font-medium text-lg"> {selectedAirport.code}</span>
                                    <span className="text-gray-500">{selectedAirport.city}</span>
                                </div>
                            ) : (
                                <span className="text-gray-400">{placeholder}</span>
                            )}
                        </div>
                    </div>
                )}

                {showSuggestionList && (
                    <div className="absolute w-full max-h-60 overflow-y-auto z-40 bg-white rounded-md border border-gray-200 drop-shadow">
                        {suggestionList.length > 0 && (
                            <ul>
                                {suggestionList.map((airport, index) => (
                                    <li
                                        key={index}
                                        className="py-3 px-4 cursor-pointer"
                                        onClick={() => handleAirportSelect(airport)}
                                    >
                                        {`${airport.code} - ${airport.city}`}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {suggestionList.length === 0 && (
                            <div className="py-3 px-4 cursor-pointer text-gray-500" onClick={closeSuggestionList}>
                                No airports found
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

AirportSearchField.defaultProps = {
    disabled: false,
};

export default AirportSearchField;

