import { FC } from 'react';
import moment from 'moment';
import http from 'app/config/http';
import contryList from 'country-list';
import { toast } from 'react-toastify';
import capitalize from 'lodash.capitalize';
import useDialog from 'app/hooks/use-dialog';
import { SeperatorDotIcon } from 'app/icons';
import Modal from 'app/components/Modal/Modal';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from 'app/components/Spinner/Spinner';
import { formatCurrency } from 'app/utils/currency-utils';
import { getCabinClassColor } from 'app/utils/color-utils';
import AddPnrModal from './components/AddPnrModal/AddPnrModal';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import { formatDOB, getGenderFromTitle } from 'app/utils/common-utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { formatDate, formatMinutesToTime, formatTime } from 'app/utils/date-utils';
import { calculateStops, getFormattedRefundableType, getFormattedStops } from 'app/utils/flight-utils';

interface BookingDetailDialogProps {
    show: boolean;
    onClose: () => void;
    bookingId: string;
    type: string;
}

const BookingDetailDialog: FC<BookingDetailDialogProps> = (props) => {
    const { bookingId, type } = props;
    const navigate = useNavigate();

    const AddPnrDialog = useDialog();
    const abortDialog = useDialog();
    const queryClient = useQueryClient();

    let api = '';

    if (type === 'AG') {
        api = '/api/v1/agent/bookings/';
    } else if (type === 'SAG') {
        api = '/api/v1/agent/agency/sub-agency-bookings/';
    } else {
        navigate('/404');
    }

    const query = useQuery(['bookingDetail'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}${api}${bookingId}`);

        return data;
    });

    const abortTickerMutation = useMutation(async () => {
        const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/abort`);

        return data;
    });

    const handleAbortTicker = async () => {
        try {
            await abortTickerMutation.mutateAsync();
            queryClient.invalidateQueries({ queryKey: ['bookingDetail'] });
        } catch (err: any) {
            toast.error(err?.data?.message || ' Some error occured while aborting the ticker, please try again');
        } finally {
            abortDialog.hide();
        }
    };

    if (query.isLoading)
        return (
            <Modal show={props.show} onClose={props.onClose} className="w-[720px] p-6 ">
                <div>
                    <div className="flex justify-center items-center">
                        <Spinner />
                    </div>
                </div>
            </Modal>
        );

    const booking = query.data;

    const getRefundableType = () => booking.priceDetail.fareDescription.adult.refundableType;
    const getCabinClass = () => booking.priceDetail.fareDescription.adult.cabinClass;
    const getFareType = () => (booking.fareSupplier === '0' ? 'Series' : 'Normal');
    const isInternational = () => booking.departure.airport.countryCode !== booking.arrival.airport.countryCode;
    const cabinColor = getCabinClass() ? getCabinClassColor(getCabinClass()) : 'bg-primary';

    return (
        <Modal show={props.show} onClose={props.onClose} className=" px-6 py-6 w-[1280px] bg-[#FAFAFB]">
            <div className="grid grid-cols-3 gap-9">
                {/* Col */}
                <div className="col-span-3 lg:col-span-2">
                    {/* Card */}
                    <div className="card px-6 pt-5 pb-6">
                        <div className="font-bold">Flight Details</div>

                        <div className="mt-8">
                            <div className="flex justify-between gap-2">
                                <div>
                                    <div className="font-semibold">
                                        {booking.departure.airport.city} <span className="font-light"> to </span>{' '}
                                        {booking.arrival.airport.city}
                                    </div>

                                    <div className="flex items-center gap-2 text-sm mt-2">
                                        <div>{formatDate(booking.departure.date, 'dddd, MMM D')}</div>
                                        <SeperatorDotIcon />
                                        <div>
                                            <span className="mr-2">
                                                {getFormattedStops(calculateStops(booking.segments))}
                                            </span>
                                            <span>{formatMinutesToTime(booking.duration)}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col items-end">
                                    {getRefundableType() && (
                                        <div
                                            className={`rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium ${
                                                getRefundableType() === 'NON_REFUNDABLE' ? 'bg-danger' : 'bg-success'
                                            }`}
                                        >
                                            {getFormattedRefundableType(getRefundableType())}
                                        </div>
                                    )}
                                    <div className="flex items-center gap-2 text-sm mt-2">
                                        {/* <div>{capitalize(getCabinClass().toLowerCase())}</div> */}

                                        <div
                                            className={`rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium 
                                        ${cabinColor}`}
                                        >
                                            {capitalize(getCabinClass().toLowerCase())}
                                        </div>

                                        <SeperatorDotIcon />
                                        <div>{getFareType()} Fare</div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-7 space-y-6 overflow-auto">
                                {booking.segments.map((segment: any, i: number) => (
                                    <div key={i}>
                                        <div className="flex justify-between items-center gap-6">
                                            <div className="flex items-center gap-2 min-w-max">
                                                <img
                                                    crossOrigin="anonymous"
                                                    className="w-9 h-9"
                                                    src={segment.flight.logo}
                                                    alt=""
                                                />
                                                <div>
                                                    <div className="text-base font-semibold">{segment.flight.name}</div>
                                                    <div className="text-xs text-black/70 mt-[2px]">{`${segment.flight.code} ${segment.flight.number}`}</div>
                                                </div>
                                            </div>
                                            <div className="flex justify-center gap-6 min-w-max">
                                                <div>
                                                    <div className="flex gap-2">
                                                        <div className="font-medium">
                                                            {formatDate(segment.departure.date)}
                                                        </div>
                                                        <div className="font-semibold">
                                                            {formatTime(segment.departure.time)}
                                                        </div>
                                                    </div>
                                                    <div className="text-xs text-black/70 mt-[2px] w-28">
                                                        {segment.departure.airport.name}
                                                        {segment.departure.airport.terminal &&
                                                            ', ' + segment.departure.airport.terminal}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-center mt-1">
                                                    <div className="text-xs text-black/70">
                                                        {formatMinutesToTime(segment.duration)}
                                                    </div>
                                                    {/* Line */}
                                                    <div className="bg-[#ECAE0E] h-1 w-24 rounded-lg mt-1" />
                                                    {/*  */}
                                                </div>
                                                <div>
                                                    <div className="flex gap-2">
                                                        <div className="font-medium">
                                                            {formatDate(segment.arrival.date)}
                                                        </div>
                                                        <div className="font-semibold">
                                                            {formatTime(segment.arrival.time)}
                                                        </div>
                                                    </div>
                                                    <div className="text-xs text-black/70 mt-[2px] w-28">
                                                        {segment.arrival.airport.name}{' '}
                                                        {segment.arrival.airport.terminal &&
                                                            ', ' + segment.arrival.airport.terminal}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="min-w-max">
                                                <div className="font-semibold">
                                                    {booking.priceDetail.fareDescription.adult?.baggage?.checkIn}
                                                </div>
                                                <div className="text-xs text-black/70 mt-[2px]">Check-in</div>
                                            </div>
                                            <div className="min-w-max">
                                                <div className="font-semibold">
                                                    {booking.priceDetail.fareDescription.adult?.baggage?.cabin}
                                                </div>
                                                <div className="text-xs text-black/70 mt-[2px]">Cabin</div>
                                            </div>
                                        </div>
                                        {segment.layoverDuration !== 0 && (
                                            <div className="mx-auto w-60 mt-6 bg-gray-100 rounded-full px-8 py-2 text-sm text-center">
                                                Layover Time -{' '}
                                                <span className="font-semibold">
                                                    {formatMinutesToTime(segment.layoverDuration)}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Card */}
                    <div className="card px-6 pt-5 pb-6 mt-6">
                        <div className="flex justify-between items-center">
                            <div className="font-bold ">Traveller Details</div>
                            {booking.status === 'AWAITING_PNR' && (
                                <div className="flex gap-2">
                                    <button
                                        onClick={abortDialog.show}
                                        className="text-center bg-red-500 text-white text-xs font-semibold px-6 py-2 rounded-md cursor-pointer "
                                    >
                                        Abort
                                    </button>

                                    <button
                                        onClick={AddPnrDialog.show}
                                        className="text-center bg-primary text-white text-xs font-semibold px-4 py-2 rounded-md cursor-pointer"
                                    >
                                        Add PNR
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className="grid grid-cols-5 gap-8 mt-8 mb-4">
                            <div className="col-start-2 col-span-1">
                                <div className="text-xs text-gray-500">Name</div>
                            </div>
                            <div className="col-span-1">
                                <div className="text-xs text-gray-500">Airline PNR</div>
                            </div>
                            <div className="col-span-1">
                                <div className="text-xs text-gray-500">eTicket Number</div>
                            </div>
                            <div className="col-span-1">
                                <div className="text-xs text-gray-500">Seat Number</div>
                            </div>
                        </div>

                        {booking.passengers.adults.map((row: any, i: number) => (
                            <PassengerDetail
                                index={i}
                                row={row}
                                type={'Adult'}
                                key={i}
                                isInternational={isInternational()}
                            />
                        ))}

                        {booking.passengers.children.map((row: any, i: number) => (
                            <PassengerDetail
                                index={i}
                                row={row}
                                type={'Child'}
                                key={i}
                                isInternational={isInternational()}
                            />
                        ))}

                        {booking.passengers.infants.map((row: any, i: number) => (
                            <PassengerDetail
                                index={i}
                                row={row}
                                type={'Infant'}
                                key={i}
                                isInternational={isInternational()}
                            />
                        ))}

                        <div className="grid grid-cols-5 gap-8 mt-8 mb-4">
                            <div className="col-start-2 col-span-1">
                                <div className="text-xs text-gray-500">Mobile Number</div>
                            </div>
                            <div className="col-span-1">
                                <div className="text-xs text-gray-500">Email Address</div>
                            </div>
                        </div>

                        <div className="grid grid-cols-5 gap-8">
                            <div className="col-span-1">
                                <div className="font-medium">Contact</div>
                            </div>
                            <div className="col-span-1">
                                <div className="font-medium">{booking.contactDetails.mobileNumber}</div>
                            </div>
                            <div className="col-span-1">
                                <div className="font-medium">{booking.contactDetails.email}</div>
                            </div>
                        </div>
                    </div>

                    {/* Card */}
                    <div className="card px-6 pt-5 pb-6 mt-6">
                        <div className="font-bold">Sub Agent Details</div>

                        <div className="mt-8">
                            <Link to={`/sub-agencies/${booking.subAgency.id}/details`}>
                                <div className="text-blue-500 font-medium">{booking.subAgency.name}</div>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* Col */}
                <div className="col-span-3 lg:col-span-1">
                    <div className="sticky top-[calc(var(--navbar-height)+20px)]">
                        {/* Card */}
                        <div className="card px-6 pt-5 pb-6">
                            <div className="font-bold ">Ticket(s)</div>
                            <Link
                                to={`/bookings/${bookingId}/ticket/${type}`}
                                target="_blank"
                                className="block text-sm text-primary cursor-pointer mt-4"
                            >
                                Print Ticket
                            </Link>
                        </div>

                        {/* Card */}
                        <div className="card px-6 pt-5 pb-6 mt-6">
                            <div className="font-bold">Fare Summary</div>
                            <div className="mt-6">
                                <div className="flex justify-between">
                                    <div className="text-sm">Base Fare</div>
                                    <div className="text-sm">{formatCurrency(booking.priceDetail.totalBaseFare)}</div>
                                </div>
                                <div className="mt-4 flex justify-between">
                                    <div className="text-sm">Fees & Surcharges</div>
                                    <div className="text-sm">
                                        {/* {formatCurrency(booking.priceDetail.totalTaxesAndFees + booking.markup)} */}
                                        {formatCurrency(
                                            booking.priceDetail.totalFare - booking.priceDetail.totalBaseFare
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="text-sm font-semibold flex justify-between mt-8">
                                <div>Total Amount</div>
                                <div>{formatCurrency(booking.priceDetail.totalFare)}</div>
                            </div>
                            <div className="mt-3 text-sm font-semibold flex justify-between">
                                <div>Payment (AGY BAL)</div>
                                <div className="text-success">-{formatCurrency(booking.priceDetail.totalFare)}</div>
                            </div>
                            <div className="mt-8 text-sm font-semibold flex justify-between">
                                <div>Balance</div>
                                <div className="text-success">₹ 0</div>
                            </div>
                        </div>
                    </div>
                </div>
                {AddPnrDialog.isOpen && (
                    <AddPnrModal onClose={AddPnrDialog.hide} show={AddPnrDialog.isOpen} booking={booking} />
                )}
                <DeleteModal
                    show={abortDialog.isOpen}
                    message={'Are you sure you want to abort this ticket?'}
                    onClose={abortDialog.hide}
                    onConfirm={handleAbortTicker}
                    actionLabel={'Abort'}
                    isLoading={abortTickerMutation.isLoading}
                />
            </div>
        </Modal>
    );
};

interface PassengerDetailProps {
    index: number;
    type: string;
    row: any;
    isInternational: boolean;
}

const PassengerDetail: FC<PassengerDetailProps> = (props) => {
    const { index, row, type, isInternational } = props;
    return (
        <>
            <div key={index} className="grid grid-cols-5 gap-8 mb-3">
                <div className="col-span-1">
                    <div className="font-medium"> {`${type} ${index + 1}`} </div>
                </div>
                <div className="col-span-1">
                    <div className="font-medium">
                        {row.title} {row.firstName} {row.lastName}
                    </div>
                    <div className="text-xs">
                        {getGenderFromTitle(row.title)}, {type}
                    </div>
                    {(type === 'Infant' || isInternational) && (
                        <div className="text-xs font-normal">(DOB - {formatDOB(row.dob, 'YYYY-MM-DD')})</div>
                    )}
                </div>
                <div className="col-span-1">
                    <div className="space-y-1">
                        {row.pnrs.map((pnr: any, i: number) => (
                            <div key={i}>
                                <div className="font-medium">{pnr.number}</div>
                                <div className="text-xs">{pnr.sector}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-span-1">
                    {/* <div className="font-medium">{row.eTicketNumber || row.pnrs[0]?.eTicketNumber}</div> //old */}

                    {row.ticketNumbers.map((ticket: any, i: number) => (
                        <div key={i}>
                            <div className="font-medium">{ticket.number}</div>
                            <div className="text-xs">{ticket.sector}</div>
                        </div>
                    ))}

                    {row.ticketNumbers.length === 0 && <div className="font-medium">-</div>}
                </div>
                <div className="col-span-1">
                    <div className="font-medium">-</div>
                </div>
            </div>
            {isInternational && (
                <div key={index} className="grid grid-cols-5 gap-8 mb-2 min-w-[650px] text-xs mt-1">
                    <div className="col-span-1"></div>
                    <div className="col-span-1 flex flex-col justify-between">
                        <div className="text-xs font-normal text-gray-500">Nationality :</div>
                        <div className="text-sm font-normal"> {contryList.getName(row.passport.nationality)} </div>
                    </div>
                    <div className="col-span-1 flex flex-col justify-between ">
                        <div className="text-xs font-normal text-gray-500">Passport :</div>
                        <div className="text-sm font-normal"> {row.passport.number} </div>
                    </div>
                    <div className="col-span-1 flex flex-col justify-between ">
                        <div className="text-xs font-normal text-gray-500">Issue :</div>
                        <div className="text-sm font-normal">
                            {moment(row.passport.issueDate, 'YYYYMMDD').format('DD MMM YYYY')}
                        </div>
                    </div>
                    <div className="col-span-1 flex flex-col justify-between ">
                        <div className="text-xs font-normal text-gray-500">Expiry :</div>
                        {moment(row.passport.expiry, 'YYYYMMDD').format('DD MMM YYYY')}
                    </div>
                </div>
            )}
        </>
    );
};

export default BookingDetailDialog;
