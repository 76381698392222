import { useOutletContext } from 'react-router-dom';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';

type context = {
    commissionPlanEnabledFareSupplier: Array<FareSupplierEnum>;
};

const useCommissionPlanLayoutContext = () => {
    const contextData = useOutletContext<context>();

    if (contextData === null) {
        throw new Error('Component must have <CommissionLayout /> as parent.');
    }
    return contextData;
};

export default useCommissionPlanLayoutContext;
